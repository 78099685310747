<template>
  <div>
    <div class="card">

      <el-tabs class="tabs" v-model="activeName">
        <el-tab-pane :label="'我的待办'" name="first">
          <div class="back">
            <div style="text-align: center" v-if="todoList.length==0">
              <el-image style="width: 300px" :src="require('../assets/nodata.png')"></el-image>
            </div>

            <div class="content">
              <div class="row" v-for="(item,index) in todoList" :key="index">
                <div class="cell title">
                  <!--                  <p style="cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" @click="readNotice(index)">{{item.theme}}</p>-->
                  <div style="width: 100%;display: flex">
                    <div style="flex: 1;width: 0">
                      <p style="cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin: 0" @click="handleView(item)">{{item.startRealName}}提交的{{item.processDefinitionName}}</p>
                    </div>

                  </div>
                </div>
                <div class="cellr des">

                </div>
                <div class="cellr des">
                  {{item.startTime|dateformat("YYYY-MM-DD")}}
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="card">
      <div>
        <div v-for="(item, index) in wllist">
          <text>{{item.billdate}}</text>
          <text>{{item.billnum}}</text>
          <text>{{item.billdate}}</text>
        </div>
      </div>
      <el-tabs class="tabs" v-model="activeName">
        <el-tab-pane :label="'通知公告(' + msglist.length + ')'" name="first">
          <div class="back">
            <div style="text-align: center" v-if="msglist.length==0">
              <el-image style="width: 300px" :src="require('../assets/nodata.png')"></el-image>
            </div>

            <div class="content">
              <div class="row" v-for="(item,index) in msglist" :key="index">
                <div class="cell title">
<!--                  <p style="cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" @click="readNotice(index)">{{item.theme}}</p>-->
                  <div style="width: 100%;display: flex">
                    <div style="flex: 1;width: 0">
                      <p style="cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin: 0" @click="readNotice(index)">{{item.theme}}</p>
                    </div>

                  </div>
                </div>
                <div class="cellr des">
                  {{item.realname}}
                </div>
                <div class="cellr des">
                  {{item.pubdate|dateformat("YYYY-MM-DD")}}
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <baseFlowDialog :visible.sync="formVisible" @close="baseFormClose" :params="formParams"></baseFlowDialog>
  </div>

</template>

<script>
  import { noteMsg, getWuLiuXinXi,process } from '../api/common'
  import { dateformat } from '../util/common'
  import baseFlowDialog from '@/components/baseFlowDialog'

  export default {
    name: '',
    components: {
      baseFlowDialog
    },
    data(){
      return{
        msglist:[],
        activeName: 'first',
        wllist:[],
        todoList: [],
        formVisible: false,
        formParams: {}
      }
    },
    methods:{
      handleView (row) {
        this.formParams = {id: row.Id, idType: "taskId"}
        this.formVisible = true
      },
      baseFormClose(){
        this.formVisible=false
        // this.loaddata()
      },
      readNotice(index){
         this.$notice({id:this.msglist[index].sguid}).show()
      },
      loadData(){
         noteMsg.getTopic().then(r=>{
           if (r.code=="200"){
             this.msglist = r.data
           }
         })
        getWuLiuXinXi({}).then(r=>{
            this.wllist = r.data.list
        })
        // process.getClaimsAndTodo({}).then(r=>{
        //    this.todoList = r.data
        // })
        process.getClaimsAndTodo({}).then(r=>{
          this.todoList = r.data
        })
      }
    },
    mounted(){
       this.loadData()
       this.msgBus.$on("topic", ()=>{
         this.loadData()
       })
    }

  }
</script>

<style scoped >
  .tabs{
      margin-bottom: 20px;
  }
  .card{
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 10px 10px -10px rgba(82,80,198,0.2);

    /*box-shadow: 5px 15px -10px -15px #5250C6;*/
  }
  /deep/ .el-tabs__item{
    color: #1890ff;
    line-height: normal;
    height: auto;
    padding: 12px 16px !important;
  }
  /deep/ .el-tabs__header{
    margin-bottom: 0;
  }
  /deep/ .el-tabs__active-bar{
    transform: none !important;
    background-color: #1890ff;
  }
  /deep/ .el-tabs__nav-wrap::after{
    height: 1px;
  }
  .back{
    padding: 10px 16px;
    margin: 10px 0;
    min-height: 240px;
  }
  .content{
    width: 100%;
    display: table;
  }
  .row{
    display: table-row;
  }
  .row:hover{
    background-color: #E6F7FF;
  }
  .cell{
    padding: 0 8px;
    display: table-cell;
    height: 30px;
    line-height: 30px;
    width: 690px;
  }
  .cellr{
    width: 120px;
    padding: 0 8px;
    display: table-cell;
    text-align: center;
  }

  .title{
    color: #1890ff;
  }

  .des{
    color: #98a5ba;
  }

</style>
