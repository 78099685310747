<template>
  <div class="flow-container">
    <div class="containers" ref="content">
      <div class="canvas" ref="canvas"></div>
      <div id="js-properties-panel" class="panel"></div>
      <ul class="buttons">
        <li>下载</li>
        <li>
          <a ref="saveDiagram" href="javascript:" title="download BPMN diagram">BPMN diagram</a>
        </li>
        <li>
          <a ref="saveSvg" href="javascript:" title="download as SVG image">SVG image</a>
        </li>
        <li>
          <el-button @click="add">新增</el-button>
        </li>
        <li>
          <el-button @click="getmodel">获取</el-button>
        </li>
        <li>
          <el-button @click="save">保存</el-button>
        </li>
        <li>
          <el-button @click="depl">部署</el-button>
        </li>
<!--        <li>-->
<!--          <el-button @click="del">删除</el-button>-->
<!--        </li>-->
        <li>
          <el-button @click="$refs.refFile.click()" >导入BPMN</el-button>
          <input type="file" id="files" ref="refFile" style="display: none" @change="loadXML" />
        </li>
        <li>
          <el-button @click="console" >Console XML</el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// 引入相关的依赖
import BpmnModeler from 'bpmn-js/lib/Modeler'
import propertiesPanelModule from '../../components/workflow/properties-panel'
import propertiesProviderModule from '../../components/workflow/properties-panel/provider/activiti'
import activitiModdleDescriptor from '../../assets/js/activiti.json'
import customTranslate from '../../components/workflow/customTranslate/customTranslate'
import customControlsModule from '../../components/workflow/customControls'

import { process } from '../../api/common'

export default {
  props: {
    id:{
      type:String,
      default: ''
    }
  },
  name: 'flow_bpmn',
  data () {
    return {
      // bpmn建模器
      bpmnModeler: null,
      container: null,
      canvas: null,
      xmlStr: null,
      processName: '',
      modelId:  null,
      xml: ''
    }
  },
  watch: {

  },
  methods: {
    console(){
      console.log(this.xml)
    },
    loadXML(){
      const that = this;
      const file = this.$refs.refFile.files[0];
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function() {
        that.xml = this.result;
        that.bpmnModeler.importXML(this.result, function (err) {
          if (err) {
            console.error(err)
          } else {
            // 这里还没用到这个，先注释掉吧
            // that.success()
          }
        })
      }
    },
    save(){
      process.saveModel({xml: this.xml, id:this.modelId}).then(r=>{
        if (r.code='200'){
          this.$message.success("保存成功")
        }
      })
      console.log(this.modelId, this.xml)
    },
    del(){
      process.deleteModel({id: this.modelId}).then(r=>{
        if (r.code='200'){
          this.$message.success("保存成功")
        }
      })
    },
    depl(){
      process.deployment({id:this.modelId}).then(
        r=>{
          if (r.code='200'){
            this.$message.success("保存成功")
          }
        }
      )
    },
    getmodel (_id) {
      if (_id){
        this.modelId = _id
      }
      process.getModelXml({ id: this.modelId}).then(r => {
        if (r.code == '200') {
          this.xml = r.data
          this.bpmnModeler.importXML(r.data, function (err) {
            if (err) {
              console.error(err)
            } else {
              // 这里还没用到这个，先注释掉吧
              // that.success()
            }
          })
        }
      })
    },

    // 随机生成六位数
    getRandomSixNum() {
      let RandomSixStr = ''
      for(let i = 0;i < 6; i++) {
        RandomSixStr += String(Math.floor(Math.random()*10))
      }
      return RandomSixStr
    },

    add(){
      this.modelId = null
      let id = this.getRandomSixNum()
      let bpmnXmlStr = `<?xml version="1.0" encoding="UTF-8"?>
        <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
          <bpmn2:process id="process${id}" name="流程${id}">
            <bpmn2:documentation>描述</bpmn2:documentation>
            <bpmn2:startEvent id="StartEvent_1" name="开始"/>
          </bpmn2:process>
          <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="process${id}">
              <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                <dc:Bounds x="184" y="64" width="36" height="36"/>
                <bpmndi:BPMNLabel>
                  <dc:Bounds x="191" y="40" width="22" height="14"/>
                </bpmndi:BPMNLabel>
              </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
          </bpmndi:BPMNDiagram>
        <processType id="test"/>
        </bpmn2:definitions>
      `
      this.xml = bpmnXmlStr

      this.bpmnModeler.importXML(bpmnXmlStr, function (err) {
        if (err) {
          console.error(err)
        } else {
          // 这里还没用到这个，先注释掉吧
          // that.success()
        }
      })
    },



    createNewDiagram () {
      return
      let id = this.getRandomSixNum()
      console.log(id)
      const bpmnXmlStr = `
      <?xml version="1.0" encoding="UTF-8"?>
        <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
          <bpmn2:process id="${id}" name="流程${id}">
            <bpmn2:documentation>描述</bpmn2:documentation>
            <bpmn2:startEvent id="StartEvent_1" name="开始"/>
          </bpmn2:process>
          <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="${id}">
              <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                <dc:Bounds x="184" y="64" width="36" height="36"/>
                <bpmndi:BPMNLabel>
                  <dc:Bounds x="191" y="40" width="22" height="14"/>
                </bpmndi:BPMNLabel>
              </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
          </bpmndi:BPMNDiagram>
        <processType id="test"/>
        </bpmn2:definitions>
      `

      // 将字符串转换成图显示出来
      this.bpmnModeler.importXML(bpmnXmlStr, function (err) {
        if (err) {
          console.error(err)
        } else {
          // 这里还没用到这个，先注释掉吧
          // that.success()
        }
      })
    },

    // 下载为SVG格式,done是个函数，调用的时候传入的
    saveSVG (done) {
      // 把传入的done再传给bpmn原型的saveSVG函数调用
      this.bpmnModeler.saveSVG(done)
    },
    // 下载为SVG格式,done是个函数，调用的时候传入的
    saveDiagram (done) {
      // 把传入的done再传给bpmn原型的saveXML函数调用
      this.bpmnModeler.saveXML({ format: true }, function (err, xml) {
        done(err, xml)
      })
    },
    // 当图发生改变的时候会调用这个函数，这个data就是图的xml
    setEncoded (link, name, data) {
      this.xml = data
      // 把xml转换为URI，下载要用到的
      const encodedData = encodeURIComponent(data)
      // 获取到图的xml，保存就是把这个xml提交给后台
      this.xmlStr = data
      // 下载图的具体操作,改变a的属性，className令a标签可点击，href令能下载，download是下载的文件的名字
      if (data) {
        link.className = 'active'
        link.href = 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData
        link.download = name
      }
    }
  },
  mounted () {
    // 获取到属性ref为“content”的dom节点
    this.container = this.$refs.content
    // 获取到属性ref为“canvas”的dom节点
    const canvas = this.$refs.canvas

    var customTranslateModule = {
      translate: ['value', customTranslate]
    }

    // 建模，官方文档这里讲的很详细
    this.bpmnModeler = new BpmnModeler({
      container: canvas,
      // 添加控制板
      propertiesPanel: {
        parent: '#js-properties-panel'
      },
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        customTranslateModule,
        customControlsModule
      ],
      moddleExtensions: {
        activiti: activitiModdleDescriptor
        // camunda: camundaModdleDescriptor
      }
    })

    // 下载画图
    const _this = this
    // 获取a标签dom节点
    const downloadLink = this.$refs.saveDiagram
    const downloadSvgLink = this.$refs.saveSvg
    // 给图绑定事件，当图有发生改变就会触发这个事件
    this.bpmnModeler.on('commandStack.changed', function () {
      _this.saveSVG(function (err, svg) {
        _this.setEncoded(downloadSvgLink, 'diagram.svg', err ? null : svg)
      })

      _this.saveDiagram(function (err, xml) {
        _this.setEncoded(downloadLink, 'diagram.bpmn', err ? null : xml)
      })
    })

    // this.createNewDiagram(this.bpmnModeler)

    this.createNewDiagram()

    // if (this.id){
    //   this.modelId = this.id
    //   this.getmodel()
    // }
  }
}
</script>

<style lang="less" scoped>

  /*左边工具栏以及编辑节点的样式*/
  @import '~bpmn-js/dist/assets/diagram-js.css' ;
  @import '~bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
  @import '~bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
  @import '~bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
  .flow-container {
    display: flex;
    height: 100%;
  }
  /*右边工具栏样式*/
  @import '~bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
  .containers{
    position: relative;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }
  .canvas{
    width: 100%;
    height: 100%;
  }
  .panel{
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    height:100%;
    overflow-y:scroll;
  }
  .buttons{
    position: absolute;
    left: 20px;
    bottom: 20px;
  &>li{
     display:inline-block;margin: 5px;
  &>a{
     color: #999;
     background: #eee;
     cursor: not-allowed;
     padding: 8px;
     border: 1px solid #ccc;
  &.active{
     color: #333;
     background: #fff;
     cursor: pointer;
   }
  }
  }
  }
</style>
