<template>
  <el-dialog :visible.sync="show" :title="title"  fullscreen destroy-on-close>
    <el-form class="form"  :model="formdata"  ref="formdata" :rules="rules"  label-width="80px" label-position="left">
      <el-form-item label="标题" prop="theme" >
        <el-input  v-model="formdata.theme"/>
      </el-form-item>
      <el-form-item label="通知对象" prop="notice_object_type">
        <el-select v-model="formdata.notice_object_type" placeholder="请选择通知对象">
          <el-option label="全体用户" value="any"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="0px" prop="value" style="flex: 1" >
        <tinymce-editor ref="editor" plugins="lists image table wordcount" v-model="formdata.value" v-if="show"></tinymce-editor>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import tinymceEditor from '../../components/tinymceEditor'
  import {noteMsg} from '../../api/common'

  export default {
    name: '',
    components: {
      tinymceEditor
    },
    data () {
      return {
        formdata:{theme:"",value: ''},
        isNew: false,
        title: '',
        show: false,
        rules: {
          theme: [
            { required: true, message: '请输入标题', trigger: ['blur', 'change'] }
          ],
          notice_object_type: [
            { required: true, message: '请选择通知对象', trigger: ['blur', 'change'] }
          ],
          value: [
            { required: true, message: '请输入内容', trigger: ['blur', 'change'] }
          ],
        }
      }
    },
    mounted(){

    },
    methods: {
      view(sguid){
        noteMsg.msg({sguid: sguid}).then(r=>{
          if (r.code=='200'){
            this.formdata = r.data
            this.show = true
          }
        })
      },
      add(){
        this.isNew = true,
          this.formdata = {theme:"",value: ''}
        this.title = '新增'
        this.show = true
      },
      handleOk(){
        this.$refs.formdata.validate((valid) => {
          if (valid) {
            noteMsg.saveData(this.formdata).then(r=>{
              if (r.code=='200'){
                this.$message.success("操作成功")
                this.isNew = false
                this.show = false
                this.$emit("confirm",this.formdata)
              }
            })
          } else {
            return false;
          }
        });
      },
    },

  }
</script>

<style scoped>

  .form{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /deep/ .is-fullscreen{
    display: flex;
    flex-direction: column;
  }

  /deep/ .el-dialog__body{
    flex: 1;
  }

</style>
