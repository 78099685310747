'use strict'

var ImplementationTypeHelper = require('../../../helper/ImplementationTypeHelper')
var InputOutputHelper = require('../../../helper/InputOutputHelper')

var entryFactory = require('../../../factory/EntryFactory')
var cmdHelper = require('../../../helper/CmdHelper')

function getImplementationType (element) {
  return ImplementationTypeHelper.getImplementationType(element)
}

function getBusinessObject (element) {
  return ImplementationTypeHelper.getServiceTaskLikeBusinessObject(element)
}

function getConnector (bo) {
  return InputOutputHelper.getConnector(bo)
}

function isConnector (element) {
  return getImplementationType(element) === 'connector'
}

module.exports = function (group, element, bpmnFactory, translate) {
  group.entries.push(entryFactory.textField({
    id: 'connectorId',
    label: translate('Connector Id'),
    modelProperty: 'connectorId',

    get: function (element, node) {
      var bo = getBusinessObject(element)
      var connector = bo && getConnector(bo)
      var value = connector && connector.get('connectorId')
      return { connectorId: value }
    },

    set: function (element, values, node) {
      var bo = getBusinessObject(element)
      var connector = getConnector(bo)
      return cmdHelper.updateBusinessObject(element, connector, {
        connectorId: values.connectorId || undefined
      })
    },

    validate: function (element, values, node) {
      return isConnector(element) && !values.connectorId ? { connectorId: translate('Must provide a value') } : {}
    },

    hidden: function (element, node) {
      return !isConnector(element)
    }

  }))
}
