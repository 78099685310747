<template>
    <div class="handle">
        <div style="padding: 15px 15px 15px ;border-bottom: 1px solid #eeeeee">
          <el-button type="primary" icon="el-icon-upload" @click="upg">提交设计图</el-button>
          <el-button type="primary" icon="el-icon-s-order" @click="qut">提交报价</el-button>
          <el-button type="primary" icon="el-icon-s-check" @click="chk">审核</el-button>
          <i class="el-icon-close"  @click="close" style="float: right;font-size: 24px"></i>
        </div>
      <div style="border-bottom: 1px solid #eeeeee;padding: 0 20px">
        <div style="width: 800px;display: flex;">
          <div style="display: flex; border-right: 1px solid #eeeeee; padding-right: 20px">
            <div style="padding: 20px 0;width: 80px">上传设计</div>
            <div style="padding: 10px 0">
              <el-upload
                ref = "uploada"
                class="upload-demo"
                multiple
                :action= "upfile.action"
                :headers="upfile.headers"
                :data="upfile.data"
                :on-success="handleAvatarSuccess"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="files">
                <el-button size="small" type="primary">选择文件</el-button>
              </el-upload>
            </div>
          </div>

          <div style="padding-left: 20px;flex: 1">
            <div style="display: flex;align-items: center;">
              <div style="padding: 20px 0;width: 80px">报价</div>
              <el-input v-model="formdata.price" type="number"></el-input>
            </div>
            <div style="display: flex;align-items: center;padding-bottom:10px;">
              <div style="width: 80px">价格说明</div>
              <el-input v-model="formdata.pricedesc" type="textarea"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="flex: 1; overflow: auto;padding: 20px 20px">
        <edit ref="bill" class="bill"/>
      </div>
    </div>
</template>

<script>
import edit from '../feibiaodingzhi/edit'
import { chkfeibiaodingzhi, getonefeibiaodingzhi, qutfeibiaodingzhi, upgfeibiaodingzhi } from '../../api/common'
import { getToken, rowid } from '../../util/common'
import { uploadUrl } from '../../config'

export default {
  name: '',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      carddata: { child: { zhugui: {}, jingzi: {} } },
      files: [],
      formdata: { id: '', docs: '[]', price: 0, pricedesc: '' },
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      }
    }
  },
  components: {
    edit
  },
  methods: {
    // 设计图
    upg () {
      if (!this.formdata.docs || this.formdata.docs == '[]') {
        this.$message.warning('你还没上传文件，不能提交！')
        return
      }
      upgfeibiaodingzhi(this.formdata).then(r => {
        if (r.code == '205') { this.$message.warning(r.message) }
        this.$emit('success', null)
        console.log(r)
      })
    },
    // 报价
    qut () {
      if (!this.formdata.price) {
        this.$message.warning('你还没输入报价金额，不能提交！')
        return
      }
      qutfeibiaodingzhi(this.formdata).then(r => {
        if (r.code == '205') { this.$message.warning(r.message) }
        this.$emit('success', null)
        console.log(r)
      })
    },
    // 审核
    chk () {
      chkfeibiaodingzhi({ id: this.formdata.id }).then(r => {
        if (r.code == '205') { this.$message.warning(r.message) }
        this.$emit('success', null)
        console.log(r)
      })
    },
    handlePreview (file) {
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = file.name
      if (file.response) { a.href = this.$options.filters.getImgSrc(file.response.data.path) } else { a.href = this.$options.filters.getImgSrc(file.url) }
      a.dispatchEvent(event)
    },
    handleAvatarSuccess (response, file, filelist) {
      const docs = []
      for (var i = 0; i < filelist.length; i++) {
        if (filelist[i].response) { docs.push({ name: filelist[i].name, url: filelist[i].response.data.path }) } else { docs.push({ name: filelist[i].name, url: filelist[i].url }) }
      }
      this.formdata.docs = JSON.stringify(docs)
    },
    handleRemove (file, filelist) {
      const docs = []
      for (var i = 0; i < filelist.length; i++) {
        if (filelist[i].response) { docs.push({ name: filelist[i].name, url: filelist[i].response.data.path }) } else { docs.push({ name: filelist[i].name, url: filelist[i].url }) }
      }
      this.formdata.docs = JSON.stringify(docs)
    },
    close (e) {
      this.$emit('close', e)
    },
    gdata () {
      getonefeibiaodingzhi({ id: this.id }).then(r => {
        this.formdata.id = r.data.id
        this.formdata.docs = r.data.docs
        if (r.data.price) { this.formdata.price = r.data.price }
        this.formdata.pricedesc = r.data.pricedesc
        this.files = JSON.parse(r.data.docs || '[]')

        // 显示单据的数据
        const d = Object.assign({}, r.data)
        d.child = JSON.parse(d.child)
        d.imgs = JSON.parse(d.imgs || '[]')
        this.$refs.bill.formdata = d
      })
    }
  },
  mounted () {
    this.$refs.bill.disabledall = true
    this.gdata()
  }

}

</script>

<style scoped>
  .handle{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .handle /deep/ .btn-box{
    display: none;
  }

  .bill{
    width:800px;
  }
</style>
