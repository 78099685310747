<template>
  <div>
    <el-row align="middle" type="flex">
      <el-col :span="4">
        <label>经销商名称：</label>
      </el-col>
      <el-col :span="5">
        <label>{{formdata.makername}}</label>
      </el-col>
      <el-col :span="3">
        <label>联系电话:</label>
      </el-col>
      <el-col :span="5">
        <span>{{formdata.lianxidianhua}}</span>
<!--        <el-input placeholder="请输入联系电话" v-model="formdata.lianxidianhua"></el-input>-->
      </el-col>
      <el-col :span="3">
        <label>下单时间：</label>
      </el-col>
      <el-col :span="5">
        <span>{{formdata.makedate|dateformat}}</span>
      </el-col>
    </el-row>
    <table border="1px" cellspacing="0" width="100%">
      <tr >
        <td colspan="9" style="vertical-align: middle;text-align: center; height: 74px;"><img v-if="brandLogo" width="143" height="68" :src="brandLogo"/></td>
        <td colspan="16" style="vertical-align: middle;text-align: center;font-size: 20px;font-weight: 600">浴室柜非标订做单</td>
      </tr>
      <tr>
        <td colspan="4" style="color: red">业主名称</td>
        <td colspan="5" ><el-input :readonly="disabledall" v-model="formdata.kehumingcheng"></el-input></td>
        <td colspan="3" style="color: red">业主电话</td>
        <td colspan="5" ><el-input :readonly="disabledall" v-model="formdata.yezhudianhua"></el-input></td>
        <td colspan="3" style="color: red">负责店员</td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.clerk"></el-input></td>
        <!--          <td colspan="3" >上传日期:</td>-->
        <!--          <td colspan="3" ><el-input></el-input></td>-->

      </tr>
      <tr>
        <td colspan="4" style="color: red">小区</td>
        <td colspan="5" ><el-input :readonly="disabledall" v-model="formdata.xiaoqu"></el-input></td>
        <td colspan="3" style="color: red">门牌号</td>
        <td colspan="5" ><el-input :readonly="disabledall" v-model="formdata.menpaihao"></el-input></td>
        <td colspan="3" style="color: red">店员电话</td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.dianyuandianhua"></el-input></td>
      </tr>
      <tr>
<!--        <td colspan="4">指定设计师</td>-->
<!--        <td colspan="8">-->
<!--          <el-select clearable :disabled="disabledall||true" v-model="formdata.zhidingshejishi " placeholder="请选择">-->
<!--            <el-option-->
<!--              v-for="item in dict_shejishi"-->
<!--              :key="item.user_id"-->
<!--              :label="item.realname"-->
<!--              :value="item.user_id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </td>-->
        <td colspan="5">要求完成设计时间</td>
        <td colspan="8" >
          <el-date-picker :readonly="disabledall" style="width: 100%" size="mini"
                          v-model="formdata.yaoqiushejishijian"
                          type="date"
                          placeholder="选择日期">
          </el-date-picker></td>
      </tr>
      <tr>
        <td rowspan="8" colspan="3">规格尺寸:</td>
        <td colspan="3" style="color: red">名称</td>
        <td colspan="4" style="color: red">型号</td>
        <td colspan="5" style="color: red">长*宽*高（mm）</td>
        <td colspan="4" style="color: red">材 质</td>
        <td colspan="6" style="color: red">颜 色</td>
      </tr>
      <tr>
        <td colspan="3">主柜</td>
        <td colspan="4"><el-input :readonly="disabledall" v-model="formdata.child.zhugui.name"></el-input></td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.child.zhugui.size"></el-input></td>
        <td colspan="4"><el-input :readonly="disabledall" v-model="formdata.child.zhugui.caizhi"></el-input></td>
        <td colspan="6"><el-input :readonly="disabledall"  v-model="formdata.child.zhugui.color"></el-input></td>
      </tr>
      <tr>
        <td colspan="3">浴镜</td>
        <td colspan="4"><el-input :readonly="disabledall" v-model="formdata.child.jingzi.name"></el-input></td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.child.jingzi.size"></el-input></td>
        <td colspan="4"><el-input :readonly="disabledall" v-model="formdata.child.jingzi.caizhi"></el-input></td>
        <td colspan="6"><el-input :readonly="disabledall" v-model="formdata.child.jingzi.color"></el-input></td>
      </tr>
      <tr>
        <td colspan="3" style="color: red">台面材质</td>
        <td colspan="8"><el-input :readonly="disabledall" v-model="formdata.taimiancaizhi"></el-input></td>
        <td colspan="3" style="color: red">边型</td>
        <td colspan="8">
          <el-select clearable :disabled="disabledall"  v-model="formdata.bianxing" placeholder="请选择">
            <el-option
              v-for="item in dict_bianxing"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="color: red">台盆</td>
        <td colspan="4">
          <el-select clearable :disabled="disabledall" v-model="formdata.penxuanpei " placeholder="选配">
            <el-option
              v-for="item in dict_penxuanpei"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <td colspan="4"><el-input sp :readonly="disabledall" v-model="formdata.zipeipenxinghao"  placeholder="型号"></el-input></td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.zipeipenchicun" placeholder="尺寸"></el-input></td>
        <!--          台上盆或台下盆-->
        <td colspan="3">
          <el-select clearable :disabled="disabledall" v-model="formdata.taipenleixing " placeholder="类型">
            <el-option
              v-for="item in dict_taipenleixing"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <!--          单盆或双盆-->
        <td colspan="3">
          <el-select clearable :disabled="disabledall" v-model="formdata.taipenshuliang " placeholder="数量">
            <el-option
              v-for="item in dict_taipenshuliang"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="color: red">龙头孔</td>
        <!--          单孔或双孔-->
        <td colspan="4">
          <el-select clearable :disabled="disabledall" v-model="formdata.longtoukong " placeholder="请选择">
            <el-option
              v-for="item in dict_longtoukong"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <td colspan="3">出水嘴长度</td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.chushuichangdu" type="number"></el-input></td>
        <td colspan="4" style="color: red">带后提拉杆</td>
        <td colspan="3">
          <el-select clearable :disabled="disabledall" v-model="formdata.houtilagan " placeholder="请选择">
            <el-option
              v-for="item in dict_yesno"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>

      </tr>
      <tr>
        <td colspan="3" style="color: red">靠墙方式</td>
        <td colspan="4">
          <el-select clearable :disabled="disabledall" v-model="formdata.kaoqiangfangshi " placeholder="请选择">
            <el-option
              v-for="item in dict_kaoqiangfangshi"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <!--        原是是其它特殊 21.7.8-->
        <td colspan="3" style="color: red">门套</td>
        <td colspan="5"><el-input :readonly="disabledall" v-model="formdata.qitateshu"></el-input></td>
        <td colspan="3">门套厚度</td>
        <td colspan="4"><el-input :readonly="disabledall" v-model="formdata.mentaohoudu"  type="number"></el-input></td>
      </tr>
      <tr>
        <td colspan="3" style="color: red">下水颜色</td>
        <td colspan="4">
          <el-select clearable :disabled="disabledall" v-model="formdata.xiashuiyanse " placeholder="请选择">
            <el-option
              v-for="item in dict_xiashuiyanse"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <td colspan="15"></td>
      </tr>
      <tr>
        <td colspan="25">其它要求说明：</td>
      </tr>
      <tr>
        <td colspan="5">进水以及排水方式</td>
        <td colspan="7">
          <el-select clearable :disabled="disabledall" v-model="formdata.jinpaishuifangshi " placeholder="请选择">
            <el-option
              v-for="item in dict_jinpaishuifangshi"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <td colspan="5" style="color: red">是否有现场照片</td>
        <td colspan="8">
          <el-select clearable :disabled="disabledall" v-model="formdata.xianchangzhaopian " placeholder="请选择">
            <el-option
              v-for="item in dict_yesno"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
<!--        21.7.22改成墙排位置高度-->
<!--        <td colspan="5">特殊五金配置情况</td>-->
        <td colspan="5" :style="{'color':(formdata.jinpaishuifangshi=='墙排'?'red':'#000000')}">墙排位置高度</td>
        <td colspan="7"><el-input :readonly="disabledall" v-model="formdata.wujinpeizhiqingkuang"></el-input></td>
        <td colspan="5">尺寸现场核对</td>
        <td colspan="8">
          <el-select clearable :disabled="disabledall" v-model="formdata.chicunxianchanghedui " placeholder="请选择">
            <el-option
              v-for="item in dict_yesno"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td colspan="25">
          <div>简易图纸、现场图或者详细的更改说明：</div>
          <el-input :readonly="disabledall" type="textarea" resize="none" v-model="formdata.shuoming"  :autosize="{minRows: 8, maxRows: 8}" placeholder="说明"></el-input>
          <div style="min-height: 150px">
            <el-upload
              ref="upload"
              drag
              :disabled="disabledall"
              :action= "upfile.action"
              :show-file-list="true"
              accept="image/png,image/jpg,image/jpeg"
              :multiple = "true"
              :headers="upfile.headers"
              :data="upfile.data"
              :on-error="uperror"
              :file-list="files"
              list-type="picture-card"
              :on-success="handleAvatarSuccess"
              :on-preview="handlePictureCardPreview">
              <!--                :on-remove="handleRemove"-->
              <!--                  <i class="el-icon-plus"></i>-->
              <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 100%">
                <i class="el-icon-plus"></i>
                <div style="line-height: 40px;font-size: 16px">上传图片</div>
              </div>
              <div slot="file" slot-scope="scope">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="$options.filters.getImgSrc(scope.file.url)" alt=""
                >
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(scope.file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <!--                  <span-->
                  <!--                    v-if="!disabled"-->
                  <!--                    class="el-upload-list__item-delete"-->
                  <!--                    @click="handleDownload(file)"-->
                  <!--                  >-->
                  <!--                    <i class="el-icon-download"></i>-->
                  <!--                  </span>-->
                  <span
                    v-if="!disabledall"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(scope.file, scope)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </td>
      </tr>
      <!--        <tr>-->
      <!--          <td colspan="4">要求交货时间：</td>-->
      <!--          <td colspan="4"><el-input></el-input></td>-->
      <!--          <td colspan="4">生产回复生产时间：</td>-->
      <!--          <td colspan="4"><el-input></el-input></td>-->
      <!--          <td colspan="4">最后确认交货日期</td>-->
      <!--          <td colspan="5"><el-input></el-input></td>-->
      <!--        </tr>-->
      <!--        <tr>-->
      <!--          <td colspan="4">客户签字：</td>-->
      <!--          <td colspan="4"><el-input></el-input></td>-->
      <!--          <td colspan="4">客户确认打款时间：</td>-->
      <!--          <td colspan="4"><el-input></el-input></td>-->
      <!--          <td colspan="4">销售接收确认下单时间：</td>-->
      <!--          <td colspan="5"><el-input></el-input></td>-->
      <!--        </tr>-->
      <tr>
        <td colspan="25">备注：1.所有订做产品请仔细填写以上表格内容。 </td>
      </tr>
    </table>
    <div class="btn-box" :class="data===null?'':'hide'">
      <el-button @click="post" type="primary">提交</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
    <el-image-viewer v-if="showViewer" :initialIndex="index" :on-close="closeViewer" style="z-index: 9999"  :url-list="imgArr"></el-image-viewer>
  </div>
</template>

<script>
  import { uploadUrl } from '../../config'
  import { getToken, rowid , isEmpty} from '../../util/common'
  import WxValidate from "../../util/WxValidate";
  import {find_getbyfind} from '../../api/common'
  import mlslogo from  "@/assets/mlslog.png"
  import ddlogo from  "@/assets/ddlogo.png"

  export default {
    name: 'edit',
    data () {
      return {
        brandLogo: null,
        showViewer: false,
        index: 0,
        options: {},
        imgArr:null,
        files:[],
        disabledall: false,
        disabled: false,
        WxValidate1:{},
        WxValidate2:{},
        upfile: {
          action: uploadUrl,
          headers: { Authorization: 'Bearer ' + getToken() },
          data: { bizPath: 'vote' }
        },
        formdata: {child:{zhugui:{},jingzi:{}}},
        dict_bianxing:[{value:'复杂边'},{value:'法国边'},{value:'大斜边'},{value:'单边'},{value:'其它边形'},{value:'直边'},{value:'下挂直边'},{value:'异形边'},{value:'简易边'},{value:'无'},{value:'中式边'},{value:'下挂中式边'}],
        dict_taipenleixing:[{value:'台上'},{value:'台下'},{value:'无边'},{value:'无缝'},{value:'无'}],
        dict_taipenshuliang:[{value:'单盆'},{value:'双盆'},{value:'无'}],
        dict_longtoukong:[{value:'单孔'},{value:'双孔'},{value:'三孔'},{value:'墙出龙头'},{value:'无'}],
        dict_yesno:[{value:'是'},{value:'否'}],
        dict_jinpaishuifangshi :[{value:'墙排'},{value:'地排'}],
        dict_shejishi:[],
        dict_penxuanpei:[{value:'自配'},{value:'厂配'},{value:'无'}],
        dict_kaoqiangfangshi:[{value:'后靠墙'},{value:'左后靠墙'},{value:'右后靠墙'},{value:'三面靠墙'},{value:'不靠墙'}],
        dict_xiashuiyanse:[{value:'镀铬'},{value:'枪灰'},{value:'哑黑'},{value:'玫瑰金'},{value:'拉丝金'}]
      }
    },
    created(){
      find_getbyfind({code:'g_designer_out', param:{a:'dd'}}).then(res=>{
        if (res.code == '200'){
          this.dict_shejishi =res.data.data
        }
      })
      let userinfo = localStorage.getItem('userinfo')
      let id = JSON.parse(userinfo).id
      find_getbyfind({code:'feibiaophone', param:{cuscode: id}}).then(res=>{
        try{
          let brand = res.data.data.length >0 ? res.data.data[0].fc6: null
          if (brand == "杜达"){
             this.brandLogo = ddlogo
          }else{
            this.brandLogo = mlslogo
          }

          let fphone =  res.data.data[0].fphone
          this.$set(this.formdata,'lianxidianhua',fphone)
        }catch (er){
          this.$set(this.formdata,'lianxidianhua','')
        }
      })
      this.initValidate1()
      this.initValidate2()
    },

    methods: {
      closeViewer(){
        this.showViewer = false
      },
      initValidate1() {
        const rules = {
          kehumingcheng: { required: true },
          yezhudianhua: { required:true, tel:true },
          xiaoqu:{required:true },
          menpaihao:{required:true },
          clerk: { required:true },
          dianyuandianhua:{ required:true , tel:true},
          // lianxidianhua:{ required:false  },
          taimiancaizhi: { required:true },
          bianxing: { required:true },
          penxuanpei:{required:true},
          zipeipenxinghao: { required:true },
          longtoukong: { required:true },
          houtilagan: { required:true },
          kaoqiangfangshi:{required: true},
          xiashuiyanse:{required: true},
          qitateshu:{required:true},
          xianchangzhaopian: { required:true },
        }
        const messages = {
          kehumingcheng: { required: '请填写业主名称' },
          yezhudianhua: {required: '请填写业主电话',  tel:'请填写正确的业主电话（手机号）' },
          xiaoqu: {required: '请填写小区' },
          menpaihao: {required: '请填写门牌号' },
          clerk: { required:'请填写负责店员' },
          dianyuandianhua:{required:'请填写店员电话' ,  tel:'请填写正确的店员电话（手机号）'},
          // lianxidianhua: { required:'请填写联系电话',  tel:'请填写正确的联系电话（手机号）' },
          taimiancaizhi: { required:'请填写台面材质' },
          bianxing: { required:'请选择台面边型' },
          penxuanpei:{required:'请选择台盆是自配还是厂配'},
          zipeipenxinghao: { required:'请填写台盆型号' },
          longtoukong: { required:'请选择龙头孔' },
          houtilagan: { required:'请选择是否带后提拉杆' },
          kaoqiangfangshi:{required:'请选择靠墙方式'},
          xiashuiyanse:{required:'请选择下水颜色'},
          qitateshu:{required:'请填写门套，没有请填无'},
          xianchangzhaopian: { required:'请选择是否有现场照片' }
        }
        this.WxValidate1 = new WxValidate(rules, messages)
      },
      initValidate2() {
        const rules = {
          name:{required: true},
          size:{required: true},
          caizhi:{required: true},
          color:{required: true}
        }
        const messages = {
          name: {required:'请填写主柜型号'},
          size: {required:'请填写主柜尺寸'},
          caizhi: {required:'请填写主柜材质'},
          color: {required:'请填写主柜颜色'},
        }
        this.WxValidate2 = new WxValidate(rules, messages)
      },
      uperror (err) {
        console.log(err)
      },
      handleAvatarSuccess (response, file, filelist) {
      },
      handlePictureCardPreview (file) {
        let files = this.$refs.upload.uploadFiles

        let imgs = []
        for(var i=0;i< files.length;i++){
          if (files[i].response)
            imgs.push(files[i].response.data.path)
          else
            imgs.push(files[i].url)
        }
        let indexstr = file.response ? file.response.data.path : file.url
        let index = imgs.indexOf(indexstr)
        this.imgArr = imgs.map(im => {
          return this.$options.filters.getImgSrc(im)
        })
        this.index= index
        this.showViewer = true
      },
      handleRemove (file) {
        var files = this.$refs.upload.uploadFiles
        let val = files.findIndex(function (ele,index) {
          return ele.uid == file.uid;
        });
        if (val > -1){
          files.splice(val, 1)
        }
      },
      cancel (e) {
        this.$emit('cancel', e)
      },
      post (e) {
        var files = this.$refs.upload.uploadFiles
        var imgs = []
        for(var i=0;i< files.length;i++){
          if (files[i].response)
            imgs.push({name:  files[i].name,url:files[i].response.data.path})
          else
            imgs.push({name:  files[i].name,url:files[i].url})
        }
        this.formdata.imgs = imgs

        if (!this.WxValidate1.checkForm(this.formdata)) {
          let error = this.WxValidate1.errorList[0];
          this.$message.error(error.msg)
          return
        }
        if (!this.WxValidate2.checkForm(this.formdata.child.zhugui)) {
          let error = this.WxValidate2.errorList[0];
          this.$message.error(error.msg)
          return
        }

        if (this.formdata.lianxidianhua ==   this.formdata.yezhudianhua){
          this.$message.error('联系电话或业主电话填写不正确，两个号码不能相同!')
          return
        }
        if (this.formdata.dianyuandianhua ==   this.formdata.yezhudianhua){
          this.$message.error('业主电话或店员电话填写不正确，两个号码不能相同!')
          return
        }

        if (this.formdata.jinpaishuifangshi == '墙排' && isEmpty(this.formdata.wujinpeizhiqingkuang)){
          this.$message.error('请填写墙排位置高度')
          return
        }
        if (this.formdata.longtoukong == '墙出龙头' && isEmpty(this.formdata.chushuichangdu)){
          this.$message.error('请填写出水嘴长度')
          return
        }
        if (!isEmpty(this.formdata.qitateshu) && isEmpty(this.formdata.mentaohoudu)){
          if (this.formdata.qitateshu.trim() != "无")
          {
            this.$message.error('请填写门套厚度')
            return
          }
        }
        if (!this.formdata.imgs || this.formdata.imgs.length == 0){
          this.$message.error('请上传图片')
          return
        }
        if (this.formdata.child.zhugui.name){
          this.formdata.model = this.formdata.child.zhugui.name
          this.formdata.size = this.formdata.child.zhugui.size
        }else if(this.formdata.child.jingzi.name){
          this.formdata.model = this.formdata.child.jingzi.name
          this.formdata.size = this.formdata.child.jingzi.size
        }
        this.$emit('post', this.formdata)
      }
    },
    props:{
      data:{
        default: null
      }
    },
    mounted(){
      if(this.data != null){
        this.formdata = this.data
        this.files =  JSON.parse(this.data.imgs||'[]')
      }else{
        let userinfo = localStorage.getItem('userinfo')
        this.formdata.makername = JSON.parse(userinfo).realname
        this.formdata.makedate = new Date()
      }
    }
  }
</script>

<style scoped>

  table
  {
    border-collapse:collapse;
    table-layout: fixed;
  }

  tr{
    height: 30px;
  }

  .btn-box{
    padding: 10px 0;
    text-align: right;
  }
  .btn-box.hide{
    display: none;
  }
  /deep/ .el-input__inner{
    border: 0px;
  }
  /deep/ .el-textarea__inner{
    border: 0px;
    padding: 5px;
  }
  /deep/ .el-upload-list__item-thumbnail{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    -webkit-transform: translate3d(-50%,-50%,0);
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  }

  .contextmenu li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
  }

  .contextmenu li:hover {
    background: #eee;
  }

  .paste:hover{
    color: #3E84E9;
    border: 1px solid #3E84E9 !important;
  }

  ::v-deep .el-upload-dragger{
    height: 100%;
    width: 100%;
  }

</style>
