<template>
    <div class="container">
      <div class="top-bar">
        <el-button type="primary" @click="add">新增</el-button>
      </div>
      <el-table :data="setdata" border>
        <el-table-column v-for="(col, index) in fields" :key="index" :label="col.name" :prop="col.field" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span class="act-edit" @click="edit_set(scope.row)">编辑</span>
            <el-divider direction="vertical"/>
            <span class="act-delete" @click="delete_set(scope.row)">删除</span>
          </div>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="dialog" :title="dialogtitle" width="600px">
        <el-form label-width="80px" ref="setform" :model="formdata" :rules="rules">
          <el-row>
          <el-col :span="22">
          <el-form-item v-for="(col, index) in fields" :key="index" :label="col.name" :prop="col.field">
            <el-input v-if="col.type==='textarea'" type="textarea" v-model="formdata[col.field]"/>
            <el-input v-else v-model="formdata[col.field]"/>
          </el-form-item>
            <el-form-item style="text-align: right">
              <el-button @click="dialog=false">关闭</el-button>
              <el-button type="primary" @click="formConfirm">确认</el-button>
            </el-form-item>
          </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
</template>

<script>
import { findAdd, findDelete, findEdit, findList } from '../api/common'

export default {
  name: '',
  data () {
    return {
      formdata: {},
      dialog: false,
      dialogtitle: '',
      fields: [
        { field: 'code', name: '编码' },
        { field: 'name', name: '名称' },
        { field: 'pk', name: '主键' },
        { type: 'textarea', field: 'sql', name: 'SQL' },
        { type: 'textarea', field: 'fieldandname', name: '字段名称' }
      ],
      setdata: [],
      rules: {
        code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        pk: [{ required: true, message: '请输入主键', trigger: 'blur' }],
        sql: [{ required: true, message: '请输入SQL', trigger: 'blur' }],
        fieldandname: [{ required: true, message: '请输入字段名称', trigger: 'blur' }]
      }
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      findList().then(res => {
        if (res.code === '200') {
          this.setdata = res.data
        }
      })
    },
    add () {
      this.formdata = {}
      this.dialogtitle = '新增'
      this.dialog = true
    },
    edit_set (row) {
      this.formdata = Object.assign({}, row)
      this.dialogtitle = '编辑'
      this.dialog = true
    },
    delete_set (row) {
      this.$confirm('确认删除吗?', '提示').then(() => {
        findDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.dialog = false
            this.$message.success(res.message)
            this.load()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    formConfirm () {
      this.$refs.setform.validate((valid) => {
        if (valid) {
          if (this.dialogtitle === '新增') {
            findAdd(this.formdata).then((res) => {
              if (res.code === '200') {
                this.dialog = false
                this.$message.success(res.message)
                this.load()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            findEdit(this.formdata).then((res) => {
              if (res.code === '200') {
                this.dialog = false
                this.$message.success(res.message)
                this.load()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    }
  }

}
</script>

<style scoped lang="scss" type="text/scss">

 .container{
   background-color: white;
 }

 .act-edit, .act-delete{
   color: $color-primary;
 }

 .act-edit, .act-delete:hover{
   cursor: pointer;
 }

 .top-bar .el-button{
   margin: 5px 10px;
   width: 80px;
 }

  @import "table.scss";
</style>
