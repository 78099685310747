<template>
    <div>
      <div>
        显示金额需要更高权限，请输入管理员密码
        <div style="margin-top: 5px; margin-bottom: 5px">
          <el-input type="password" v-model="password" ></el-input>
        </div>
        <div style="color: #3e84e9;font-size: 14px">*如未设置密码首次输入为设置密码</div>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveAddForm()">确 定</el-button>
          <el-button @click="handleclose">取 消</el-button>
        </div>
      </div>
    </div>
</template>

<script>
import { user_checkadvauth } from '../api/common'
export default {
  name: '',
  data () {
    return {
      password: ''
    }
  },
  methods: {
    handleclose () {
      this.$parent.handleClose()
    },
    saveAddForm () {
      if (!this.password) {
        this.$message({ message: '你还没输入密码，请输入密码！', type: 'warning' })
        return
      }
      user_checkadvauth({ password: this.password }).then(r => {
        if (r.code == '200') {
          if (r.data == true) {
            this.$emit('result', true)
            this.$parent.handleClose()
          } else {
            this.$emit('result', false)
            this.$message({ message: '密码不正确', type: 'warning' })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.dialog-footer{
  margin-top: 15px;
}
</style>
