<template>
  <div class="root">
    <new-view v-if="addcus" @close="addcus = false" title="客户信息表">
      <el-scrollbar :style="{'height':scHeight + 'px'}">
        <el-form class="cusform" label-width="120px" :model="cusForm">
          <el-col :span="12">
            <el-form-item label="所属城市/区域">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约品牌名">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="市场名">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责业务">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人电话">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="指定物流">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认联系人">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="传真和电话">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户收货地址">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人联系电话">
              <el-input></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <div class="formbutton">
          <el-button @click="addcus=false">关闭</el-button>
          <el-button type="primary">确定</el-button>
        </div>
      </el-scrollbar>
    </new-view>
    <div class="title">
      <div class="icon-background">
        <i class="iconfont icondianpu-"></i>
      </div>
      <div>专卖店</div>
      <el-button type="primary" class="add-button" @click="addcus = true">
        <span>添加</span>
        <el-divider direction="vertical"></el-divider>
        <i class="el-icon-plus"></i>
      </el-button>
    </div>
    <div class="tab-box">
      <el-tabs>
        <el-tab-pane label="全部专卖店 （10）">
          <el-table>
            <el-table-column v-for="(col,index) in table_column" :key="index" :label="col.text"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="我关注的 （3）">
          <el-table>
            <el-table-column v-for="(col,index) in table_column" :key="index" :label="col.text"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import NewView from '../../components/NewView'

export default {
  name: '',
  components: {
    NewView
  },
  data () {
    return {
      scHeight: document.documentElement.clientHeight - 147,
      cusForm: {},
      addcus: false,
      table_column: [{
        field: 'customer',
        text: '客户'
      },
      {
        field: 'brand',
        text: '签约品牌'
      },
      {
        field: 'city',
        text: '城市'
      },
      {
        field: 'market',
        text: '市场名'
      },
      {
        field: 'principal',
        text: '负责人'
      },
      {
        field: 'principal_phone',
        text: '负责人电话'
      },
      {
        field: 'contract',
        text: '合同'
      },
      {
        field: 'deposit',
        text: '定金'
      }]
    }
  },
  mounted () {
    var self = this
    /** 控制table的高度 */
    window.onresize = function () {
      self.scHeight = document.documentElement.clientHeight - 147
    }
  }
}
</script>

<style scoped lang="less" type="text/less">
  .title {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    padding: 15px;
  }

  .tab-box {
    background-color: white;
    padding: 10px;
    font-size: 14px;
  }

  /deep/ .el-tabs__item.is-active {
    color: #2362FB;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #2362fb;
  }

  .title {
    align-items: center;

    .icon-background {
      background-color: #4AB8B8;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
    }

    i {
      font-size: 20px;
      color: white;
      font-weight: 400;
      margin-right: 5px;
    }

    .add-button {
      margin-left: 15px;

      i {
        font-size: 13px;
      }
    }
  }
  .formbutton{
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 20px;
  }
</style>
<style>
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
</style>
