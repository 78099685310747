<template>
  <div v-if="visible" class="baseflowdialogcontainer" >
    <baseForm :params="params" @close="close" :defaultValue="defaultValue"></baseForm>
  </div>
</template>

<script>
  import baseForm from './form/baseForm'
  export default {
    components: { baseForm },
    props: ['visible','params','defaultValue'],
    data () {
      return {

      }
    },
    methods:{
      close(){
        this.$emit('close')
      }
    },

  }

</script>

<style scoped>
  .baseflowdialogcontainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F3F4F9;
    /*padding: 24px 24px 0;*/
    overflow-y:scroll;
    z-index: 999;
  }


</style>
