<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" label-width="80px" :inline="true" size="small" @submit.native.prevent>

        <el-form-item label="单据日期">
          <el-date-picker
            v-model="value"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="客户">
          <el-input v-model="queryParas[2].val" @keyup.enter.native="search"  placeholder="经销商"></el-input>
        </el-form-item>

        <el-form-item label="销售单号">
          <el-input v-model="queryParas[3].val" @keyup.enter.native="search"  placeholder="经销商"></el-input>
        </el-form-item>

        <el-form-item label="型号">
          <el-input v-model="queryParas[4].val" @keyup.enter.native="search"  placeholder="型号"></el-input>
        </el-form-item>

        <el-form-item>
          <div style="margin-bottom: 24px">
            <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
          </div>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <div style="margin-bottom: 24px">-->
<!--            <el-button type="primary"  icon="el-icon-download" :disabled="loading"  @click="handleExportXls('预订单')">导出</el-button>-->
<!--          </div>-->
<!--        </el-form-item>-->
      </el-form>
    </div>
    <div class="tbbox">
      <el-table class="tb"  v-loading="loading" :data="listdata" :height="'100%'" :border="true">
        <el-table-column  type="index" width="50" align="center" label="序号"/>
        <el-table-column header-align="center" :width="120" label="销售单号" prop="billnum" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="单据日期" prop="billdate" :formatter="el_table_datetime_format" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="200" label="客户" prop="cusname" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="150" label="产品名称" prop="mname"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="60" label="数量" prop="quanissue"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="非标" prop="dc6" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="310" label="产品备注" prop="dc1" show-overflow-tooltip></el-table-column>

        <el-table-column header-align="center" fixed="right" align="center" :width="100" label="操作">
          <div class="commanddiv" slot-scope="scope">
<!--            <span @click="viewdetail(scope.row)">订单详情</span>-->
            <span @click="showApplyGD(scope.row)">申请改单</span>
          </div>
        </el-table-column>

      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[50, 100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>

    <handle class="handle" :id="handleid" @success="handlesuccess" @close="handleclose" v-if="showhandle"></handle>
    <new-view v-if="dialog" @close="dialogclose" :title="title">
      <edit :data="carddata" @post="save" @cancel="dialogclose" ref="editbill"/>
    </new-view>

    <el-dialog title="设计文件" :visible.sync="dialog_fp" width="400px">
      <div>
        <div v-for="(file,findex) in files" class="file-item" @click="openfile(file, files, findex)">
          {{file.name}}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary"  @click="dialog_fp=false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog class="billdialog" :destroy-on-close="true" title="报价单" :fullscreen="true"
               :visible.sync="dialog_bj"
               :close-on-press-escape="false"
               width="400px">
      <bill :showmoney="true" ref="bjbill"/>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index" :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>
    <designform ref="designform"></designform>

    <baseFlowDialog ref="applyForm" :visible.sync="showApplyDialog" @close="baseFormClose" :params="formParams" :defaultValue="initValue">

    </baseFlowDialog>
  </div>
</template>

<script>
import {
  activityAdd,
  activityBodyAdd,
  activityBodyDelete,
  activityBodyEdit,
  activityBodyList,
  activityDelete,
  activityEdit,
  getallfeibiaodingzhi,
  addfeibiaodingzhi,
  cancelfeibiaodingzhi,
  getbuslog,
  feibiaodingzhi,
  find_getbyfind,
  getonefeibiaodingzh,
  gycommain
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, rowid, el_table_datetime_format, dateformat } from '../../util/common'


import { uploadUrl, mobileurl } from '../../config'
import QRCode from 'qrcodejs2'
import edit from '../feibiaodingzhi/edit'
import bill from '../quoteprice/bill'
import designform from '../design/designform'
import { mapGetters } from 'vuex'
import baseFlowDialog from '../../components/baseFlowDialog'

export default {
  components: {
    NewView,
    edit,
    bill,
    designform,
    baseFlowDialog
  },
  computed:{
    ...mapGetters(['userinfo'])
  },
  created () {
    find_getbyfind({ code: 'find_state', param: { a: 'dd' } }).then(res => {
      if (res.code == '200') {
        this.dict_state = res.data.data
      }
    })
  },
  data () {
    return {
      initValue:{},
      formParams:'',
      showApplyDialog: false,
      showOutTimeOnly: false,
      pagetotal: 0,
      dict_state: [],
      value: [],
      queryPage: {},
      queryParas: [
        { field: 'billdate', type: '0', rule: 'ge', val: null },
        { field: 'billdate', type: '0', rule: 'le', val: null },
        { field: 'cusname', type: '0', rule: 'like', val: null },
        { field: 'billnum', type: '0', rule: 'like', val: null },
        { field: 'mname', type: '0', rule: 'like', val: null },
      ],
      dialog_bj: false,
      showViewer: false,
      perviewImage: { showViewer: false, index: 0, urls: [] },
      files: [],
      dialog_fp: false,
      activities: [],
      el_table_datetime_format: el_table_datetime_format,
      handleid: '',
      showhandle: false,
      loading: false,
      searchdata: { key: '', curpage: 1, size: 50 },
      carddata: {},
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    baseFormClose(){
      this.showApplyDialog=false
    },
    showApplyGD (row) {
      this.formParams = {id: this.gD2WorkFlowKey, idType: "processDefKey"}
      this.initValue = row
      this.showApplyDialog = true
    },

    handleSizeChange (e) {
      this.searchdata.size = e
      this.load()
    },
    handleCurrentChange (e) {
      this.searchdata.curpage = e
      this.load()
    },
    handleExportXls (fileName) {
      if (this.value && this.value.length > 1) {
        this.queryParas[0].val = dateformat(this.value[0])
        this.queryParas[1].val = dateformat(this.value[1])
      } else {
        this.queryParas[0].val = null
        this.queryParas[1].val = null
      }

      const param = Object.assign({}, this.searchdata,
        { queryParams: encodeURI(JSON.stringify(this.queryParas)) })

      feibiaodingzhi.exportexcel(param).then(data => {
        if (!data || data.size === 0) {
          this.$message.warning('文件下载失败')
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data]), fileName + '.xls')
        } else {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '预订单管理.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(url) // 释放掉blob对象
        }
      })
    },
    handleCancel (row) {
      this.$confirm('此操作将取消订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          feibiaodingzhi.fcancel({ id: row.id }).then(r => {
            this.$message.success('取消成功')
            this.load()
          })
        })
    },
    openfile (file, files, index) {
      this.perviewImage.urls = files.map(im => { return this.$options.filters.getImgSrc(file.url) })
      this.perviewImage.index = index
      this.showViewer = true
    },
    showdesign (row) {
      this.$refs.designform.show(row.id, '1')
    },
    openbaojia (row) {
      this.dialog_bj = true
      this.$nextTick(() => {
        const bill = this.$refs.bjbill
        bill.disabled = true
        const load = this.$loading()
        feibiaodingzhi.getbaojiabill({ id: row.id }).then(r => {
          if (r.code == '200') {
            if (r.data.cdsmaster) {
              bill.cdsmaster = r.data.cdsmaster
              bill.cdsdeta00 = r.data.cdsdeta00
            }
          }
          load.close()
        }).catch(er => { load.close() })
      })
    },
    handlesuccess () {
      this.handleclose()
      this.load()
    },
    glog (e) {
      console.log(e)
      this.activities = []
      getbuslog({ rel_id: e.row.id }).then(r => {
        this.activities = r.data
      })
    },
    formatDate (row, column) {
      // 获取单元格数据
      const data = row[column.property]
      if (data == null) {
        return null
      }
      const dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    },
    uploadsj (e) {
      this.handleid = e.row.id
      this.showhandle = true
    },
    handleclose () {
      this.showhandle = false
    },
    search () {
      this.searchdata.curpage = 1
      this.load()
    },
    cancel (row) {
      this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
        cancelfeibiaodingzhi({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.load()
            this.$message.success('操作成功!')
          }
        })
      })
    },
    viewdetail (row) {
      // const d = Object.assign({}, row)
      // d.child = JSON.parse(d.child)
      // d.imgs = JSON.parse(d.imgs || '[]')
      // this.carddata = d
      // this.title = '详情'
      // this.dialog = true
      const that = this
      getonefeibiaodingzhi({ id: row.id }).then(r => {
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          this.carddata = d
          this.title = '详情'
          this.dialog = true
          this.$nextTick(r => {
            that.$refs.editbill.disabledall = true
          })
        }
      })
    },
    addoption () {
      this.imglist = []
      this.optionData = { activity_id: this.formData.id }
      this.optiontitle = '新增'
      this.showModal = true
    },
    dialogclose () {
      this.formData = {}
      this.bodydata = []
      this.dialog = false
    },
    billstateconvert (row, column) {
      const val = row[column.property]
      if (val == '-1') {
        return '订单取消'
      }
    },
    load () {
      this.loading = true
      if (this.value && this.value.length > 1) {
        this.queryParas[0].val = dateformat(this.value[0])
        this.queryParas[1].val = dateformat(this.value[1])
      } else {
        this.queryParas[0].val = null
        this.queryParas[1].val = null
      }

      let enQueryParas = encodeURI(JSON.stringify(this.queryParas))

      if (this.showOutTimeOnly)
        enQueryParas = encodeURI(JSON.stringify(this.queryParas.concat([{field: 'outtimecount', type: '15', rule: 'gt', val: 0}])))

      const param = Object.assign({}, this.searchdata, { queryParams: enQueryParas })
      gycommain.getdetail(param).then(res => {
        this.listdata = res.data.list
        this.pagetotal = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    save (e) {
      const d = Object.assign({}, e)
      d.child = JSON.stringify(d.child)
      d.imgs = JSON.stringify(d.imgs)
      addfeibiaodingzhi(d).then(res => {
        if (res.code == '200') {
          this.dialog = false
          this.load()
          this.$message.success('操作成功!')
        }
      })
    },
    addactivity () {
      this.title = '新增'
      this.carddata = null
      this.bodydata = []
      this.formData = { id: rowid() }
      this.dialog = true
    },
    handlePictureCardPreview () {

    },
    handleAvatarSuccess (response, file, filelist) {
      this.optionData.img = response.data.path
    },
    handleRemove (file, filelist) {
      if (filelist.length === 0) {
        this.optionData.img = ''
      }
    },
    handleRemove2 (file, filelist) {

    },
    closemodal () {
      this.showModal = false
    },
    loadbody (id) {
      this.bodydata = []
      activityBodyList({ id: id, search: '' }).then(res => {
        if (res.code === '200') {
          this.bodydata = res.data
        }
      })
    },
    editoption (row) {
      this.optionData = Object.assign({}, row)
      if (this.optionData.att) {
        this.optionData.attlist = JSON.parse(this.optionData.att)
      }
      if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
      this.optiontitle = '编辑'
      this.showModal = true
    },
    uperror (err) {
      console.log(err)
    },
    submitoption () {
      this.$refs.option.validate(vali => {
        if (vali) {
          if (this.optionData.attlist) {
            const att = []
            this.optionData.attlist.map(cur => {
              att.push({ name: cur.name, path: cur.path })
            })
            this.optionData.att = JSON.stringify(att)
          }
          console.log(this.optionData)
          if (this.optiontitle === '新增') {
            activityBodyAdd(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          } else if (this.optiontitle === '编辑') {
            activityBodyEdit(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          }
        }
      })
    },
    deleteactivity (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.load()
          }
        })
      })
    },
    deleteoption (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityBodyDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.loadbody(row.activity_id)
          }
        })
      })
    },
    submitactivity () {
      this.$refs.mainform.validate(vali => {
        if (vali) {
          if (this.title === '新增') {
            activityAdd(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          } else if (this.title === '编辑') {
            activityEdit(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          }
        } else {
          console.log('fail')
        }
      })
    },
    crateQrcode (text) {
      this.qr = new QRCode('qrcode', {
        width: 128,
        height: 128, // 高度
        text: text // 二维码内容
      })
    },
    genqrcode (row) {
      console.log(mobileurl)
      this.qrcodedialog = true
      this.$nextTick(() => {
        this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
      })
    },
    qrcodedialogclose () {
      this.$refs.qrcode.innerHTML = ''
    },
    optiondialogclose () {
      this.optionData = {}
      this.imglist = []
      this.fileList = []
    },
    // 预览图片
    handlePreview () {

    },
    filelistchange (file, filelist) {
      console.log(filelist.slice(-3))
    },
    uploadSuccess (req, file, filelist) {
      if (req.code === '200') {
        file.url = req.data.path
      }
    },
    handleChange (file, filelist) {
      this.fileList = filelist.slice(-3)
    }
  },
  mounted () {
    this.load()
  }

}
</script>

<style scoped lang="scss" type="text/scss">
.buttondiv{
  margin: 10px 0;
  text-align: right;
}
.commanddiv{
  color: $color-primary;
  span:hover{
    cursor: pointer;
  }
}
.qrcodedialog ::v-deep .el-dialog__body{
  padding: 0px;
}

.container{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tbbox{
  display: flex;
  flex: 1;
  position: relative;
}
.tb{
  height: 100% !important;
  position: absolute;
}

.handle{
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10;
}

.file-item{
  cursor: pointer;
  line-height: 30px;
}
.file-item:hover{
  background-color: #eeeeee;
}

.billdialog ::v-deep .el-dialog.is-fullscreen{
  display: flex !important;
  flex-direction: column !important;
}

.billdialog ::v-deep .el-dialog__body{
  flex: 1 !important;
}
.el-form-item {
  margin-bottom: 18px;
}

@import "../table";
</style>
