<template>
  <div class="container">
    <div class="top-bar">
      <el-button @click="handleAddMenu" type="primary">新增</el-button>
    </div>
    <el-table :data="menu_data" row-key="id" :height="tableHeight" border>
      <el-table-column show-overflow-tooltip v-for="(menu,index) in menuColumn" :label="menu.text" :prop="menu.field"
                       :key="index">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <label class="row-edit" @click="rowEdit(scope.row)">编辑</label>
          <el-divider direction="vertical"></el-divider>
          <el-dropdown @command="rowAction">
            <span class="row-more">更多
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{type:'add', row: scope.row}">添加下级</el-dropdown-item>
              <el-dropdown-item :command="{type:'del', row: scope.row}">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer :withHeader="false" :visible.sync="isDrawerOpen" :title="drawerTitle" size="692px">
      <el-scrollbar class="drawer-scrollbar" :style="{height: drawerHeight + 'px'}">
        <div class="drawer-title">
          <label>{{drawerTitle}}</label>
          <i class="el-icon-close" @click="formClose"/>
        </div>
        <el-form class="form" :model="menuForm" ref="formRules" label-position="right" label-width="128px"
                 :rules="formRules">
          <el-form-item label="菜单类型：">
            <el-radio v-model="menuForm.menu_type" :label=0>一级菜单</el-radio>
            <el-radio v-model="menuForm.menu_type" :label=1>子菜单</el-radio>
            <el-radio v-model="menuForm.menu_type" :label=2>按钮/权限</el-radio>
          </el-form-item>
          <el-form-item label="菜单名称：" prop="name">
            <el-input v-model="menuForm.name"/>
          </el-form-item>
          <el-form-item label="菜单路径：" prop="url">
            <el-input v-model="menuForm.url"/>
          </el-form-item>
          <el-form-item label="前端组件：" prop="component">
            <el-input v-model="menuForm.component"/>
          </el-form-item>
          <el-form-item label="重定向：">
            <el-input v-model="menuForm.redirect"/>
          </el-form-item>
          <el-form-item label="菜单图标：">
            <el-input v-model="menuForm.icon"/>
          </el-form-item>
          <el-form-item label="排序：">
            <el-input v-model="menuForm.sort"/>
          </el-form-item>
          <el-form-item label="打开方式：">
            <el-radio v-model="menuForm.internal_or_external" :label=0>内部</el-radio>
            <el-radio v-model="menuForm.internal_or_external" :label=1>外部</el-radio>
          </el-form-item>
          <el-form-item class="form-button">
            <el-button @click="formClose">关闭</el-button>
            <el-button type="primary" @click="formConfirm">确认</el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </el-drawer>
  </div>
</template>

<script>
import { functionAdd, functionDelete, functionEdit, functionTreeList } from '../../api/common'

export default {
  name: '',
  data () {
    return {
      menu_data: [],
      menuColumn: [
        {
          field: 'name',
          text: '菜单名称'
        },
        {
          field: 'menu_type',
          text: '菜单类型'
        },
        {
          field: 'icon',
          text: '图标'
        },
        {
          field: 'component',
          text: '组件'
        },
        {
          field: 'url',
          text: '路径'
        },
        {
          field: 'sort',
          text: '排序'
        }],
      isDrawerOpen: false,
      drawerTitle: '',
      menuForm: {},
      drawerHeight: document.documentElement.clientHeight,
      formRules: {
        name: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请输入菜单路径',
          trigger: 'blur'
        }],
        component: [{
          required: true,
          message: '请输入前端组件',
          trigger: 'blur'
        }]
      },
      tableHeight: document.documentElement.clientHeight - 142
    }
  },
  methods: {
    getMenuData () {
      functionTreeList().then((res) => {
        if (res.code === '200') {
          this.menu_data = res.data
        }
      })
    },
    handleAddMenu () {
      this.drawerTitle = '新增'
      this.menuForm = {}
      this.menuForm.menu_type = 0
      this.menuForm.sort = 0
      this.menuForm.internal_or_external = 0
      this.isDrawerOpen = true
    },
    formConfirm () {
      this.$refs.formRules.validate((valid) => {
        if (valid) {
          if (this.drawerTitle === '新增') {
            functionAdd(this.menuForm).then((res) => {
              if (res.code === '200') {
                this.$message.success(res.message)
                this.getMenuData()
                this.isDrawerOpen = false
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            functionEdit(this.menuForm).then((res) => {
              if (res.code === '200') {
                this.$message.success(res.message)
                this.getMenuData()
                this.isDrawerOpen = false
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    formClose () {
      this.isDrawerOpen = false
    },
    rowEdit (row) {
      this.drawerTitle = '编辑'
      this.menuForm = Object.assign({}, row)
      this.isDrawerOpen = true
    },
    rowAction ({ type, row }) {
      if (type === 'del') {
        this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
          functionDelete({ id: row.id }).then((res) => {
            if (res.code === '200') {
              this.$message.success(res.message)
              this.getMenuData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      }
      if (type === 'add') {
        this.drawerTitle = '新增'
        this.menuForm = {}
        this.menuForm.parent_id = row.id
        this.menuForm.menu_type = 1
        this.menuForm.internal_or_external = 0
        this.isDrawerOpen = true
      }
    }
  },
  mounted () {
    // var self = this
    // /** 控制table的高度 */
    // window.onresize = function () {
    //   self.tableHeight = document.documentElement.clientHeight - 132
    // }
    this.getMenuData()
  }
}
</script>

<style lang="less" scoped type="text/less">
  .container {
    background-color: white;
  }

  /deep/ :focus {
    outline: 0;
  }

  .drawer-scrollbar /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .form {
    margin: 24px;
    border: 1px solid #E9E9E9;
    padding: 24px 24px 0px 24px;

    .el-input {
      width: 406px;
    }

    .form-button {
      text-align: right;
    }
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
    padding: 24px 24px;
    font-size: 16px;

    i {
      cursor: pointer;
    }

    i:hover {
      color: red;
    }
  }

  .row-edit, .row-more {
    color: #2362FB;
  }

  .row-edit:hover, .row-more:hover {
    cursor: pointer;
  }

  .top-bar .el-button {
    margin: 5px 10px;
    width: 80px;
  }

  @import "../table.scss";
</style>
