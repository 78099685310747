<template>
  <div>
    <div class="box">
<!--      <fieldset class="fieldset" >-->
<!--        <el-form size="mini" label-width="200px" :model="formData" ref="formData">-->
<!--          <el-form-item label="产品型号：" prop="mname" >-->
<!--            <span>{{formData.mname}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="订单号：" prop="erp_billnum">-->
<!--            <span>{{formData.erp_billnum}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="做工信息：">-->
<!--            <span>{{formData.dc1}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="颜色：">-->
<!--            <span>{{formData.fc1}}</span>-->
<!--          </el-form-item>-->
<!--          &lt;!&ndash;          <el-form-item label="尺寸：" prop="model" :rules="[{ required: true, message: '尺寸不能为空', trigger: 'blur'}]">&ndash;&gt;-->
<!--          &lt;!&ndash;            <span>{{formData.model}}</span>&ndash;&gt;-->
<!--          &lt;!&ndash;          </el-form-item>&ndash;&gt;-->
<!--          &lt;!&ndash;          <el-form-item label="材质：">&ndash;&gt;-->
<!--          &lt;!&ndash;            <span>{{formData.fc3}}</span>&ndash;&gt;-->
<!--          &lt;!&ndash;          </el-form-item>&ndash;&gt;-->
<!--          <el-form-item label="数量：" prop="quan" >-->
<!--            <span>{{formData.quan}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="问题描述：" prop="issue">-->
<!--            <span>{{formData.issue}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="要求说明：">-->
<!--            <span>{{formData.require}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="需工厂如何协助处理说明：" prop="dispose">-->
<!--            <span>{{formData.dispose}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="图片：">-->
<!--            <v-upload :host="$options.filters.getImgSrc('')"-->
<!--                      :action="upfile.action"-->
<!--                      :headers="upfile.headers"-->
<!--                      :data="upfile.data"-->
<!--                      accept=".jpg,.jpeg,.png,.gif,.bmp"-->
<!--                      :file-list="tmpimgs"-->
<!--                      ref="upload"-->
<!--                      :disabled="true"-->
<!--                      :on-preview="handlePreview"-->
<!--            >-->
<!--            </v-upload>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </fieldset>-->
<!--      <el-image-viewer v-if="showViewer" :zIndex="9999"-->
<!--                       :on-close="()=>{showViewer=false}"-->
<!--                       :initialIndex="perviewImage.index"-->
<!--                       :url-list="perviewImage.urls"></el-image-viewer>-->

      <fieldset class="fieldset" :disabled="iseditable">
        <el-form size="mini" label-width="200px" :model="formData" ref="formData">
          <el-form-item label="产品型号：" prop="mname" :rules="[{ required: true, message: '产品型号不能为空', trigger: 'blur'}]">
            <el-input style="max-width: 200px" placeholder="产品型号" v-model="formData.mname"/>
          </el-form-item>
          <el-form-item label="订单号：" prop="erp_billnum" :rules="[{ required: true, message: '订单号不能为空', trigger: 'blur'}]">
            <el-input style="max-width: 200px" placeholder="订单号" v-model="formData.erp_billnum"/>
          </el-form-item>
          <!--        <el-form-item label="尺寸：" prop="model" :rules="[{ required: true, message: '尺寸不能为空', trigger: 'blur'}]">-->
          <!--          <el-input style="max-width: 200px" placeholder="尺寸" v-model="formData.model"/>-->
          <!--        </el-form-item>-->
          <!--        <el-form-item label="材质：">-->
          <!--          <el-input style="max-width: 200px" placeholder="材质" v-model="formData.fc3"/>-->
          <!--        </el-form-item>-->
          <el-form-item label="做工信息：">
            <el-input class="input" type="textarea"  placeholder="做工信息" v-model="formData.dc1"/>
          </el-form-item>
          <el-form-item label="颜色：">
            <el-input style="max-width: 200px" placeholder="颜色" v-model="formData.fc1"/>
          </el-form-item>
          <el-form-item label="数量：" prop="quan" :rules="[{ required: true, message: '数量不能为空', trigger: 'blur'}]">
            <el-input type="number" style="max-width: 100px" placeholder="数量" v-model="formData.quan"/>
          </el-form-item>
          <el-form-item label="问题描述：" prop="issue" :rules="[{ required: true, message: '问题描述不能为空', trigger: 'blur'}]">
            <el-input class="input" placeholder="问题描述" :rows="2" type="textarea" v-model="formData.issue"/>
          </el-form-item>
          <el-form-item label="要求说明：">
            <el-input class="input" placeholder="要求说明" :rows="2" type="textarea" v-model="formData.require"/>
          </el-form-item>
          <el-form-item label="需工厂如何协助处理说明：" prop="dispose" :rules="[{ required: true, message: '需工厂如何协助处理说明不能为空'}]">
            <el-input class="input" placeholder="需工厂如何协助处理说明" :rows="2" type="textarea" v-model="formData.dispose"/>
          </el-form-item>
          <el-form-item label="上传图片：">
            <v-upload :host="$options.filters.getImgSrc('')"
                      :action="upfile.action"
                      :headers="upfile.headers"
                      :data="upfile.data"
                      accept=".jpg,.jpeg,.png,.gif,.bmp"
                      :file-list="tmpimgs"
                      ref="upload"
                      :disabled="iseditable"
                      :on-preview="handlePreview"
            >
            </v-upload>
          </el-form-item>
        </el-form>
      </fieldset>

      <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index"
                       :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>


    </div>
    <br>
    <div class="box">
      <fieldset class="fieldset" :disabled="iseditable">
        <el-form label-width="200px" size="mini" :model="formData2">
          <el-form-item label="经销商名称：">
            <el-col :span="10">
              <span>{{formData.cusname}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="责任判定：">
            <el-col :span="10">
              <el-select v-model="formData2.def" clearable filterable placeholder="请选择" :disabled="iseditable">
                <el-option value="公司" />
                <el-option value="非公司" />
                <el-option value="其它" />
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="责任人：">
            <el-col :span="10">
              <el-input v-model="formData2.dc1"/>
            </el-col>
          </el-form-item>

          <el-form-item label="售后类型：">
            <el-col :span="10">
              <el-select v-model="formData2.type" @change="handleTypeChange(formData2.type)" clearable filterable placeholder="请选择" :disabled="iseditable">
                <el-option v-for="(item,index) in dict_type" :value="item.fname" :key="index" />
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="工厂处理方案：">
            <el-col :span="10">
              <el-input type="textarea" v-model="formData2.project"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="客户涉及费用：">
            <el-col :span="10">
              <el-form inline :model="formData2" size="mini">
                <el-form-item label="成本费">
                  <el-input type="number" v-model="formData2.dn1"/>
                </el-form-item>
                <el-form-item label="客户需承担金额">
                  <el-input type="number" v-model="formData2.dn2"/>
                </el-form-item>
                <el-form-item label="生产承担费用">
                  <el-input type="number" v-model="formData2.dn3"/>
                </el-form-item>
                <el-form-item label="销售承担费用">
                  <el-input type="number" v-model="formData2.dn6"/>
                </el-form-item>
                <el-form-item label="工厂费用">
                  <el-input type="number" v-model="formData2.dn7"/>
                </el-form-item>
                <el-form-item label="采购费用">
                  <el-input type="number" v-model="formData2.dn8"/>
                </el-form-item>
                <el-form-item label="返厂运费">
                  <el-input type="number" v-model="formData2.dn4"/>
                </el-form-item>
                <el-form-item label="财务最后审批费用">
                  <el-input type="number" v-model="formData2.dn5"/>
                </el-form-item>
              </el-form>
            </el-col>
          </el-form-item>
          <el-form-item label="最终结果：">
            <el-col :span="10">
              <el-select v-model="formData2.result" clearable filterable placeholder="请选择" :disabled="iseditable">
                <!--                <el-option value="更换配件" />-->
                <!--                <el-option value="维修（补助费用）" />-->
                <!--                <el-option value="退货冲款" />-->
                <!--                <el-option value="返厂返修" />-->
                <!--                <el-option value="其它" />-->
                <!--                </el-option>-->
                <el-option value="公司重新生产" />
                <el-option value="客户自费返厂维修" />
                <el-option value="自费返厂维修" />
                <el-option value="补当地处理费用" />
                <el-option value="客户自费重做" />
                <el-option value="优惠价重做" />
                <el-option value="补发配件" />
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="改进后图片：">
            <v-upload :host="$options.filters.getImgSrc('')"
                      :action="upfile.action"
                      :headers="upfile.headers"
                      :data="upfile.data"
                      accept=".jpg,.jpeg,.png,.gif,.bmp"
                      :file-list="arrImg1"
                      ref="upload1"
                      :disabled="iseditable"
                      :on-preview="handlePreview1"
            >
            </v-upload>
          </el-form-item>
          <el-form-item label="责任单位：">
            <el-col :span="10">
              <el-input v-model="formData2.dc2"/>
            </el-col>
          </el-form-item>
          <el-form-item label="最终责任人：">
            <el-col :span="10">
              <el-input v-model="formData2.dc3"/>
            </el-col>
          </el-form-item>
          <el-form-item label="品质部处理意见：">
            <el-col :span="10">
              <el-input v-model="formData2.dc4"/>
            </el-col>
          </el-form-item>
          <el-form-item label="生产改善措施：">
            <el-col :span="10">
              <el-input v-model="formData2.dc5"/>
            </el-col>
          </el-form-item>
          <el-form-item label="处理结果：">
            <el-col :span="10">
              <el-input v-model="formData2.dc6"/>
            </el-col>
          </el-form-item>
        </el-form>
      </fieldset>
    </div>
    <br>

  </div>
</template>

<script>
  import vUpload from '../../components/v-upload'
  import { uploadUrl } from '../../config'
  import { getToken, rowid } from '../../util/common'
  import { postsaleshead, process,find_getbyfind, afterprocessing } from '../../api/common'
  import { mapGetters } from 'vuex'

  export default {
    props: ['processData'],

    created(){
      find_getbyfind({ code: 'findAfterSaleType', param: { a: 'dd' } }).then(res => {
        if (res.code == '200') {
          this.dict_type = res.data.data
        }
      })
    },
    mounted () {
      postsaleshead.getbill({ sguid: this.processData.formId }).then(r => {
        if (r.code == '200') {
          this.formData = r.data
          this.tmpimgs = JSON.parse(r.data.imgs)
        }
      })
      afterprocessing.getInstance({sguid: this.processData.formId}).then(r=>{
        if (r.code=='200'){
          this.formData2 = r.data || {}
          this.arrImg1 = JSON.parse(this.formData2.img1||"[]")
        }
      })
    },
    components: {
      vUpload
    },
    computed: {
      ...mapGetters(['userinfo']),
      getImgs(){
        return this.tmpimgs.map((item)=>{
          item.url = this.$options.filters.getImgSrc(item.url);
          return item
        })
      },
      getImg1(){
        return this.arrImg1.map((item)=>{
          item.url = this.$options.filters.getImgSrc(item.url);
          return item
        })
      },
      iseditable () {
        if (this.processData) {
          if (this.processData.editableList && this.processData.editableList.length > 0) {
            return false
          } else {
            if (this.processData.taskDefId == "Activity_10t822q"||this.processData.taskDefId == "Activity_1i4wtmc")
              return false
            return true
          }
        } else {
          return true
        }
      }
    },
    data () {
      return {
        inited: false,
        isChange: true,
        perviewImage:[],
        dict_type:[],
        formData2:{},
        showViewer: false,
        upfile: {
          action: uploadUrl,
          headers: { Authorization: 'Bearer ' + getToken() },
          data: { bizPath: 'vote' }
        },
        formData: {
          sguid: null,
          mname: '',
          billnum: '',
          fc3: '',
          model: '',
          quan: 1,
          issue: '',
          require: '',
          dispose: '',
          imgs: [],
          cuscode: null,
          cusname: null,
          billdate: null
        },
        tmpimgs: [],
        arrImg1:[]

      }
    },
    methods: {

      handleTypeChange(val){
        let elm = this.dict_type.find(x=>x.fname == val)
        this.$set(this.formData2, 'type2', elm.memo)
      },
      getVariables(){
        return Object.assign({type2:''}, this.formData2, {sguid: this.formData.sguid})
      },
      handleError () {

      },
      handleSuccess (response, file, fileList) {

      },

      saveformA () {
        let _this = this
        const upload = this.$refs.upload
        const imgs = []
        upload.uploadFiles.forEach(value => {
          if (value.response) {
            imgs.push({ url: value.response.data.path })
          } else {
            imgs.push({ url: value.url })
          }
        })
        if (imgs.length == 0){
          _this.$message.warning('请上传图片')
          return true
        }
        this.formData.imgs = JSON.stringify(imgs)
        //保存
        postsaleshead.savebill(this.formData).then(res => {
          if (res.code == '200') {

          }
        })
      },

      save () {
        this.saveformA()
        let _this = this
        if (Object.keys(this.formData2).length == 0){
          _this.$message.warning('请填写内容后再保存')
          return
        }

        const upload = this.$refs.upload1
        const imgs = []
        upload.uploadFiles.forEach(value => {
          if (value.response) {
            imgs.push({ url: value.response.data.path })
          } else {
            imgs.push({ url: value.url })
          }
        })
        // if (imgs.length == 0){
        //   _this.$message.warning('请上传图片')
        //   return
        // }

        this.formData2.img1 = JSON.stringify(imgs)
        let recode = Object.assign({}, this.formData2, {sguid: this.formData.sguid})
        afterprocessing.save(recode).then(r=>{
          if (r.code=='200'){
            this.$parent.setButton('audit', false)
            _this.$message.success('操作成功')
          }
        })
      },
      handleRemove (file, fileList) {
        console.log(file, fileList)
      },
      handlePreview(file){
        const upload = this.$refs.upload
        const i = upload.uploadFiles.findIndex(item => item.uid === file.uid)
        const imgs = []
        upload.uploadFiles.forEach(value => {
          if (value.response) {
            imgs.push({ url: value.response.data.path })
          } else {
            imgs.push({ url: value.url })
          }
        })
        this.previewimage(this.tmpimgs,i)
      },
      handlePreview1(file){
        const upload = this.$refs.upload1
        const i = upload.uploadFiles.findIndex(item => item.uid === file.uid)
        const imgs = []
        upload.uploadFiles.forEach(value => {
          if (value.response) {
            imgs.push({ url: value.response.data.path })
          } else {
            imgs.push({ url: value.url })
          }
        })
        this.previewimage(this.arrImg1,i)
      },
      previewimage (imgs, index) {
        this.perviewImage.urls = imgs.map(im => {
          return this.$options.filters.getImgSrc(im.url)
        })
        this.perviewImage.index = index
        this.showViewer = true
      }
    },
    watch: {
      formData2: {
        handler (nval, oval) {
          if (this.inited)
            this.$parent.setButton('audit', true)
          this.inited = true
        },
        deep: true
      }
    }
  }
</script>

<style scoped>

  div {
    line-height: 20px;
  }


  .input {
    max-width: 600px;
  }

  .box {
    /*border: 1px solid #ccc;*/
    margin: 0px 20px;
    padding-top: 35px;
    padding-bottom: 50px;
    padding-right: 100px;
    border: 1px solid rgb(232, 232, 232);
    background-color: white;
  }

  .post_button {
    border: none;
    height: 32px;
    line-height: 32px;
    color: #fff;
    background: #dd2727;
    font-size: 16px;
    min-width: 200px;
    border-radius: 4px;
    padding: 0 10px;
    margin-left: 200px;
  }

  /deep/ .el-upload--picture-card {
    height: 88px;
    width: 88px;
    line-height: 88px;
  }

  /deep/ .el-upload-list__item {
    height: 88px;
    width: 88px;
    transition: none !important;
  }

  .fieldset {
    border: 0px;
  }

  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/ input[type='number'] {
    -moz-appearance: textfield !important;
  }

</style>
