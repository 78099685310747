'use strict'

var escapeHTML = require('../Utils').escapeHTML

var entryFieldDescription = require('./EntryFieldDescription')

var textBox = function (options, defaultParameters) {
  var resource = defaultParameters
  var label = options.label || resource.id
  var canBeShown = !!options.show && typeof options.show === 'function'
  var description = options.description

  resource.html =
    '<label for="activiti-' + escapeHTML(resource.id) + '" ' +
    (canBeShown ? 'data-show="isShown"' : '') +
    '>' + label + '</label>' +
    '<div class="bpp-field-wrapper" ' +
    (canBeShown ? 'data-show="isShown"' : '') +
    '>' +
      '<div contenteditable="true" id="activiti-' + escapeHTML(resource.id) + '" ' +
            'name="' + escapeHTML(options.modelProperty) + '" />' +
    '</div>'

  // add description below text box entry field
  if (description) {
    resource.html += entryFieldDescription(description)
  }

  if (canBeShown) {
    resource.isShown = function () {
      return options.show.apply(resource, arguments)
    }
  }

  resource.cssClasses = ['bpp-textbox']

  return resource
}

module.exports = textBox
