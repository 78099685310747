<template>
  <div class="system-content">
    <!-- 左边导航栏 -->
    <div class="system-view-nav">
      <el-tree ref="tree" :data="treeData" :expand-on-click-node="false" node-key="id" default-expand-all
               highlight-current>
      <span slot-scope="{ node, data }" class="node-data">
        <i class="iconfont icon11111 node-img" v-if="node.expanded" @click="handleExpand('close',node, data)"/>
        <i class="iconfont icon111111111 node-img" v-if="!node.expanded" @click="handleExpand('open',node, data)"/>
        <div class="node-label">{{ data.name }}</div>
        <div class="node-label-set">
          <el-button type="text" size="mini" @click.stop="() => append(data)">
            <i class="el-icon-plus"/>
          </el-button>
          <el-button v-if="node.label != '全部'" type="text" size="mini" @click.stop="() => edit(node, data)">
            <i class="el-icon-edit"/>
          </el-button>
          <el-button v-if="node.label != '全部'" type="text" size="mini" @click.stop="() => remove(node, data)">
            <i class="el-icon-close"/>
          </el-button>
        </div>
      </span>
      </el-tree>
    </div>
    <div class="system-view-table">
<!--      筛选面板-->
      <div v-if="selectionList.length == 0" class="table-top">
        <el-input class="icon-search" placeholder="请输入用户名称" v-model="searchdata.realname" >
          <i slot="suffix" @click="getUserList" class="el-input__icon el-icon-search"></i>
        </el-input>
<!--        <div class="status">-->
<!--          <span>状态</span>-->
<!--          <el-select placeholder="请选择" v-model="selectModel">-->
<!--            <el-option value="禁用"></el-option>-->
<!--            <el-option value="启用"></el-option>-->
<!--          </el-select>-->
<!--        </div>-->
        <el-button class="el-bottom" type="primary" @click="newBtn">新增用户</el-button>

      </div>

<!--      工具面板-->
      <div v-if="selectionList.length > 0" class="selection-bar">
        <div class="selected—title">已选中<span class="selected—count">{{ selectionList.length }}</span>项</div>
        <div class="selection-items-box">
          <div v-for="(item, index) in selectionInfo" :key="index"
               class="selection-item"
               @click="selectionBarClick(item.type)">
            <img :src="item.icon" class="selection-item-icon" >
            <div class="selection-item-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
     <div style="margin-bottom: 10px;margin-left: 10px">
        <el-button class="el-bottom" type="primary" @click="importErpCus">导入ERP客户</el-button>
<!--        <el-button class="el-bottom" type="primary" @click="importErpUser">导入ERP用户</el-button>-->
      </div>
      <div class="table-box">
        <el-table id="depTable" border  :data="tableData" :height="'100%'"
                  @row-click="rowClick" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"/>
          <el-table-column prop="realname" width="180" show-overflow-tooltip label="姓名">
            <template slot-scope="scope">
              <div class="status-name">
                <div :style="{'background-color' : getStatusColor(scope.row.status)}"/>
                {{ scope.row.realname }}
              </div>
            </template>
            <template slot="header" slot-scope="scope">
              <div class="table-head-name">{{ scope.column.label }}</div>
            </template>
          </el-table-column>
          <el-table-column v-for="(item, index) in fieldList" :key="index" :width="item.width" :prop="item.field" :label="item.value" :formatter="tableFormatter" show-overflow-tooltip>
            <template slot="header" slot-scope="scope">
              <div class="table-head-name">{{ scope.column.label }}</div>
            </template>
          </el-table-column>
          <el-table-column/>
        </el-table>
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentpage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size= "page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.pagetotal">
        </el-pagination>
      </div>
    </div>
    <!-- 导航新增编辑弹出框 -->
    <el-dialog :visible.sync="depCreateDialog" :title="navBtnTitle" :before-close="navHandleClose" width="30%">
      <div class="nav-dialog-div">
        <label>{{ labelName }}：</label>
        <el-input v-model="treeInput" placeholder="请输入内容"/>
      </div>
      <div v-if="depSelect != 0 && dialogOptions.length > 0" class="nav-dialog-div">
        <label>上级部门：</label>
        <el-select v-model="depSelect" :clearable="false" placeholder="请选择">
          <el-option v-for="item in dialogOptions" :key="item.dept_id" :label="item.name" :value="item.dept_id"/>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="depCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新建和编辑 -->
    <el-dialog v-if="employeeCreateDialog" :title="dialogTitle" :visible.sync="employeeCreateDialog"
               :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true"
               :before-close="newHandleClose" width="60%">
      <p class="new-dialog-title">基本信息</p>
      <el-form ref="dialogRef" :inline="true" :model="formInline" :rules="dialogRules" class="new-dialog-form"
               label-width="80px" label-position="top">
        <el-form-item size="mini" v-for="(item, index) in tableList" :label="item.value" :prop="item.field" :key="index">
          <span slot="label">{{ item.value }}</span>
          <el-tooltip v-if="item.tips" slot="label" :content="item.tips" effect="dark" placement="top">
            <i class="wukong wukong-help_tips"/>
          </el-tooltip>
          <template v-if="item.type == 'select'">
            <el-select v-model="formInline[item.field]" filterable placeholder="请选择" >
              <el-option v-for="optionItem in optionsList[item.field].list" :key="optionItem.id"
                         :label="optionItem.name" :value="optionItem.id"/>
            </el-select>
          </template>
          <template v-else-if="item.type == 'selectCheckout'">
            <el-select v-model="formInline[item.field]" :popper-append-to-body="false"
                       popper-class="select-popper-class" filterable multiple placeholder="请选择">
<!--              <el-option-group v-for="group in groupsList" :key="group.pid" :label="group.name">-->
                <el-option v-for="item in groupsList" :key="item.role_id" :label="item.role_name" :value="item.role_id"/>
<!--              </el-option-group>-->
            </el-select>
          </template>
          <el-input v-else v-model="formInline[item.field]" :disabled="dialogTitle == '编辑用户' && item.field == 'username'"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"  @click="newDialogSubmit">保 存</el-button>
        <el-button @click="employeeCreateDialog = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 重置密码 -->
    <el-dialog :visible.sync="resetPasswordVisible" :close-on-click-modal="false"
      :modal-append-to-body="false" :before-close="resetPasswordClose"
      title="重置密码" width="30%">
      <div class="el-password">
        <el-form ref="passForm" :model="passForm" :rules="rules">
          <el-form-item label="密码" prop="password">
            <el-input v-model="passForm.password" type="password"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPasswordClose">取 消</el-button>
        <el-button type="primary" @click="passSubmit(passForm)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 重置高级密码 -->
    <el-dialog :visible.sync="resetAdvPasswordVisible" :close-on-click-modal="false"
               :modal-append-to-body="false" :before-close="resetAdvPasswordClose"
               destroy-on-close
               title="重置高级密码" width="30%">
      <div class="el-password">
        <el-form ref="advPassForm" :model="advPassForm">
          <el-form-item label="密码" prop="password">
            <el-input v-model="advPassForm.password" type="password"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetAdvPasswordClose">取 消</el-button>
        <el-button type="primary" @click="advPassSubmit(advPassForm)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 重置登录账号 -->
    <el-dialog :visible.sync="resetUserNameVisible" :close-on-click-modal="false"
               :modal-append-to-body="false" :before-close="()=>{resetUserNameVisible = false}"
               title="重置登录账号"  width="30%">
      <div class="el-password">
        <el-form ref="resetUserNameForm" :model="resetUserNameForm" :rules="dialogRules">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="resetUserNameForm.username"/>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input v-model="resetUserNameForm.password" type="password"/>
          </el-form-item>
        </el-form>
        <div class="tips" style="margin-top: 20px;">重置登录帐号后，用户需用新账号登录。请及时告知用户，确保正常使用</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="()=>{resetUserNameVisible = false}">取 消</el-button>
        <el-button type="primary" @click="passUserNameSubmit(resetUserNameForm)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { rowid, uuid } from '../../util/common'
import {
  depList, depTree, depDelete, depAdd, depEdit,
  CanSetdepList, roleList, userAdd, userList,
  usersEditStatus, updateUser, geterpuser, seterpcusdefpass, changePassword, reSetAdvAuth
} from '../../api/common'

var validateUsername = (rule, value, callback) => {
  if (value && value === 'admin') {
    callback()
  } else if (value && /^1\d{10}$/.test(value)) {
    callback()
  } else {
    callback(new Error('目前只支持中国大陆的手机号码'))
  }
}
export default {
  data () {
    return {
      page: {
        pagetotal: 0,
        currentpage: 1,
        size: 100
      },
      searchdata: { realname: '' },
      employeeCreateDialog: false,
      dialogTitle: '',
      formInline: {},
      // fdfsf
      treeEditId: '',
      depLoading: false,
      depCreateDialog: false,
      navBtnTitle: '',
      labelName: '',
      treeInput: '',
      depSelect: '',
      dialogOptions: [],
      treeData: [],
      selectModel: '',
      dialogRules: {
        realname: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }
        ],
        usertype: [
          {
            required: true,
            message: '用户类型不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 12,
            message: '长度在 3 到 12 个字符',
            trigger: 'blur'
          }
        ],
        username: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }
        ],
        // mobile: [
        //   {
        //     required: true,
        //     message: '手机号码不能为空',
        //     trigger: 'blur'
        //   },
        //   {
        //     validator: validateUsername,
        //     trigger: 'blur'
        //   }
        // ],
        email: [
          {
            pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
            message: '请输入正确的邮箱格式',
            trigger: 'blur'
          }
        ],
        deptId: [
          {
            required: true,
            message: '部门不能为空',
            trigger: 'change'
          }
        ],
        roleId: [{
          required: true,
          message: '角色不能为空',
          trigger: 'change'
        }]
      },
      optionsList: {
        dept_id: {
          field: 'dept_id',
          list: []
        },
        parent_id: {
          field: 'parent_id',
          list: [{
            id: 0,
            name: '请选择'
          }]
        },
        usertype: {
          field: 'usertype',
          list: [
            {
              id: 0,
              name: '内部员工'
            }, {
              id: 1,
              name: '经销商'
            }
          ]
        },
        sex: {
          field: 'sex',
          list: [
            {
              id: 0,
              name: '请选择'
            },
            {
              id: 1,
              name: '男'
            },
            {
              id: 2,
              name: '女'
            }
          ]
        }
      },
      groupsList: [],
      /** 列表 */
      fieldList: [
        { field: 'username', value: '用户名', width: '150' },
        { field: 'usertype', value: '用户类型', type: 'select', width: '150' },
        { field: 'sex', value: '性别', type: 'select', width: '150' },
        { field: 'email', value: '邮箱', width: '150' },
        { field: 'dept_name', value: '部门', type: 'select', width: '150' },
        { field: 'post', value: '岗位', width: '150' },
        {
          field: 'parent_name',
          value: '直属上级',
          type: 'select',
          width: '150'
        },
        {
          field: 'role_name',
          value: '角色',
          type: 'selectCheckout',
          width: '150'
        }
      ],
      tableData: [],
      tableHeight: document.documentElement.clientHeight - 155, // 表的高度
      selectionList: [],
      resetPasswordVisible: false,
      resetUserNameVisible: false,
      resetAdvPasswordVisible: false,
      passForm: {},
      advPassForm:{password:''},
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '用户名不能为空' }
        ]
        // mobile: [
        //   { required: true, message: '手机号不能为空', trigger: 'blur' }
        // ]
      },
      resetUserNameForm: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {
    this.treeListFun()
    this.getUserList()
    // 部门列表
    this.getDepList()
    // 加载角色列表
    roleList().then(res => {
      this.groupsList = res.data
    })
  },
  methods: {
    advPassSubmit(form){
      reSetAdvAuth(Object.assign(this.advPassForm, {'user_id':this.dialogData.user_id})).then(r=>{
        if (r.code=='200'){
          this.$message.success("密码修改成功！")
          this.resetAdvPasswordVisible = false
        }else{
          this.$message.warning(r.message)
        }
      })
    },
    passSubmit (form) {
      changePassword({ user_id: this.dialogData.user_id, password: form.password }).then(r => {
        if (r.code == '200') {
          this.$message.success('密码修改成功！')
          this.resetPasswordVisible = false
        } else {
          this.$message.warning(r.message)
        }
      })
    },
    handleCurrentChange (e) {
      this.page.currentpage = e
      this.getUserList()
    },
    handleSizeChange (e) {
      this.page.size = e
      this.getUserList()
    },
    importErpCus () {
      geterpuser().then(r => {
        const l = r.data
        for (let i = 0; i < 100; i++) {
          const mm = uuid(5, 16)
          userAdd({ username: l[i].cuscode, realname: l[i].cusname, password: mm, usertype: '1', erp_openid: l[i].sguid }).then(r1 => {
            if (r1.code == '200') {
              seterpcusdefpass({ id: l[i].sguid, pass: mm }).then(r2 => {
                console.log(r2)
              })
            }
          })
        }
      })
    },
    importErpUser () {

    },
    append (data) {
      this.treeInput = ''
      this.labelName = '新增部门'
      this.navBtnTitle = '新增部门'
      this.depSelect = data.dept_id
      this.getStructuresListBySuperior()
      this.depCreateDialog = true
    },
    handleExpand (type, node, data) {
      if (type === 'close') {
        if (data.children && data.children.length > 0) {
          node.expanded = false
        }
      } else if (type === 'open') {
        node.expanded = true
      }
    },
    // 获取新增部门 上级部门信息
    getStructuresListBySuperior (deptId) {
      this.dialogOptions = []
      if (deptId === undefined) {
        depList().then((res) => {
          var treedata = res.data
          if (treedata.length > 0) {
            treedata.unshift({
              dept_id: '0',
              pid: '',
              name: '全部',
              label: '全部',
              level: 0
            })
          }
          this.dialogOptions = treedata
        })
      } else {
        var data = { id: deptId }
        CanSetdepList(data).then((res) => {
          var treedata = res.data
          if (treedata.length > 0) {
            treedata.unshift({
              dept_id: '0',
              pid: '',
              name: '全部',
              label: '全部',
              level: 0
            })
          }
          this.dialogOptions = treedata
        })
      }
    },
    // 关闭新增或编辑
    navHandleClose () {
      this.depCreateDialog = false
    },
    // 获取树形列表
    treeListFun () {
      this.depLoading = true
      depTree().then((res) => {
        var treedata = res.data
        treedata.unshift({
          dept_id: '0',
          pid: '',
          name: '全部',
          label: '全部',
          level: 1
        })
        this.treeData = treedata
        this.depLoading = false
      }).catch(() => {
        this.depLoading = false
      })
    },
    // 编辑组织架构
    edit (node, data) {
      this.treeInput = data.name
      this.treeEditId = data.dept_id
      this.depSelect = data.pid
      this.navBtnTitle = '编辑部门'
      this.labelName = '编辑部门'
      this.getStructuresListBySuperior(data.dept_id)
      this.depCreateDialog = true
    },
    // 删除组织架构
    remove (node, data) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          depDelete({ id: data.dept_id })
            .then(res => {
              if (res.code === '200') {
                this.treeListFun()
                this.$message.success('删除成功')
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {
          console.log('取消')
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // })
        })
    },
    // 新增或编辑确定按钮
    submitDialog () {
      if (this.labelName === '新增部门') {
        depAdd({
          name: this.treeInput,
          pid: this.depSelect,
          dept_id: rowid()
        }).then(res => {
          this.getDepList() // 增加了新部门 刷新数据
          this.treeListFun()
          this.navHandleClose()
        })
      } else {
        depEdit({
          name: this.treeInput,
          dept_id: this.treeEditId,
          pid: this.depSelect
        }).then(res => {
          this.$message.success('操作成功')
          this.treeListFun()
          this.navHandleClose()
        })
      }
    },
    // 新建用户
    newBtn () {
      this.employeeCreateDialog = true
      this.dialogTitle = '新建用户'
      this.formInline = {
        role_id: []
      }
    },
    // 新建和编辑
    newHandleClose () {
      this.employeeCreateDialog = false
    },
    // 用户新建
    newDialogSubmit () {
      this.$refs.dialogRef.validate(valid => {
        if (valid) {
          if (this.dialogTitle === '新建用户') {
            this.formInline.role_ids = this.formInline.role_id.join(',')
            this.formInline.user_id = rowid()
            userAdd(this.formInline)
              .then(res => {
                this.$message.success('新增成功')
                this.employeeCreateDialog = false
                this.getUserList()
              })
              .catch(() => {

              })
          } else {
            this.formInline.role_ids = this.formInline.role_id.join(',')
            updateUser(this.formInline).then(res => {
              if (res.code == '200') {
                this.$message.success('修改成功')
                this.employeeCreateDialog = false
                this.getUserList()
              }
            }).catch(err => {
              this.$message.warning('修改失败')
            })
          }
        } else {
          return false
        }
      })
    },
    // 获取部门列表，用于新增dialog
    getDepList () {
      depList().then(response => {
        this.optionsList.dept_id.list = response.data
      })
    },
    getUserList () {
      userList({ param: this.searchdata, currentpage: this.page.currentpage, size: this.page.size }).then(req => {
        this.tableData = req.data.records
        this.page.pagetotal = req.data.total
        this.loading = false
      })
    },
    handleSelectionChange (val) {
      this.selectionList = val // 勾选的行
    },
    rowClick (row, column, event) {
      this.dialogData = row
      if (column.property === 'realname') {
        this.employeeDetailDialog = true
      }
    },
    // 列表信息格式化
    tableFormatter (row, column) {
      if (column.property === 'sex') {
        return { 1: '男', 2: '女', 0: '未知' }[row.sex]
      }
      if (column.property === 'usertype') {
        return { 0: '内部员工', 1: '经销商' }[row.usertype]
      }
      return row[column.property]
    },

    // 获取状态颜色 0 禁用 1 启用 2未激活
    getStatusColor (status) {
      if (status === 0) {
        return '#FF6767'
      } else if (status === 1) {
        return '#46CDCF'
      } else if (status === 2) {
        return '#CCCCCC'
      }
    },
    /** 操作 */
    selectionBarClick (type) {
      var ids = this.selectionList
        .map(function (item, index, array) {
          return item.user_id
        })
        .join(',')
      if (type === 'lock' || type === 'unlock') {
        var message = type === 'lock' ? '禁用' : '激活'
        this.$confirm('这些用户账号将被' + message + ', 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            usersEditStatus({
              userIds: ids,
              status: type === 'unlock' ? 1 : 0
            })
              .then(res => {
                // this.loading = false
                this.$message.success('修改成功')
                this.getUserList()
              })
              .catch(() => {
                console.log('sdfdf')
                // this.loading = false
              })
          })
          .catch(() => {
            console.log('取消')
            // this.$message({
            //   type: 'info',
            //   message: '已取消' + message
            // })
          })
      } else if (type === 'reset') {
        this.dialogData = this.selectionList[0]
        this.resetPasswordVisible = true
      } else if (type === 'resetAdvPsd') {
        this.dialogData = this.selectionList[0]
        this.resetAdvPasswordVisible = true
      }
      else if (type === 'resetName') {
        this.resetUserNameVisible = true
      } else if (type === 'edit') {
        this.dialogData = this.selectionList[0]
        this.dialogTitle = '编辑用户'
        var detail = {}
        for (let index = 0; index < this.tableList.length; index++) {
          const element = this.tableList[index]
          if (element.field !== 'password') {
            if (element.field === 'roleId') {
              detail[element.field] = this.dialogData.roleId ? this.dialogData.roleId.split(',').map(function (item, index, array) {
                return parseInt(item)
              }) : []
            } else if (element.field === 'parentId') {
              detail.parentId = this.dialogData.parentId
            } else if (element.field === 'deptId') {
              detail.deptId = this.dialogData.deptId
            } else {
              detail[element.field] = this.dialogData[element.field]
            }
          }
        }
        detail.user_id = this.dialogData.user_id
        this.formInline = detail
        this.employeeCreateDialog = true
      }
    },
    // 重置密码 -- 关闭按钮
    resetPasswordClose () {
      this.resetPasswordVisible = false
    },
    // 重置adv密码 -- 关闭按钮
    resetAdvPasswordClose () {
      this.resetAdvPasswordVisible = false
    }
  },
  computed: {
    // 工具栏
    selectionInfo: function () {
      let temps = []
      temps = [
        {
          name: '禁用',
          type: 'lock',
          icon: require('@/assets/img/selection_disable.png')
        },
        {
          name: '激活',
          type: 'unlock',
          icon: require('@/assets/img/selection_start.png')
        }
      ]
      if (this.selectionList.length === 1) {
        temps = temps.concat([
          {
            name: '编辑',
            type: 'edit',
            icon: require('@/assets/img/selection_edit.png')
          },
          {
            name: '重置密码',
            type: 'reset',
            icon: require('@/assets/img/selection_reset.png')
          },
          // {
          //   name: '重置登录账号',
          //   type: 'resetName',
          //   icon: require('@/assets/img/section_reset_name.png')
          // }
          {
            name: '重置高级密码',
            type: 'resetAdvPsd',
            icon: require('@/assets/img/section_reset_name.png')
          },
        ])
      } else {
        temps = temps.concat([
          {
            name: '重置密码',
            type: 'reset',
            icon: require('@/assets/img/selection_reset.png')
          }
        ])
      }
      return temps
    },
    /** 添加列表 */
    tableList: function () {
      if (this.dialogTitle === '新建用户') {
        return [
          {
            field: 'username',
            value: '用户名'
          },
          {
            field: 'password',
            value: '登录密码'
          },
          {
            field: 'realname',
            value: '姓名'
          }, {
            field: 'usertype',
            value: '用户类型',
            type: 'select'
          },
          {
            field: 'sex',
            value: '性别',
            type: 'select'
          },
          {
            field: 'mobile',
            value: '手机号'
          },
          {
            field: 'email',
            value: '邮箱'
          },
          {
            field: 'dept_id',
            value: '部门',
            type: 'select'
          },
          {
            field: 'post',
            value: '岗位'
          },
          {
            field: 'parent_id',
            value: '直属上级',
            type: 'select'
          },
          {
            field: 'role_id',
            value: '角色',
            type: 'selectCheckout'
          }
        ]
      } else {
        return [
          {
            field: 'username',
            value: '用户名',
            tips: '如需修改登录名，请在列表勾选用户后进行操作'
          },
          {
            field: 'realname',
            value: '姓名'
          },
          {
            field: 'sex',
            value: '性别',
            type: 'select'
          },
          {
            field: 'email',
            value: '邮箱'
          },
          {
            field: 'dept_id',
            value: '部门',
            type: 'select'
          },
          {
            field: 'post',
            value: '岗位'
          },
          {
            field: 'parent_id',
            value: '直属上级',
            type: 'select'
          },
          {
            field: 'role_id',
            value: '角色',
            type: 'selectCheckout'
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="less" type="text/less">

  /** 树形结构 */
  .el-tree /deep/ .el-tree-node__expand-icon {
    display: none;
  }

  .el-tree /deep/ .el-tree-node__content {
    height: 30px;

    .node-data {
      .node-img {
        width: 15px;
        height: 15px;
        display: block;
        margin-right: 8px;
        margin-left: 24px;
      }

      .node-label {
        margin-right: 8px;
      }

      .node-label-set {
        display: none;

        .el-button--text {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }

    .node-data:hover .node-label-set {
      display: block;
    }
  }

  .el-tree /deep/ .el-tree-node.is-current > .el-tree-node__content {
    background-color: #ebf3ff;
    border-right: 2px solid #46cdcf;

    .node-label-set {
      display: block;
    }
  }

  .system-view-nav /deep/ .el-tree-node > .el-tree-node__children {
    overflow: visible;
  }

  .system-view-nav /deep/ .el-tree > .el-tree-node {
    min-width: 100%;
    display: inline-block !important;
  }

  .node-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  /*tree*/
  .system-view-nav {
    width: 250px;
    height: 100%;
    overflow: auto;
    margin-right: 10px;
    background: #fff;
    padding-top: 20px;
    border: 1px solid #e6e6e6;
  }

  /*table*/
  .system-view-table {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #e6e6e6;
    position: absolute;
    top: 0;
    left: 260px;
    bottom: 0;
    right: 0;
  }

  /*content*/
  .system-content {
    position: relative;
    height: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .table-box{
    flex: 1;
  }

  .table-top {
    padding: 10px 0;

    .el-bottom {
      float: right;
      margin-right: 30px;
    }
  }

  .icon-search {
    width: 280px;
    position: relative;
    float: left;
    margin-left: 30px;
  }

  .status {
    display: inline-block;
    margin-left: 50px;

    span {
      margin-right: 10px;
    }
  }

  .nav-dialog-div {
    margin-bottom: 20px;
  }

  .nav-dialog-div /deep/ .el-input {
    width: auto;
  }
  .el-tree{
    color: #333333;
  }
  .status-name {
    div {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
    }
    color: #3E84E9;
    cursor: pointer;
  }

  /** 勾选操作 */
  .selection-bar {
    align-items: center;
    display: flex;
    font-size: 12px;
    height: 54px;
    min-height: 54px;
    padding: 0 20px;
    color: #777;

    .selected—title {
      flex-shrink: 0;
      padding-right: 20px;
      border-right: 1px solid #e6e6e6;
      .selected—count {
        color: #3E84E9;
      }
    }
  }
  .selection-items-box {
    align-items: center;
    display: flex;
    .selection-item {
      display: flex;
      width: auto;
      padding: 15px;
      .selection-item-icon {
        display: block;
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }
      .selection-item-name {
        cursor: pointer;
        color: #777;
      }
      .selection-item-name:hover {
        color: #3E84E9;
      }
    }
  }

  @import '../table.scss';

</style>
