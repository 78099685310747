<template>
    <el-table @cell-click="cellclick"
              :header-row-style="{height:'35px'}"
              :row-style="{height: rowHeiht}"
              :cell-class-name="tableCellClassName"
              ref="tb"
              @row-dblclick="rowDbclick"
              v-loading="loading"
              :border="true"
              :data="data"
              :height= height
              :row-key="rowKey"
              :show-summary="showSummary"
              header-cell-class-name="v-header-row"
              :summary-method="getSummaries"
    >
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column label="序号" align="center"  type="index"></el-table-column>
      <template v-for="(col, colindex) in columns" v-if="col.visible == null || col.visible">
        <el-table-column header-align="center" show-overflow-tooltip :prop="col.field" :width="col.width" :label="col.fieldname">
        <template  scope="scope">
          <div style="height: 100%;width: 100%;background-color: #ffffff" v-if="!disabled && !col.readonly && col.allowedit && scope.row[rowKey] == currentRowKey && scope.column.property == currentColKey">
            <!--            普通input-->
            <el-input size="mini" type="text" v-if="(col.type == 0 || !col.type) && !col.help_code" @input="fieldChange(col.field, scope.row);changed(col.field, scope.row)" style="padding: 0;display: inline-block" ref="inputRef"  v-model="scope.row[col.field]"/>
            <!--            下拉表格-->
            <dropdown-table ref="inputRef" v-if="col.type == 0 && col.help_code" :columns="Dict[col.help_code].columns" @selected="handleSelected($event, scope.row, col)" :data="Dict[col.help_code].data" :value="scope.row[col.field]"/>
            <!--            combox-->
            <el-select ref="inputRef" size="mini" v-if="col.type == 19" @change="fieldChange(col.field, scope.row);changed(col.field, scope.row)" filterable clearable v-model="scope.row[col.field]">
              <el-option
                v-for="item in Dict[col.id]"
                :key="item.value"
                :label="item.value"
                :value="item.value">
              </el-option>
            </el-select>
            <!--            数值-->
            <el-input size="mini" type="number" @input="fieldChange(col.field, scope.row);changed(col.field, scope.row)"  v-if="col.type==8" style="padding: 0;display: inline-block;" ref="inputRef"  v-model="scope.row[col.field]"/>
            <!--            日期-->
            <el-input size="mini" type="date" @input="fieldChange(col.field, scope.row);changed(col.field, scope.row)"  v-if="col.type==7" style="padding: 0;display: inline-block;" ref="inputRef"  v-model="scope.row[col.field]"/>
          </div>
          <span v-else>{{scope.row[col.field]|vformat(col)}}</span>
        </template>
        </el-table-column>
      </template>
    </el-table>
</template>

<script>
import { find_getbyfind } from '../api/common'
import { strToColumns, backFillObjectPlus, backFillObject } from '../util/common'
import dropdownTable from '../components/dropdown-table'
import * as math from 'mathjs'

export default {
  name: '',
  components: { dropdownTable },
  data () {
    return {
      currentRowKey: '',
      currentColKey: '',
      Dict: {},
      summaryColumn: [],
      readOnlyColumn: [],
      mustInputColumn: [],
      removeRow: []
    }
  },
  filters: {
    vformat (val, col) {
      if (col.hidezero && val == 0) {
        return ''
      }
      return val
    }
  },
  props: {
    height: {
      type: String,
      default: '300px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showSummary: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: []
    },
    data: {
      type: Array,
      default: []
    },
    rowKey: {
      type: String,
      default: 'sguid'
    },
    rowHeiht: {
      type: String,
      default: '30px'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showOrderColumn: {
      type: Boolean,
      default: true
    },
    showSelectColumn: {
      type: Boolean,
      default: true
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.tb.doLayout()
    })
  },
  watch: {
    columns: {
      handler: function (newVal, oldVal) {
        this.summaryColumn.length = 0
        this.readOnlyColumn.length = 0
        this.mustInputColumn.length = 0
        for (let i = 0; i < newVal.length; i++) {
          const col = newVal[i]
          if (col.sum) { this.summaryColumn.push(col.field) }
          if (col.readonly) { this.readOnlyColumn.push(col.field) }
          if (col.mustinput) { this.mustInputColumn.push(col.field) }

          if (col.type == 19) {
            const ar = col.combo.split(',')
            const r = []
            for (let i = 0; i < ar.length; i++) {
              r.push({ value: ar[i] })
            }
            this.Dict[col.id] = r
          }
          if (col.help_code) {
            find_getbyfind({ code: col.help_code, param: {} }).then(r => {
              if (r.code == '200') {
                const d = {}
                d.columns = strToColumns(r.data.fieldandname)
                d.data = r.data.data
                this.Dict[col.help_code] = d
              }
              this.loading = false
            }).then(err => { this.loading = false })
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getCurrentRowIndex () {
      return this.data.findIndex((obj) => {
        return obj[this.rowKey] == this.currentRowKey
      })
    },
    getColumnByField (field) {
      return this.columns.find(item => {
        return item.field == field
      })
    },
    resetState () {
      this.removeRow.length = 0
      for (let i = 0; i < this.data.length; i++) {
        this.data[i]._rowState = null
      }
    },
    backFillObject (Target, TargetField, Source, SourceField) {
      backFillObject(Target, TargetField, Source, SourceField, this.fieldChange, this.changed)
    },
    fieldChange (field, row) {
      if (!row._rowState) { this.$set(row, '_rowState', 'update') }
      this.$emit('field-change', { field: field, row: row })
    },
    changed (field, row) {
      if (!row._rowState) { this.$set(row, '_rowState', 'update') }
      this.$emit('changed', { field: field, row: row })
    },
    tableCellClassName (param) {
      const { row, column, rowIndex, columnIndex } = param
      if (this.readOnlyColumn.indexOf(column.property) > -1) { return 'vcellstyle readonly' }
      return 'vcellstyle'
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => item[column.property])
        if (this.summaryColumn.indexOf(column.property) > -1) {
          sums[index] = values.reduce((prev, curr) => {
            return math.add(math.bignumber(prev || 0), math.bignumber(curr || 0)).toString()
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleSelected (e, row, col) {
      backFillObjectPlus(row, e, col.alias_self, this.fieldChange)
      this.changed(col.field, row)
    },
    cellclick (row, column, cell, event) {
      this.currentColKey = column.property
      this.currentRowKey = row[this.rowKey]
      this.$nextTick(function () {
        if (this.$refs.inputRef && this.$refs.inputRef.length > 0) { this.$refs.inputRef[0].focus() }
      })
    },
    insertrow (vals) {
      const rowIndex = this.getCurrentRowIndex()
      // 插入行之后的行rowno+1
      if (rowIndex > -1) {
        for (var i = this.data.length - 1; i >= rowIndex; i--) {
          const old_rowno = this.data[i].rowno
          this.$set(this.data[i], 'rowno', old_rowno + 1)
          if (this.data[i]._rowState != 'insert') { this.$set(this.data[i], '_rowState', 'update') }
        }
      }
      // 插入行
      const rowno = rowIndex + 1
      const newRow = {}
      this.$set(newRow, '_rowState', 'insert')
      this.$set(newRow, 'rowno', rowno)
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i]
        newRow[col.field] = null
      }
      for (const key in vals) {
        this.$set(newRow, key, vals[key])
        this.fieldChange(key, newRow)
      }
      this.data.splice(rowIndex, 0, newRow)
      this.changed(null, newRow)
      return newRow
    },

    addrow (vals) {
      const newRow = {}
      this.$set(newRow, '_rowState', 'insert')
      this.$set(newRow, 'rowno', this.data.length + 1)
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i]
        newRow[col.field] = null
      }
      for (const key in vals) {
        this.$set(newRow, key, vals[key])
        this.fieldChange(key, newRow)
      }
      this.data.push(newRow)
      this.changed(null, newRow)
      return newRow
    },
    deleterow () {
      const selection = this.$refs.tb.selection
      if (selection.length == 0) {
        this.$message.warning('请选择行后再删除！')
      }
      for (let i = 0; i < selection.length; i++) {
        for (let j = 0; j < this.data.length; j++) {
          if (selection[i][this.rowKey] == this.data[j][this.rowKey]) {
            const removeRow = this.data.splice(j, 1)
            if (removeRow[0]._rowState != 'insert') {
              this.$set(removeRow[0], '_rowState', 'deleted')
              this.removeRow.push(removeRow[0][this.rowKey])
            }
          }
        }
      }
      for (let i = 0; i < this.data.length; i++) {
        this.$set(this.data[i], 'rowno', i + 1)
      }
      this.$refs.tb.clearSelection()
    },
    rowDbclick (row, column, event) {
      this.$emit('row-dbclick', row, column, event)
    }
  }
}
</script>

<style scoped>

  .el-table ::v-deep .vcellstyle{
    padding: 0;
  }
   .el-table ::v-deep .cell, .el-table ::v-deep .el-table--border td:first-child .cell, .el-table ::v-deep .el-table--border th:first-child .cell{
     padding-left: 0 !important;
     padding-right: 0;
  }
  /*只读列 */
  .el-table ::v-deep .readonly{
    background-color: #F3F3F3;
  }

 .el-table ::v-deep .el-table__header th {
   background-color: #F6F8FA;
   color: #333333;
   font-weight: bold;
   padding: 0;
 }

  .el-input ::v-deep .el-input__inner{
    padding: 0;
    border-radius: 0 !important;
    /*line-height: 100%;*/
    /*border: 0px;*/
  }
  .el-select ::v-deep .el-input__inner{
    padding-left: 0;
    border-radius: 0 !important;;
    /*border: 0px;*/
  }

 .el-table ::v-deep .el-table__footer td, .el-table ::v-deep .el-table__footer th{
   padding: 0 !important;
   height: 35px;
 }
</style>
