<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" label-width="80px" size="small" @submit.native.prevent>
        <el-row :gutter="20">
          <el-col :span="10" >
            <el-form-item label="关键字">
              <el-input v-model="searchdata.key" @keyup.enter.native="search"  placeholder="请输入关键字"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div style="margin-bottom: 24px">
              <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="tbbox">
      <el-table ref="tb" class="tb" :summary-method="getSummaries" show-summary v-loading="loading" :data="listdata" :height="'1'" :border="true">
        <el-table-column header-align="center" label="日期" prop="billdate" :width="110" :formatter="el_table_datetime_format"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="订单编号" prop="billnum" :width="150" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="货品名称" prop="mname" :width="150" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="类型" prop="dc6" :width="80" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="做工信息" prop="dc1" :width="250"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="材质" prop="fc3" :width="100"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="颜色" prop="fc1" :width="100"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="数量" prop="factissuequan" :width="80"  show-overflow-tooltip></el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getdeliverygoods
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, el_table_datetime_format } from '../../util/common'
import { uploadUrl } from '../../config'
export default {
  components: {
    NewView
  },

  data () {
    return {
      showApplyDialog: false,
      pagetotal: 0,
      activities: [],
      el_table_datetime_format: el_table_datetime_format,
      loading: false,
      searchdata: { key: '', type: '0', curpage: 1, size: 200 },
      carddata: {},
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    hidezero (s, d) {
      if (s[d.property] == 0) { return '' } else { return s[d.property] }
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // if (!values.every(value => isNaN(value))) {
        if (['factissuequan'].includes(column.property)) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleSizeChange (e) {
      this.searchdata.size = e
      this.load()
    },
    handleCurrentChange (e) {
      this.searchdata.curpage = e
      this.load()
    },
    statechange (e) {
      this.searchdata.type = e
      this.search()
    },
    search () {
      this.load()
    },
    load () {
      this.loading = true
      getdeliverygoods(this.searchdata).then(res => {
        this.listdata = res.data.records
        this.pagetotal = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    }

  },
  mounted () {
    this.load()
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.tb.doLayout()
    })
  }

}
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    position: relative;
    display: flex;
    flex: 1;
  }
  .tb{
    height: 100% !important;
    position: absolute;
  }

  .el-menu-demo{
    background-color: #F6F8FA;
    border: 1px solid #E6E6E6;
    border-bottom: 0px;
  }
  .el-menu-item{
    height: 40px;
    line-height: 40px;
  }

  @import "../table";
</style>
