<template>
      <el-upload
        :action= "serverUrl"
        :headers= "upfile.headers"
        :data= "upfile.data"
        :on-change="handleChange"
        :on-preview="handlePreview"
        :on-success= "handleUploadSuccess"
        :on-remove="handleRemove"
        :file-list="fileList">
        <el-button size="small" type="primary">{{buttonTxt}}</el-button>
<!--        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
      </el-upload>
</template>

<script>
import { getToken } from '../util/common'

export default {
  model: {
    prop: 'value',
    event: 'handleJSONSuccess'
  },
  props: {
    buttonTxt: {
      type: String,
      default: '上传'
    },
    flag: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    bizPath: {
      type: String,
      default: ''
    },
    serverUrl: {
      type: String,
      required: true
    },
    buttonType: {
      type: String,
      default: 'primary'
    },
    value: Array,
    name: null,
    uploadId: null,
    onSuccess: Function,
    onProgress: Function,
    beforeUpload: Function,
    fileType: null // 文件类型限制用|分隔 如png|jpg|jpeg|gif
  },
  data () {
    return {
      fileList: [],
      upfile: {
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      }
    }
  },
  methods: {
    handlePreview (file) {
      console.log(file)
      window.location.href = file.url
      // window.open(file.url, '_self')
    },
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    handleUploadSuccess (res, file, fileList) {
      if (res.code === '200') { // 重新绑定数据
        file.path = res.data.path
        var arr = this.fileList.map(curr => {
          return { name: curr.name, path: curr.path }
        })
        this.$emit('handleJSONSuccess', arr)
      }
    },
    handleRemove (file, fileList) {
      this.$emit('handleJSONSuccess', fileList)
    }
  },
  watch: {
    value: function () {
      var s = this.value || []
      var v = s.filter(cur => {
        cur.url = this.$options.filters.getImgSrc('') + cur.path
      })
      this.fileList = v
    }
  },
  mounted: function () {
    var s = this.value || []
    var v = s.filter(cur => {
      cur.url = this.$options.filters.getImgSrc('') + cur.path
      return cur
    })
    this.fileList = v
  }
}
</script>

<style scoped>

</style>
