<template>
<div @click="handleInputClick">
    <el-input autocomplete="off" :value="editing ? searchvalue : value"
          size="mini"
          @input="changeValue"
          @focus="onfocus"
          @blur="onblur"
          @keydown.native.down.stop.prevent="navigateOptions('next')"
          @keydown.native.up.stop.prevent="navigateOptions('up')"
          @keydown.native.enter.prevent="selectOption"
          :placeholder="value? value:'请选择'"
           ref="reference"/>
  <select-dropdown ref="popper" v-show="visible" append-to-body>
      <div class="table-container">
        <el-table v-loading="listLoading"
                  ref="droptable"
                  highlight-current-row
                  :header-cell-style="{padding:'1px',backgroundColor: '#F6F8FA'}"
                  :cell-style="{padding:'1px'}"
                  :data="filteddata.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
                  @row-click="rowClick"
                  stripe
                  size="mini"
                  element-loading-text="Loading"
                  fit
                  border
                  highlight-current-row>
          <el-table-column v-if="showIndex" label="序号"    type="index"  align="center" width="50"></el-table-column>
          <el-table-column v-for="item in columns"
                           show-overflow-tooltip
                           :type="item.type"
                           :key="item.field"
                           :label="item.fieldname"
                           :prop="item.field"
                           :align="item.align"
                           :width="item.width"
                           header-align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="total, prev, pager, next"
          :total="filteddata.length"
          :page-size="pageSize"
          :page-sizes="sizesArray"
        ></el-pagination>
      </div>
  </select-dropdown>
</div>
</template>

<script>
import { addResizeListener, removeResizeListener } from '../util/resize-event'
import selectDropdown from '../components/select-dropdown'

function broadcast (componentName, eventName, params) {
  this.$children.forEach(child => {
    var name = child.$options.componentName
    if (name === componentName) {
      child.$emit.apply(child, [eventName].concat(params))
    } else {
      broadcast.apply(child, [componentName, eventName].concat([params]))
    }
  })
}
export default {
  name: 'el-combo-grid',
  props: {
    placeholder: { type: String },
    value: { type: String },
    requestUrl: { type: String },
    columns: { type: Array },
    panelStyle: { type: String },
    itemValue: { type: String },
    showIndex: { type: Boolean },
    pagination: { type: Boolean },
    requestParam: { type: Object },
    data: { type: Array }
  },
  components: { selectDropdown },
  computed: {
    filteddata () {
      if (!this.searchvalue) return this.data
      const patt1 = new RegExp('\\[(.*)\\]')
      const cols = this.columns.filter(col => {
        return !patt1.test(col.fieldname)
      })
      return this.data.filter(item => {
        if (this.searchvalue) {
          for (let i = 0; i < cols.length; i++) {
            if (item[cols[i].field] && item[cols[i].field].toLowerCase().includes(this.searchvalue.toLowerCase())) { return true }
          }
        }
      })
    }
  },
  data () {
    return {
      currentrow: null,
      searchvalue: '',
      editing: false,
      total: 0, // 总条数
      pageSize: 10, // 每页显示条数
      currentPage: 1, // 当前页数
      sizesArray: [10], // 可选择的页数
      visible: false,
      pStyle: 'width:500px',
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 5,
        keyword: null
      },
      keyword: ''
    }
  },
  mounted () {
    this.$refs.reference.focus()
    this.broadcast('ElSelectDropdown', 'updatePopper')
  },
  methods: {
    focus () {
      this.$refs.reference.focus()
    },
    handleInputClick () {

    },
    rowClick: function (row, column, event) {
      this.currentrow = row
      this.$emit('selected', this.currentrow)
      this.visible = false
    },
    selectOption () {
      if (!this.currentrow) {
        this.visible = false
        this.$refs.reference.blur()
        return
      }
      this.$emit('selected', this.currentrow)
      this.visible = false
      this.$refs.reference.blur()
    },
    resetCurrentRow () {
      this.currentrow = null
      this.$refs.droptable.setCurrentRow()
    },
    navigateOptions (e) {
      if (e == 'next') {
        if (!this.currentrow && this.filteddata.length > 0) { // 如果当前行为空
          this.currentrow = this.filteddata[0]
          this.$refs.droptable.setCurrentRow(this.currentrow)
          return
        }
        let index = this.filteddata.findIndex((n) => n == this.currentrow) // 不为空取索引定位
        if (index == -1) { // 未找到
          this.currentrow = this.filteddata[0]
          this.$refs.droptable.setCurrentRow(this.currentrow)
          return
        }
        if (index < this.filteddata.length - 1) {
          index++
          // 跳转页面
          this.currentPage = (index + 1) % this.pageSize == 0 ? (index + 1) / this.pageSize : Math.floor((index + 1) / this.pageSize) + 1
          this.$nextTick(() => {
            this.currentrow = this.filteddata[index]
            this.$refs.droptable.setCurrentRow(this.currentrow)
          })
        }
      }
      if (e == 'up') {
        if (!this.currentrow) return
        let index = this.filteddata.findIndex((n) => n == this.currentrow) // 取索引定位
        if (index > 0) {
          index--
          this.currentPage = (index + 1) % this.pageSize == 0 ? (index + 1) / this.pageSize : Math.floor((index + 1) / this.pageSize) + 1
          this.$nextTick(() => {
            this.currentrow = this.filteddata[index]
            this.$refs.droptable.setCurrentRow(this.currentrow)
          })
        }
      }
    },
    broadcast (componentName, eventName, params) {
      broadcast.call(this, componentName, eventName, params)
    },
    // 改变页数
    handleCurrentChange (val) {
      this.currentPage = val
    },
    // 改变条数
    handleSizeChange (val) {
      this.pageSize = val
    },
    changeValue (val) {
      this.searchvalue = val
      this.resetCurrentRow()
    },
    showDownForm () {
      this.searchvalue = ''
      this.editing = true
      this.currentrow = null
      // 定位
      // let index = this.filteddata.findIndex((n) => n == this.currentrow) //取索引定位
      // if (index > 0){
      //   this.currentPage = (index+1) % this.pageSize == 0 ? (index+1) / this.pageSize : Math.floor((index+1) / this.pageSize) + 1
      //   this.$nextTick(()=>{
      //     this.currentrow = this.filteddata[index]
      //     this.$refs.droptable.setCurrentRow(this.currentrow)
      //   })
      // }
      this.visible = true
    },
    onfocus (el) {
      this.showDownForm()
    },
    onblur (el) {
      this.editing = false
    }
  }
}
</script>

<style scoped>

  .table-container{
    background-color: #ffffff;
    max-height: 500px;
  }

  .el-dropdown-menu.el-popper{
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top:0;
    padding-bottom: 0;
  }

  .el-input ::v-deep .el-input__inner{
    padding: 0;
    border-radius: 0 !important;
  }

</style>
