<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" label-width="80px" size="small" @submit.native.prevent>
        <el-row :gutter="20">
          <el-col :span="10" >
            <el-form-item label="关键字">
              <el-input v-model="searchdata.key" @keyup.enter.native="search"  placeholder="请输入订单编号/客户名称/货品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div style="margin-bottom: 24px">
              <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
<!--              <el-button type="primary"  icon="el-icon-plus" @click="addactivity">新增</el-button>-->
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-menu class="el-menu-demo" mode="horizontal" :default-active="searchdata.type" @select="statechange">
      <el-menu-item index="0">待发货订单</el-menu-item>
      <el-menu-item index="1">全部订单</el-menu-item>
    </el-menu>

    <div class="tbbox">
      <el-table ref="tb" class="tb" :summary-method="getSummaries" show-summary v-loading="loading" :data="listdata" :height="'1'" :border="true">
        <el-table-column header-align="center" label="订单编号" prop="billnum" :width="115" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="下单时间" prop="billdate" :width="105" :formatter="el_table_datetime_format"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="客户名称" prop="kehumingcheng" :width="125" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="货品名称" prop="mname" :width="125" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="颜色" prop="fc1" :width="105" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="非标说明" prop="dc1" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="数量" prop="quanissue" align="right" :width="80" show-overflow-tooltip></el-table-column>
<!--        <el-table-column header-align="center" label="金额" prop="issuemoney" align="right" :width="80" show-overflow-tooltip></el-table-column>-->
        <el-table-column header-align="center" label="订单状态" align="center" class-name="blogstyle" :width="100" prop="blog" show-overflow-tooltip>
          <div style="color: #A52A2A;" slot-scope="scope">
            {{scope.row[scope.column.property]}}
          </div>
<!--          <div slot-scope="scope">-->
<!--            <el-popover-->
<!--              placement="left"-->
<!--              width="160"-->
<!--              trigger="click">-->
<!--              <el-timeline style="padding: 0px; max-height: 200px; overflow: auto">-->
<!--                <el-timeline-item-->
<!--                  v-for="(activity, index) in activities"-->
<!--                  :key="index"-->
<!--                  :timestamp="activity.log_time|dateformat">-->
<!--                  {{activity.log_content}}-->
<!--                </el-timeline-item>-->
<!--              </el-timeline>-->
<!--              <div slot="reference" style="color: #A52A2A; cursor: pointer" @click="glog(scope)">{{scope.row[scope.column.property]}}</div>-->
<!--            </el-popover>-->
<!--          </div>-->
        </el-table-column>
<!--        <el-table-column header-align="center" fixed="right" :width="145" label="操作">-->
<!--          <div class="commanddiv" slot-scope="scope">-->
<!--            <span @click="viewdetail(scope.row)">查看详情</span>-->
<!--            <el-divider direction="vertical"/>-->
<!--            <span @click="cancel(scope.row)">取消订单</span>-->
<!--          </div>-->
        </el-table-column>
        <el-table-column header-align="center" fixed="right" :width="80" label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="showApplyGD(scope.row)">申请改单</span>
          </div>
        </el-table-column>
        <el-table-column header-align="center" fixed="right" :width="80" label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="showApply(scope.row)">申请售后</span>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>


    <baseFlowDialog ref="applyForm" :visible.sync="showApplyDialog" @close="baseFormClose" :params="formParams" :defaultValue="initValue">

    </baseFlowDialog>

    <baseFlowDialog ref="applyForm" :visible.sync="showApplyGDDialog" @close="baseFormClose" :params="formParams" :defaultValue="initValue">

    </baseFlowDialog>

  </div>
</template>

<script>
import {
  activityAdd,
  activityBodyAdd,
  activityBodyDelete,
  activityBodyEdit,
  activityBodyList,
  activityDelete,
  activityEdit,
  getuserallfeibiaodingzhi,
  addfeibiaodingzhi,
  cancelfeibiaodingzhi,
  getbuslog,
  getallmyorder
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, rowid, el_table_datetime_format } from '../../util/common'

import { uploadUrl, mobileurl } from '../../config'
import QRCode from 'qrcodejs2'
import baseFlowDialog from '../../components/baseFlowDialog'


export default {
  components: {
    NewView,
    baseFlowDialog
  },

  data () {
    return {
      initValue:{},
      formParams:'',
      showApplyDialog: false,
      showApplyGDDialog: false,
      pagetotal: 0,
      activities: [],
      el_table_datetime_format: el_table_datetime_format,
      loading: false,
      searchdata: { key: '', type: '0', curpage: 1, size: 100 },
      carddata: {},
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    baseFormClose(){
      this.showApplyDialog=false
      // this.loaddata()
    },
    showApply (row) {
      this.formParams = {id: this.afterOrderProWorkFlowKey, idType: "processDefKey"}
      this.initValue = row
      this.showApplyDialog = true
    },
    showApplyGD (row) {
      this.formParams = {id: this.gDWorkFlowKey, idType: "processDefKey"}
      this.initValue = row
      this.showApplyDialog = true
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // if (!values.every(value => isNaN(value))) {
        if (['quanissue', 'issuemoney'].includes(column.property)) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleSizeChange (e) {
      this.searchdata.size = e
      this.load()
    },
    handleCurrentChange (e) {
      this.searchdata.curpage = e
      this.load()
    },
    statechange (e) {
      this.searchdata.type = e
      this.search()
    },
    glog (e) {
      this.activities = []
      getbuslog({ rel_id: e.row.id }).then(r => {
        this.activities = r.data
      })
    },
    search () {
      this.searchdata.curpage = 1
      this.load()
    },
    cancel (row) {
      this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
        cancelfeibiaodingzhi({ id: row.id }).then(res => {
          if (res.code == '205') { this.$message.warning(res.message) }
          if (res.code === '200') {
            this.load()
            this.$message.success('操作成功!')
          }
        })
      })
    },
    viewdetail (row) {
      const that = this
      const d = Object.assign({}, row)
      d.child = JSON.parse(d.child)
      d.imgs = JSON.parse(d.imgs || '[]')
      this.carddata = d
      this.title = '详情'
      this.dialog = true
      this.$nextTick(r => {
        that.$refs.bill.disabledall = true
      })
    },
    addoption () {
      this.imglist = []
      this.optionData = { activity_id: this.formData.id }
      this.optiontitle = '新增'
      this.showModal = true
    },
    dialogclose () {
      this.formData = {}
      this.bodydata = []
      this.dialog = false
    },
    billstateconvert (row, column) {
      const val = row[column.property]
      if (val == '-1') {
        return '订单取消'
      }
    },
    load () {
      this.loading = true
      getallmyorder(this.searchdata).then(res => {
        this.listdata = res.data.records
        this.pagetotal = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    save (e) {
      const d = Object.assign({}, e)
      d.child = JSON.stringify(d.child)
      d.imgs = JSON.stringify(d.imgs)
      addfeibiaodingzhi(d).then(res => {
        if (res.code == '200') {
          this.dialog = false
          this.load()
          this.$message.success('操作成功!')
        }
      })
    },
    addactivity () {
      this.title = '新增'
      this.carddata = null
      this.bodydata = []
      this.formData = { id: rowid() }
      this.dialog = true
    },
    handlePictureCardPreview () {

    },
    handleAvatarSuccess (response, file, filelist) {
      this.optionData.img = response.data.path
    },
    handleRemove (file, filelist) {
      if (filelist.length === 0) {
        this.optionData.img = ''
      }
    },
    handleRemove2 (file, filelist) {

    },
    closemodal () {
      this.showModal = false
    },
    loadbody (id) {
      this.bodydata = []
      activityBodyList({ id: id, search: '' }).then(res => {
        if (res.code === '200') {
          this.bodydata = res.data
        }
      })
    },
    editoption (row) {
      this.optionData = Object.assign({}, row)
      if (this.optionData.att) {
        this.optionData.attlist = JSON.parse(this.optionData.att)
      }
      if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
      this.optiontitle = '编辑'
      this.showModal = true
    },
    uperror (err) {
      console.log(err)
    },
    submitoption () {
      this.$refs.option.validate(vali => {
        if (vali) {
          if (this.optionData.attlist) {
            const att = []
            this.optionData.attlist.map(cur => {
              att.push({ name: cur.name, path: cur.path })
            })
            this.optionData.att = JSON.stringify(att)
          }
          console.log(this.optionData)
          if (this.optiontitle === '新增') {
            activityBodyAdd(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          } else if (this.optiontitle === '编辑') {
            activityBodyEdit(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          }
        }
      })
    },
    deleteactivity (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.load()
          }
        })
      })
    },
    deleteoption (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityBodyDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.loadbody(row.activity_id)
          }
        })
      })
    },
    submitactivity () {
      this.$refs.mainform.validate(vali => {
        if (vali) {
          if (this.title === '新增') {
            activityAdd(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          } else if (this.title === '编辑') {
            activityEdit(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          }
        } else {
          console.log('fail')
        }
      })
    },
    crateQrcode (text) {
      this.qr = new QRCode('qrcode', {
        width: 128,
        height: 128, // 高度
        text: text // 二维码内容
      })
    },
    genqrcode (row) {
      console.log(mobileurl)
      this.qrcodedialog = true
      this.$nextTick(() => {
        this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
      })
    },
    qrcodedialogclose () {
      this.$refs.qrcode.innerHTML = ''
    },
    optiondialogclose () {
      this.optionData = {}
      this.imglist = []
      this.fileList = []
    },
    // 预览图片
    handlePreview () {

    },
    filelistchange (file, filelist) {
      console.log(filelist.slice(-3))
    },
    uploadSuccess (req, file, filelist) {
      if (req.code === '200') {
        file.url = req.data.path
      }
    },
    handleChange (file, filelist) {
      this.fileList = filelist.slice(-3)
    }
  },
  mounted () {
    this.load()
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.tb.doLayout()
    })
  }

}
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    position: relative;
    display: flex;
    flex: 1;
  }
  .tb{
    height: 100% !important;
    position: absolute;
  }

  .el-menu-demo{
    background-color: #F6F8FA;
    border: 1px solid #E6E6E6;
    border-bottom: 0px;
  }
  .el-menu-item{
    height: 40px;
    line-height: 40px;
  }

  @import "../table";
</style>
