<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" :inline="true" size="small"  @submit.native.prevent >
            <el-form-item label="关键字">
              <el-input v-model="searchdata.key" @keyup.enter.native="search"  placeholder="请输入订单编号/联系电话"></el-input>
            </el-form-item>
            <el-form-item label="经销商">
              <el-input v-model="queryParas[3].val" @keyup.enter.native="search"  placeholder="经销商"></el-input>
            </el-form-item>
            <el-form-item label="设计师">
              <el-input v-model="queryParas[0].val" @keyup.enter.native="search"  placeholder="设计师"></el-input>
            </el-form-item>
            <el-form-item label="下单时间">
              <el-date-picker
               v-model="value"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
        <el-form-item label="订单状态">
          <!--          <el-input v-model="queryParas[4].val" @keyup.enter.native="search"  placeholder="订单状态"></el-input>-->
          <el-select clearable v-model="queryParas[4].val" placeholder="请选择订单状态">
            <el-option
              v-for="item in dict_state"
              :key="item.log_content"
              :label="item.log_content"
              :value="item.log_content">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="queryParas[5].val" @keyup.enter.native="search"  placeholder="型号"></el-input>
        </el-form-item>
        <el-form-item label="尺寸">
          <el-input v-model="queryParas[6].val" @keyup.enter.native="search"  placeholder="尺寸"></el-input>
        </el-form-item>
           <el-form-item>
            <div style="margin-bottom: 24px">
              <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
            </div>
           </el-form-item>
      </el-form>
    </div>

    <el-menu class="el-menu-demo" mode="horizontal" :default-active="searchdata.type" @select="statechange">
      <el-menu-item index="0">未分配</el-menu-item>
      <el-menu-item index="1">全部</el-menu-item>
    </el-menu>

    <div class="tbbox">
      <el-table class="tb"  v-loading="loading" :data="listdata" :height="'1'" :border="true">
        <el-table-column  type="index" width="50" align="center" label="序号"/>
        <el-table-column header-align="center" label="订单状态" :width="150" align="center" prop="blog" show-overflow-tooltip>
          <div slot-scope="scope">
            <el-popover
              placement="left"
              width="160"
              trigger="click">
              <el-timeline style="padding: 0px; max-height: 200px; overflow: auto">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.log_time|dateformat">
                  {{activity.log_content}}
                </el-timeline-item>
              </el-timeline>
              <div slot="reference" style="color: #A52A2A; cursor: pointer" @click="glog(scope)">{{scope.row[scope.column.property]}}</div>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column header-align="center" label="订单编号" :width="120" prop="billnum" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="下单时间" :width="110" prop="makedate" :formatter="el_table_datetime_format" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="经销商" :width="110" prop="maker_name" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="终端客户名称" :width="110" prop="kehumingcheng" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="联系电话" :width="110" prop="lianxidianhua" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="型号" :width="100" prop="model" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="尺寸" :width="110" prop="size" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="指定设计师" :width="110" prop="zhidingshejishi_name" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="设计师" :width="110" prop="shejishi_name" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="要求完成设计时间" :width="110" prop="yaoqiushejishijian" show-overflow-tooltip :formatter="el_table_datetime_format"></el-table-column>
        <el-table-column header-align="center" label="说明" prop="shuoming" show-overflow-tooltip></el-table-column>

        <el-table-column header-align="center" fixed="right" :width="145" label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="viewdetail(scope.row)">订单详情</span>
            <el-divider direction="vertical"/>
            <span v-if="userinfo.realname === '杜丽红'" @click="dispatch(scope.row)">分配</span>
              <span v-else style="color: #999">分配</span>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>
    <new-view v-if="dialog" @close="dialogclose" :title="title">
      <edit ref="bill" :data="carddata" @post="save" @cancel="dialogclose"/>
    </new-view>

    <el-dialog title="分配" :visible.sync="dialog_fp" width="450px">
      <el-form label-width="100px">
        <el-form-item label="是否需要出图">
          <el-select v-model="currentRow.want_plot" placeholder="请选择">
            <el-option label="是" value=1></el-option>
            <el-option label="否" value=0></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择设计师" v-if="currentRow.want_plot =='1'">
          <el-select  clearable v-model="currentRow.shejishi" placeholder="请选择">
            <el-option
              v-for="item in dict_shejishi"
              :key="item.user_id"
              :label="item.realname"
              :value="item.user_id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

<!--      <label style=" display: inline-block;width: 60px"></label>-->

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog_fp = false">取 消</el-button>
        <el-button type="primary" @click="saveshejishi">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {
  activityAdd,
  activityBodyAdd,
  activityBodyDelete,
  activityBodyEdit,
  activityBodyList,
  activityDelete,
  activityEdit,
  getuserallfeibiaodingzhi,
  addfeibiaodingzhi,
  cancelfeibiaodingzhi,
  getlistfordispatch,
  getbuslog,
  feibiaodingzhi,
  find_getbyfind,
  getonefeibiaodingzhi
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, rowid, el_table_datetime_format, dateformat } from '../../util/common'

import { uploadUrl, mobileurl } from '../../config'
import QRCode from 'qrcodejs2'
import edit from '../feibiaodingzhi/edit'
import {mapGetters} from "vuex";

export default {
  components: {
    NewView,
    edit
  },
  computed:{
      ...mapGetters(['userinfo'])
  },
  data () {
    return {
      pagetotal: 0,
      dict_state: [],
      value: [],
      queryParas: [
        { field: 'shejishi_name', type: '0', rule: 'like', val: null },
        { field: 'makedate', type: '0', rule: 'ge', val: null },
        { field: 'makedate', type: '0', rule: 'le', val: null },
        { field: 'maker_name', type: '0', rule: 'like', val: null },
        { field: 'blog', type: '0', rule: 'like', val: null },
        { field: 'model', type: '0', rule: 'like', val: null },
        { field: 'size', type: '0', rule: 'like', val: null }
      ],
      currentRow: { id: '', shejishi: '', want_plot: '1' },
      dict_shejishi: [],
      dialog_fp: false,
      activities: [],
      el_table_datetime_format: el_table_datetime_format,
      loading: false,
      searchdata: { key: '', type: '0', curpage: 1, size: 100 },
      carddata: {},
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    handleSizeChange (e) {
      this.searchdata.size = e
      this.load()
    },
    handleCurrentChange (e) {
      this.searchdata.curpage = e
      this.load()
    },
    saveshejishi () {
      feibiaodingzhi.setdesigner(this.currentRow).then(r => {
        if (r.code == '200') {
          this.dialog_fp = false
          this.load()
        }
      })
    },
    dispatch (row) {
      if (row.shejishi) {
        this.$confirm('该订单已指定设计师是否重新指定？', '提示', { type: 'warning' }).then(res => {
          this.currentRow.id = row.id
          this.currentRow.shejishi = row.shejishi
          this.dialog_fp = true
        })
      } else {
        this.currentRow.id = row.id
        this.currentRow.shejishi = row.shejishi
        this.dialog_fp = true
      }
    },
    statechange (e) {
      this.searchdata.type = e
      this.search()
    },
    glog (e) {
      this.activities = []
      getbuslog({ rel_id: e.row.id }).then(r => {
        this.activities = r.data
      })
    },
    search () {
      this.searchdata.curpage = 1
      this.load()
    },
    cancel (row) {
      this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
        cancelfeibiaodingzhi({ id: row.id }).then(res => {
          if (res.code == '205') { this.$message.warning(res.message) }
          if (res.code === '200') {
            this.load()
            this.$message.success('操作成功!')
          }
        })
      })
    },
    viewdetail (row) {
      const that = this
      getonefeibiaodingzhi({ id: row.id }).then(r => {
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          this.carddata = d
          this.title = '详情'
          this.dialog = true
          this.$nextTick(r => {
            that.$refs.bill.disabledall = true
          })
        }
      })
      // let d = Object.assign({}, row)
      // d.child = JSON.parse(d.child)
      // this.carddata= d
      // this.title = '详情'
      // this.dialog = true
      // this.$nextTick(r=>{
      //   that.$refs.bill.disabledall=true
      // })
    },
    addoption () {
      this.imglist = []
      this.optionData = { activity_id: this.formData.id }
      this.optiontitle = '新增'
      this.showModal = true
    },
    dialogclose () {
      this.formData = {}
      this.bodydata = []
      this.dialog = false
    },
    billstateconvert (row, column) {
      const val = row[column.property]
      if (val == '-1') {
        return '订单取消'
      }
    },
    load () {
      this.loading = true
      if (this.value && this.value.length > 1) {
        this.queryParas[1].val = dateformat(this.value[0])
        this.queryParas[2].val = dateformat(this.value[1])
      } else {
        this.queryParas[1].val = null
        this.queryParas[2].val = null
      }

      const param = Object.assign({}, this.searchdata,
        { queryParams: encodeURI(JSON.stringify(this.queryParas)) })
      getlistfordispatch(param).then(res => {
        this.listdata = res.data.records
        this.pagetotal = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    save (e) {
      const d = Object.assign({}, e)
      d.child = JSON.stringify(d.child)
      d.imgs = JSON.stringify(d.imgs)
      addfeibiaodingzhi(d).then(res => {
        if (res.code == '200') {
          this.dialog = false
          this.load()
          this.$message.success('操作成功!')
        }
      })
    },
    addactivity () {
      this.title = '新增'
      this.carddata = null
      this.bodydata = []
      this.formData = { id: rowid() }
      this.dialog = true
    },
    handlePictureCardPreview () {

    },
    handleAvatarSuccess (response, file, filelist) {
      this.optionData.img = response.data.path
    },
    handleRemove (file, filelist) {
      if (filelist.length === 0) {
        this.optionData.img = ''
      }
    },
    handleRemove2 (file, filelist) {

    },
    closemodal () {
      this.showModal = false
    },
    loadbody (id) {
      this.bodydata = []
      activityBodyList({ id: id, search: '' }).then(res => {
        if (res.code === '200') {
          this.bodydata = res.data
        }
      })
    },
    editoption (row) {
      this.optionData = Object.assign({}, row)
      if (this.optionData.att) {
        this.optionData.attlist = JSON.parse(this.optionData.att)
      }
      if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
      this.optiontitle = '编辑'
      this.showModal = true
    },
    uperror (err) {
      console.log(err)
    },
    submitoption () {
      this.$refs.option.validate(vali => {
        if (vali) {
          if (this.optionData.attlist) {
            const att = []
            this.optionData.attlist.map(cur => {
              att.push({ name: cur.name, path: cur.path })
            })
            this.optionData.att = JSON.stringify(att)
          }
          console.log(this.optionData)
          if (this.optiontitle === '新增') {
            activityBodyAdd(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          } else if (this.optiontitle === '编辑') {
            activityBodyEdit(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          }
        }
      })
    },
    deleteactivity (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.load()
          }
        })
      })
    },
    deleteoption (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityBodyDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.loadbody(row.activity_id)
          }
        })
      })
    },
    submitactivity () {
      this.$refs.mainform.validate(vali => {
        if (vali) {
          if (this.title === '新增') {
            activityAdd(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          } else if (this.title === '编辑') {
            activityEdit(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          }
        } else {
          console.log('fail')
        }
      })
    },
    crateQrcode (text) {
      this.qr = new QRCode('qrcode', {
        width: 128,
        height: 128, // 高度
        text: text // 二维码内容
      })
    },
    genqrcode (row) {
      console.log(mobileurl)
      this.qrcodedialog = true
      this.$nextTick(() => {
        this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
      })
    },
    qrcodedialogclose () {
      this.$refs.qrcode.innerHTML = ''
    },
    optiondialogclose () {
      this.optionData = {}
      this.imglist = []
      this.fileList = []
    },
    // 预览图片
    handlePreview () {

    },
    filelistchange (file, filelist) {
      console.log(filelist.slice(-3))
    },
    uploadSuccess (req, file, filelist) {
      if (req.code === '200') {
        file.url = req.data.path
      }
    },
    handleChange (file, filelist) {
      this.fileList = filelist.slice(-3)
    }
  },
  mounted () {
    this.load()
  },
  created () {
    find_getbyfind({ code: 'find_state', param: { a: 'dd' } }).then(res => {
      if (res.code == '200') {
        this.dict_state = res.data.data
      }
    })
    find_getbyfind({ code: 'g_designer', param: { a: 'dd' } }).then(res => {
      if (res.code == '200') {
        this.dict_shejishi = res.data.data
      }
    })
  }
}
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    display: flex;
    flex: 1;
    position: relative;
  }
  .tb{
    height: 100% !important;
    position: absolute;
  }

  .el-menu-demo{
    background-color: #F6F8FA;
    border: 1px solid #E6E6E6;
    border-bottom: 0px;
  }
  .el-menu-item{
    height: 40px;
    line-height: 40px;
  }

  @import "../table";
</style>
