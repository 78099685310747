<template>
  <div class="msglist">
    <el-form :inline="true">
      <el-form-item label="标题">
        <el-input/>
      </el-form-item>
      <el-form-item>
        <el-button @click="" icon="el-icon-search" type="primary" @click="handleQuery">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleAddMsg" icon="el-icon-plus" type="primary">新 增</el-button>
      </el-form-item>
    </el-form>
    <div class="tbbox">
      <el-table border :data="data">
        <el-table-column header-align="center" align="center" :width="50" type="index" label="序号"></el-table-column>
        <el-table-column header-align="center" property="theme" show-overflow-tooltip label="标题"></el-table-column>
        <el-table-column header-align="center" property="realname" :width="90" label="发布人"></el-table-column>
        <el-table-column header-align="center" property="makedate" :width="160" :formatter="el_table_datetime_format" label="创建时间"></el-table-column>
        <el-table-column header-align="center" property="notice_object_type" :width="90" label="通告对象"
                         :formatter="formatNoticeObject"></el-table-column>
        <el-table-column header-align="center" property="pubstate" :width="80" label="状态"></el-table-column>
        <el-table-column header-align="center" property="pubdate" :width="160" :formatter="el_table_datetime_format"
                         label="发布时间"></el-table-column>
        <el-table-column header-align="center" align="center" :width="100" label="操作">
          <template slot-scope="scope">
            <span style="cursor: pointer;color: #3e84e9" @click="handleView(scope.row)">查看</span>
            <el-divider direction="vertical"/>
            <span style="cursor: pointer;color: #3e84e9" @click="handleRelease(scope.row)">发布</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <msg-add ref="msgDialog" @confirm="handleConfirm"></msg-add>
  </div>
</template>

<script>
  import msgAdd from './msgAdd'
  import vTable from '../../components/v-table'
  import { noteMsg } from '../../api/common'
  import { el_table_datetime_format } from '../../util/common'

  export default {
    name: '',
    data () {
      return {
        data: [],
        el_table_datetime_format: el_table_datetime_format,
      }
    },
    components: {
      msgAdd,
      vTable
    },
    methods: {
      formatNoticeObject (row, column) {
        switch (row.notice_object_type) {
          case 'any':
            return '全体用户'
            break
          default:
            return '未知'
        }
      },
      handleConfirm () {
        this.loaddata()
      },
      handleView (row) {
        this.$refs.msgDialog.view(row.sguid)
      },
      handleRelease (row) {
        noteMsg.release({ sguid: row.sguid }).then(r => {
          if (r.code == '200') {
            this.$message.success('发布成功')
            this.loaddata()
          }
        })
      },
      handleQuery () {
        this.loaddata()
      },
      handleAddMsg () {
        this.$refs.msgDialog.add()
      },
      loaddata () {
        noteMsg.list().then(r => {
          this.data = r.data
        })
      }
    },
    mounted () {
      this.loaddata()
    }
  }
</script>

<style scoped>
  .msglist {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tbbox {
    flex: 1;
    position: relative;
  }

  .tb {
    position: absolute;
    height: 100% !important;
  }

</style>
