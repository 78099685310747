<template>
  <transition name="opacity-fade">
    <div class="n-view" style="background-color: #F5F6F9;padding: 40px 0px" :style="{'z-index':index}">
      <el-card  style="width: 700px"  class="n-view-card">
        <slot name="header">
              <div class="header">
                  <span class="text">{{title}}</span>
                  <span class="el-icon-close" @click="$emit('close')"/>
              </div>
        </slot>
        <slot/>
      </el-card>
    </div>
  </transition>
</template>
<script>
import { getMaxIndex } from '../util/common'
export default {
  name: 'NewView',
  mounted () {

  },
  data () {
    return {
      index: getMaxIndex()
    }
  },
  props: {
    title: {
      default: '标题'
    }
  }
}
</script>

<style scoped lang="less" type="text/less">
  .opacity-fade-enter-active,
  .opacity-fade-leave-active {
    transition: all 0.2s;
  }
  .opacity-fade-enter,
  .opacity-fade-leave-to {
    opacity: 0;
  }
  /** 容器布局 */
  .n-view {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
  }
  .n-view-card{
    margin: 0 auto;
  }

  .header{
    margin-bottom: 24px;
  .text{
    font-size: 17px;
  }
  .el-icon-close{
    float: right;
    color: #ccc;
    font-size: 26px;
  }
    .el-icon-close:hover{
       cursor: pointer;
       color: red;
    }
  }
  .cusform{
    box-sizing: border-box;
  }

</style>
