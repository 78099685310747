<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" :inline="true" size="small" @submit.native.prevent>
        <el-form-item label="通知标题">
          <el-input v-model="searchdata.theme" @keyup.enter.native="search"  placeholder="通知标题"></el-input>
        </el-form-item>
        <el-form-item label="用户">
          <el-input v-model="searchdata.realname" @keyup.enter.native="search"  placeholder="用户"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="margin-bottom: 24px">
            <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
          </div>
        </el-form-item>

      </el-form>
    </div>
    <div class="tbbox">
      <el-table class="tb"  v-loading="loading" :data="listdata" :height="'100%'" :border="true">
        <el-table-column  type="index" width="50" align="center" label="序号"/>
        <el-table-column header-align="center" :width="250" label="通知标题" prop="theme" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="180" label="用户" prop="realname" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="180" label="查阅时间" prop="read_date" :formatter="el_table_datetime_format" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.currentpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
  import {
    activityAdd,
    activityBodyAdd,
    activityBodyDelete,
    activityBodyEdit,
    activityBodyList,
    activityDelete,
    activityEdit,
    getallfeibiaodingzhi,
    addfeibiaodingzhi,
    cancelfeibiaodingzhi,
    getbuslog,
    feibiaodingzhi,
    find_getbyfind,
    getonefeibiaodingzhi,
    noteMsgUser
  } from '@/api/common'
  import NewView from '@/components/NewView'
  import { getToken, rowid, el_table_datetime_format, dateformat } from '../../util/common'

  import { uploadUrl, mobileurl } from '../../config'

  export default {
    components: {
    },
    created () {
      find_getbyfind({ code: 'find_state', param: { a: 'dd' } }).then(res => {
        if (res.code == '200') {
          this.dict_state = res.data.data
        }
      })
    },
    data () {
      return {
        showOutTimeOnly: false,
        pagetotal: 0,
        dict_state: [],
        value: [],
        queryPage: {},
        queryParas: [
          { field: 'shejishi_name', type: '0', rule: 'like', val: null },
          { field: 'makedate', type: '0', rule: 'ge', val: null },
          { field: 'makedate', type: '0', rule: 'le', val: null },
          { field: 'maker_name', type: '0', rule: 'like', val: null },
          { field: 'blog', type: '0', rule: 'like', val: null },
          { field: 'model', type: '0', rule: 'like', val: null },
          { field: 'size', type: '0', rule: 'like', val: null }
        ],
        dialog_bj: false,
        showViewer: false,
        perviewImage: { showViewer: false, index: 0, urls: [] },
        files: [],
        dialog_fp: false,
        activities: [],
        el_table_datetime_format: el_table_datetime_format,
        handleid: '',
        showhandle: false,
        loading: false,
        searchdata: { theme: '', realname:'', currentpage: 1, size: 100 },
        carddata: {},
        upfile: {
          action: uploadUrl,
          headers: { Authorization: 'Bearer ' + getToken() },
          data: { bizPath: 'vote' }
        },
        imglist: [],
        fileList: [],
        bodydata: [],
        optiontitle: '',
        title: '',
        optionData: {}, // 选项数据
        formData: {}, // 活动数据
        showModal: false,
        dialog: false,
        listdata: []
      }
    },
    methods: {
      handleSizeChange (e) {
        this.searchdata.size = e
        this.load()
      },
      handleCurrentChange (e) {
        this.searchdata.currentpage = e
        this.load()
      },
      handleExportXls (fileName) {
        if (this.value && this.value.length > 1) {
          this.queryParas[1].val = dateformat(this.value[0])
          this.queryParas[2].val = dateformat(this.value[1])
        } else {
          this.queryParas[1].val = null
          this.queryParas[2].val = null
        }

        const param = Object.assign({}, this.searchdata,
          { queryParams: encodeURI(JSON.stringify(this.queryParas)) })

        feibiaodingzhi.exportexcel(param).then(data => {
          if (!data || data.size === 0) {
            this.$message.warning('文件下载失败')
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data]), fileName + '.xls')
          } else {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', '预订单管理.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) // 下载完成移除元素
            window.URL.revokeObjectURL(url) // 释放掉blob对象
          }
        })
      },
      handleCancel (row) {
        this.$confirm('此操作将取消订单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            feibiaodingzhi.fcancel({ id: row.id }).then(r => {
              this.$message.success('取消成功')
              this.load()
            })
          })
      },
      openfile (file, files, index) {
        this.perviewImage.urls = files.map(im => { return this.$options.filters.getImgSrc(file.url) })
        this.perviewImage.index = index
        this.showViewer = true
      },
      showdesign (row) {
        this.$refs.designform.show(row.id, '1')
      },
      openbaojia (row) {
        this.dialog_bj = true
        this.$nextTick(() => {
          const bill = this.$refs.bjbill
          bill.disabled = true
          const load = this.$loading()
          feibiaodingzhi.getbaojiabill({ id: row.id }).then(r => {
            if (r.code == '200') {
              if (r.data.cdsmaster) {
                bill.cdsmaster = r.data.cdsmaster
                bill.cdsdeta00 = r.data.cdsdeta00
              }
            }
            load.close()
          }).catch(er => { load.close() })
        })
      },
      handlesuccess () {
        this.handleclose()
        this.load()
      },
      glog (e) {
        console.log(e)
        this.activities = []
        getbuslog({ rel_id: e.row.id }).then(r => {
          this.activities = r.data
        })
      },
      formatDate (row, column) {
        // 获取单元格数据
        const data = row[column.property]
        if (data == null) {
          return null
        }
        const dt = new Date(data)
        return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
      },
      uploadsj (e) {
        this.handleid = e.row.id
        this.showhandle = true
      },
      handleclose () {
        this.showhandle = false
      },
      search () {
        this.searchdata.currentpage = 1
        this.load()
      },
      cancel (row) {
        this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
          cancelfeibiaodingzhi({ id: row.id }).then(res => {
            if (res.code === '200') {
              this.load()
              this.$message.success('操作成功!')
            }
          })
        })
      },
      viewdetail (row) {
        // const d = Object.assign({}, row)
        // d.child = JSON.parse(d.child)
        // d.imgs = JSON.parse(d.imgs || '[]')
        // this.carddata = d
        // this.title = '详情'
        // this.dialog = true
        const that = this
        getonefeibiaodingzhi({ id: row.id }).then(r => {
          if (r.code == '200') {
            const d = r.data
            d.child = JSON.parse(d.child)
            this.carddata = d
            this.title = '详情'
            this.dialog = true
            this.$nextTick(r => {
              that.$refs.editbill.disabledall = true
            })
          }
        })
      },
      addoption () {
        this.imglist = []
        this.optionData = { activity_id: this.formData.id }
        this.optiontitle = '新增'
        this.showModal = true
      },
      dialogclose () {
        this.formData = {}
        this.bodydata = []
        this.dialog = false
      },
      billstateconvert (row, column) {
        const val = row[column.property]
        if (val == '-1') {
          return '订单取消'
        }
      },
      load () {
        this.loading = true
        const param = Object.assign({}, this.searchdata)

        noteMsgUser.getlist(param).then(res => {

          this.listdata = res.data.records
          this.pagetotal = res.data.total
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      save (e) {
        const d = Object.assign({}, e)
        d.child = JSON.stringify(d.child)
        d.imgs = JSON.stringify(d.imgs)
        addfeibiaodingzhi(d).then(res => {
          if (res.code == '200') {
            this.dialog = false
            this.load()
            this.$message.success('操作成功!')
          }
        })
      },
      addactivity () {
        this.title = '新增'
        this.carddata = null
        this.bodydata = []
        this.formData = { id: rowid() }
        this.dialog = true
      },
      handlePictureCardPreview () {

      },
      handleAvatarSuccess (response, file, filelist) {
        this.optionData.img = response.data.path
      },
      handleRemove (file, filelist) {
        if (filelist.length === 0) {
          this.optionData.img = ''
        }
      },
      handleRemove2 (file, filelist) {

      },
      closemodal () {
        this.showModal = false
      },
      loadbody (id) {
        this.bodydata = []
        activityBodyList({ id: id, search: '' }).then(res => {
          if (res.code === '200') {
            this.bodydata = res.data
          }
        })
      },
      editoption (row) {
        this.optionData = Object.assign({}, row)
        if (this.optionData.att) {
          this.optionData.attlist = JSON.parse(this.optionData.att)
        }
        if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
        this.optiontitle = '编辑'
        this.showModal = true
      },
      uperror (err) {
        console.log(err)
      },
      submitoption () {
        this.$refs.option.validate(vali => {
          if (vali) {
            if (this.optionData.attlist) {
              const att = []
              this.optionData.attlist.map(cur => {
                att.push({ name: cur.name, path: cur.path })
              })
              this.optionData.att = JSON.stringify(att)
            }
            console.log(this.optionData)
            if (this.optiontitle === '新增') {
              activityBodyAdd(this.optionData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.showModal = false
                  this.loadbody(this.optionData.activity_id)
                }
              })
            } else if (this.optiontitle === '编辑') {
              activityBodyEdit(this.optionData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.showModal = false
                  this.loadbody(this.optionData.activity_id)
                }
              })
            }
          }
        })
      },
      deleteactivity (row) {
        this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
          activityDelete({ id: row.id }).then(res => {
            if (res.code === '200') {
              this.$message.success('操作成功！')
              this.load()
            }
          })
        })
      },
      deleteoption (row) {
        this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
          activityBodyDelete({ id: row.id }).then(res => {
            if (res.code === '200') {
              this.$message.success('操作成功！')
              this.loadbody(row.activity_id)
            }
          })
        })
      },
      submitactivity () {
        this.$refs.mainform.validate(vali => {
          if (vali) {
            if (this.title === '新增') {
              activityAdd(this.formData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.dialog = false
                  this.load()
                }
              })
            } else if (this.title === '编辑') {
              activityEdit(this.formData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.dialog = false
                  this.load()
                }
              })
            }
          } else {
            console.log('fail')
          }
        })
      },
      crateQrcode (text) {
        this.qr = new QRCode('qrcode', {
          width: 128,
          height: 128, // 高度
          text: text // 二维码内容
        })
      },
      genqrcode (row) {
        console.log(mobileurl)
        this.qrcodedialog = true
        this.$nextTick(() => {
          this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
        })
      },
      qrcodedialogclose () {
        this.$refs.qrcode.innerHTML = ''
      },
      optiondialogclose () {
        this.optionData = {}
        this.imglist = []
        this.fileList = []
      },
      // 预览图片
      handlePreview () {

      },
      filelistchange (file, filelist) {
        console.log(filelist.slice(-3))
      },
      uploadSuccess (req, file, filelist) {
        if (req.code === '200') {
          file.url = req.data.path
        }
      },
      handleChange (file, filelist) {
        this.fileList = filelist.slice(-3)
      }
    },
    mounted () {
      this.load()
    }

  }
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    display: flex;
    flex: 1;
    position: relative;
  }
  .tb{
    height: 100% !important;
    position: absolute;
  }

  .handle{
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 10;
  }

  .file-item{
    cursor: pointer;
    line-height: 30px;
  }
  .file-item:hover{
    background-color: #eeeeee;
  }

  .billdialog ::v-deep .el-dialog.is-fullscreen{
    display: flex !important;
    flex-direction: column !important;
  }

  .billdialog ::v-deep .el-dialog__body{
    flex: 1 !important;
  }

  @import "../table";
</style>
