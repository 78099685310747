'use strict'

var map = require('lodash/map')

var extensionElementsHelper = require('./ExtensionElementsHelper')

/**
 * Returns true if the attribute 'activiti:asyncBefore' is set
 * to true.
 *
 * @param  {ModdleElement} bo
 *
 * @return {boolean} a boolean value
 */
function isAsyncBefore (bo) {
  return !!(bo.get('activiti:asyncBefore') || bo.get('activiti:async'))
}

module.exports.isAsyncBefore = isAsyncBefore

/**
 * Returns true if the attribute 'activiti:asyncAfter' is set
 * to true.
 *
 * @param  {ModdleElement} bo
 *
 * @return {boolean} a boolean value
 */
function isAsyncAfter (bo) {
  return !!bo.get('activiti:asyncAfter')
}

module.exports.isAsyncAfter = isAsyncAfter

/**
 * Returns true if the attribute 'activiti:exclusive' is set
 * to true.
 *
 * @param  {ModdleElement} bo
 *
 * @return {boolean} a boolean value
 */
function isExclusive (bo) {
  return !!bo.get('activiti:exclusive')
}

module.exports.isExclusive = isExclusive

/**
 * Get first 'activiti:FailedJobRetryTimeCycle' from the business object.
 *
 * @param  {ModdleElement} bo
 *
 * @return {Array<ModdleElement>} a list of 'activiti:FailedJobRetryTimeCycle'
 */
function getFailedJobRetryTimeCycle (bo) {
  return (extensionElementsHelper.getExtensionElements(bo, 'activiti:FailedJobRetryTimeCycle') || [])[0]
}

module.exports.getFailedJobRetryTimeCycle = getFailedJobRetryTimeCycle

/**
 * Removes all existing 'activiti:FailedJobRetryTimeCycle' from the business object
 *
 * @param  {ModdleElement} bo
 *
 * @return {Array<ModdleElement>} a list of 'activiti:FailedJobRetryTimeCycle'
 */
function removeFailedJobRetryTimeCycle (bo, element) {
  var retryTimeCycles = extensionElementsHelper.getExtensionElements(bo, 'activiti:FailedJobRetryTimeCycle')
  return map(retryTimeCycles, function (cycle) {
    return extensionElementsHelper.removeEntry(bo, element, cycle)
  })
}

module.exports.removeFailedJobRetryTimeCycle = removeFailedJobRetryTimeCycle
