<template>
    <div>
      <div v-loading.fullscreen.lock="loading2" element-loading-text="提交中"> </div>
      <!--    按钮组-->
      <div class="title-toolbar">
        <span class="bpm-title-group" @click="test">{{processData.processName}}</span>
        <span class="bpm-title-group">
          <em v-if="processData && processData.identityString">{{processData.taskName ? processData.taskName + '-':''}}{{processData.identityString}}</em>
        </span>
        <div class="bpm-button-group">
          <el-button v-for="(item, index) in processData.buttonList"
            :loading ="item.loading"
            @click="asClick(item.type, index)"
            :disabled="item.disabled"
            :type="item.buttonStyle"
           >{{item.label}}
          </el-button>

<!--          <el-button type="danger" @click="asCancel">-->
<!--            取消-->
<!--          </el-button>-->

<!--          <el-button type="primary" icon="el-icon-edit" @click="submit">提交</el-button>-->
<!--          <el-button type="primary" icon="el-icon-edit" @click="audit">办理</el-button>-->
          <el-button icon="el-icon-close" type="text" @click="close"></el-button>
        </div>
      </div>
      <apply v-if="processData.formCode=='afterSale'"  @close="close" :processData="processData" ref="form" :defaultValue="defaultValue"/>
      <dispose  v-else-if="processData.formCode=='dispose'"  @close="close" :processData="processData" ref="form"/>
      <apply-g-d v-if="processData.formCode=='applyGD'"  @close="close" :processData="processData" ref="form" :defaultValue="defaultValue"/>

      <el-dialog title="选择处理人" :visible.sync="selectDialog" class="selectDialog"
                 :modal-append-to-body="false" >
        <el-form>
          <el-form-item label="下一步处理">
            <el-select v-model="tmpData.nextman" clearable filterable placeholder="请选择" >
              <el-option value="品质主管" />
              <el-option value="售后主管" />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="selectDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmNextMan">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="流程备注" :visible.sync="checkDialog" class="selectDialog"
                 :modal-append-to-body="false" >
        <el-form>
          <el-form-item label="备注">
            <el-input v-model="tmpData.remark"></el-input>            
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="checkDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmChck">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="流程备注" :visible.sync="reDialog" class="selectDialog"
                 :modal-append-to-body="false" >
        <el-form>
          <el-form-item label="备注">
            <el-input v-model="tmpData.remark"></el-input>            
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="reDialog = false">取 消</el-button>
          <el-button type="primary" @click="rejectPreTask">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
  import { process } from '../../api/common'
  import apply from '../../views/dispute/apply'
  import dispose from '../../views/dispute/dispose'
  import applyGD from '../../views/order/gd'

  export default {
    props:['params', 'defaultValue'],
    components:{
      apply,dispose, applyGD
    },
    data(){
      return{
        processData:{},
        selectDialog: false,
        tmpData: {remark: ''},
        _variables:{},
        checkDialog: false,
        loading2: false,
        reDialog: false
      }
    },
    methods:{
      asCancel(){
        this.$confirm('确定要取消吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass:'del-model',
          type: 'warning'
        }).then(() => {
          this.$refs.form.cancel()
        }).catch(() => {

        });
      },
      test(){

      },
      setButton(name, disabled){
        this.processData.buttonList.map(item =>{
          if (item.type == name)
            item['disabled'] = disabled
        })
        this.$forceUpdate()
      },
      asClick(type, index){
        if (type == 'submit'){
          this.$set(this.processData.buttonList[index], 'loading', true)
          this.$refs.form.submit()
          this.$set(this.processData.buttonList[index], 'loading', false)
        }
        if (type == 'save'){
          this.$set(this.processData.buttonList[index], 'loading', true)
          this.$refs.form.save()
          this.$set(this.processData.buttonList[index], 'loading', false)
        }
        if (type == 'audit'){
          this.$set(this.processData.buttonList[index], 'loading', true)
          this.audit(this.$refs.form.getVariables())
          this.$set(this.processData.buttonList[index], 'loading', false)
        }
        if (type == 'rejectPreTask'){
          // this.$set(this.processData.buttonList[index], 'loading', true)
          this.reDialog = true
          // this.rejectPreTask()
          // this.$set(this.processData.buttonList[index], 'loading', false)
        }
        if (type == 'cancel'){

          this.$set(this.processData.buttonList[index], 'loading', true)
          this.$refs.form.cancel()
          this.$set(this.processData.buttonList[index], 'loading', false)
        }
      },


      rejectPreTask(){
        this.loading2 = true
        process.rejectPreTask({task_id: this.processData.taskId, remark: this.tmpData.remark}).then(r=>{
          if (r.code=='200'){
            this.$message.success("操作成功")
            this.close()
          }
          this.loading2 = false
          console.log(r)
        }).catch(err=>{
          this.loading2 = false
        })
      },

      confirmNextMan(){
        if (!this.tmpData.nextman){
          this.$message.warning("请选择下一步处理人")
          return
        }
        this.selectDialog = false
        this._variables = Object.assign(this._variables, this.tmpData)
        this.exAudit()
      },
      
      

      confirmChck(){
        this.checkDialog = false
        this._variables = Object.assign(this._variables, this.tmpData)
        this.exAudit()
      },

      audit(variables){
        this._variables = variables
        if (this.processData.taskDefId == "Activity_0j14jvk"){
            this.selectDialog = true
        }else if(this.processData.taskDefId == "Activity_1i4wtmc"){
            this.checkDialog = true
        }else{
            this.exAudit()
        }
      },
      
      exAudit(){
         this.loading2 = true
          process.completTask({task_id: this.processData.taskId, remark: this.tmpData.remark, variables: this._variables}).then(r=>{
            if (r.code=='200'){
              this.$message.success("操作成功")
              this.close()
            }
            this.loading2 = false
            console.log(r)
          }).catch(err=>{
            this.loading2 = false
          })
      },

      submit(){
        console.log('dfd')
        this.$refs.form.submit()
      },
      close(){
        this.$emit('close')
      }
    },
    mounted(){
      process.getBpmOperate({ id: this.params.id, idType: this.params.idType }).then(r => {
        if (r.code=='200')
          this.processData = r.data
        if (this.processData.taskDefId == "Activity_10t822q" || this.processData.taskDefId == "Activity_1i4wtmc"){
          if(this.processData.buttonList[0].type != "save")
            this.processData.buttonList.unshift({
              "buttonStyle": "primary",
                "icon": "save",
                "label": "保存",
                "order": 0,
                "type": "save"
            })
        }
      })
    }
  }
</script>

<style scoped>

  .title-toolbar{
    height: 56px;
    line-height: 56px;
    padding: 0 10px 0 10px;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 16px;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .bpm-title-group{

    height: 56px;
    line-height: 56px;
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 0 10px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0,0,0,.65);
  }
  .bpm-button-group{
    display: inline-block;
    float: right;
  }

  /deep/ .el-button--primary {
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
  /*  color: #fff;*/
  /*  background-color: #1890ff;*/
  /*  border-color: #1890ff;*/
  /*  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);*/
  /*  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);*/
  /*  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);*/
    border-radius: 4px;
  }

  /deep/ .el-button--primary span {
    margin-left: 6px;
  }

  /deep/ .el-button--text{
    font-size: 20px;
  }

  .el-icon-close{
    font-size: 20px;
  }

  .title-toolbar .bpm-title-group em{
    display: inline-block;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    border: 1px solid #f90;
    color: #f90;
    padding: 0 6px;
    border-radius: 20px;
    font-style: normal;
  }

  .selectDialog /deep/ .el-dialog__body {
    position: static;
  }

</style>
