<template>
  <el-dialog title="设计文件" :visible.sync="visible" fullscreen destroy-on-close style="height: 100%">
    <el-tabs tab-position="left" v-model="tabsindex" style="height: 100%;border-top: 1px solid #eeeeee" v-if="visible">
      <el-tab-pane label="预订单">
        <edit ref="bill" style="width: 800px" :data="carddata" />
      </el-tab-pane>
      <el-tab-pane label="设计图">
       <div style="margin: 10px 0">设计图</div>
        <div style="display: flex;">
          <div v-for="(img,index) in imgs3">
            <vue-hover-mask>
            <el-image :src="$options.filters.getImgSrc(img.url)" style="width:200px"/>
              <template slot="action">
                <i class="el-icon-zoom-in" @click="previewimage(imgs3, index)"/>
              </template>
            </vue-hover-mask>
          </div>
        </div>
        <div style="margin: 10px 0;display: flex;align-items: center">
          <span>生产图</span>
          <el-button type="primary" style="margin-left: 20px" @click="jump20">驳回重新上传生产图</el-button>
        </div>
        <div>
          <div v-for="(img,index) in carddata.pro_imgs">
            <vue-hover-mask>
              <el-image :src="$options.filters.getImgSrc(img.url)" style="width:200px"/>
              <template slot="action">
                <i class="el-icon-zoom-in" @click="previewimage(carddata.pro_imgs, index)"/>
              </template>
            </vue-hover-mask>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index"
                     :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { designflow, feibiaodingzhi, getonefeibiaodingzhi } from '../../api/common'
import { uploadUrl } from '../../config'
import { getToken, rowid } from '../../util/common'
import edit from '../feibiaodingzhi/edit'

export default {
  name: 'designform',
  components: {
    edit
  },
  computed: {
    ...mapGetters(['userinfo'])

  },
  data () {
    return {
      imgs3: [],
      carddata: null,
      tabsindex: '0',
      showViewer: false,
      perviewImage: {
        showViewer: false,
        index: 0,
        urls: []
      },
      current: null,
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      visible: false,
      sguid: '',
      formdata: []
    }
  },
  methods: {
    jump20 () {
      this.$confirm('确认要驳回吗？', '提示', { type: 'info' }).then(() => {
        feibiaodingzhi.jump({ id: this.carddata.id, step: '20' }).then(r => {
          if (r.code == '200') {
            this.$message.success('驳回成功')
          } else {
            this.$message.error(r.message)
          }
        })
      })
    },
    deleteImage2 (imgs, index, item) {
      this.$confirm('确认要删除吗？', '提示', { type: 'info' }).then(() => {
        imgs.splice(index, 1)
        const str = JSON.stringify(imgs)
        designflow.postimg2({
          sguid: item.sguid,
          smainguid: this.sguid,
          imgs2: str
        }).then(r => {
          if (r.code == '200') {
            this.$set(item, 'imgs2', str)
          } else {
            this.$message({
              message: r.message,
              type: 'warning'
            })
          }
        })
      })
    },
    deleteImage (imgs, index, item) {
      this.$confirm('确认要删除吗？', '提示', { type: 'info' }).then(() => {
        imgs.splice(index, 1)
        const str = JSON.stringify(imgs)
        designflow.postimg({
          sguid: item.sguid,
          smainguid: this.sguid,
          imgs: str
        }).then(r => {
          if (r.code == '200') {
            this.$set(item, 'imgs', str)
          } else {
            this.$message({
              message: r.message,
              type: 'warning'
            })
          }
        })
      })
    },
    previewimage (imgs, index) {
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    previewimage2 (imgs, index) {
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    handleincheck (item) {
      item.dc1 = ''
      designflow.incheck({
        sguid: item.sguid,
        dc1: item.dc1
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker1', 'ok')
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleinuncheck (item) {
      if (!item.dc1) {
        this.$message({
          message: '请输入不通过的原因或要修改的内容',
          type: 'warning'
        })
        return
      }
      designflow.inuncheck({
        sguid: item.sguid,
        dc1: item.dc1
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker1', '')
          this.$message({
            message: '驳回成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleoutcheck (item) {
      item.dc2 = ''
      designflow.outcheck({
        sguid: item.sguid,
        dc2: item.dc2
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker2', 'ok')
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleoutuncheck (item) {
      if (!item.dc2) {
        this.$message({
          message: '请输入不通过的原因或要修改的内容',
          type: 'warning'
        })
        return
      }
      designflow.outuncheck({
        sguid: item.sguid,
        dc2: item.dc2
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker2', '')
          this.$message({
            message: '驳回成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handlepost (item) {
      designflow.post({ sguid: item.sguid }).then(r => {
        if (r.code == '200') {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    getimgs (imgs) {
      return JSON.parse(imgs || '[]')
    },
    hanlderupload () {
      this.$refs.fileRefs.handleClick()
    },
    handleAvatarSuccessRelImg (response, file) {
      const imgs = JSON.parse(this.current.imgs2 || '[]')
      imgs.push({
        name: file.name,
        url: response.data.path
      })
      const str = JSON.stringify(imgs)
      designflow.postimg2({
        sguid: this.current.sguid,
        smainguid: this.sguid,
        imgs2: str
      }).then(r => {
        if (r.code == '200') {
          this.$set(this.current, 'imgs2', str)
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleAvatarSuccess (response, file) {
      const imgs = JSON.parse(this.current.imgs || '[]')
      imgs.push({
        name: file.name,
        url: response.data.path
      })
      const str = JSON.stringify(imgs)
      designflow.postimg({
        sguid: this.current.sguid,
        smainguid: this.sguid,
        imgs: str
      }).then(r => {
        if (r.code == '200') {
          this.$set(this.current, 'imgs', str)
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    loaddata (sguid) {
      designflow.getbilldetail({ sguid: sguid }).then(r => {
        if (r.code == '200') {
          if (r.data.length > 0) {
            this.formdata = r.data
          } else {
            this.formdata = [{
              rowno: 1,
              sguid: rowid()
            }]
          }
        }
      })
    },
    show (id, pagindex) {
      const that = this
      getonefeibiaodingzhi({ id: id }).then(r => {
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          d.pro_imgs = JSON.parse(d.pro_imgs || '[]')
          this.carddata = d
          this.visible = true
          this.sguid = id
          this.loaddata(id)
          this.$nextTick(r => {
            that.$refs.bill.disabledall = true
          })
          this.tabsindex = pagindex || '0'
        }
      })
      designflow.getPassIns({ id: id }).then(r => {
        if (r.code == '200') {
          if (typeof (r.data) === 'undefined' || r.data.imgs3 == null || typeof (r.data.imgs3) === 'undefined') {
            if (typeof (r.data) === 'undefined' || r.data.imgs == null || typeof (r.data.imgs) === 'undefined') { this.imgs3 = [] } else { this.imgs3 = JSON.parse(r.data.imgs) }
          } else { this.imgs3 = JSON.parse(r.data.imgs3) }
        }
      })
    }
    // show (sguid) {
    //   this.visible = true
    //   this.sguid = sguid
    //   this.loaddata(sguid)
    // }
  },
  mounted () {

  }
}
</script>

<style scoped>

  .image {
    height: 100px;
    width: 100px;
  }

  .imgbox {
    display: flex;
    flex-direction: row;
  }

  .sheji-message {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding: 5px 0;
  }

  .zgyj {
    margin: 0 50px;
    align-self: flex-end;
  }

  .khyj {
    align-self: flex-end;
  }

  .yj {
    height: 80px;
    width: 200px;
  }

  .sheji-center {

  }

  .title {
    margin: 7px 0;
    font-size: 15px;
    font-weight: 700;
  }

  ::v-deep .el-dialog.is-fullscreen{
    display: flex !important;
    flex-direction: column !important;
  }

   ::v-deep .el-dialog__body{
    flex: 1 !important;
  }

</style>
