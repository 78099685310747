<template>

</template>
<script>
// 基础任务属性组件
export default {

}
</script>
<style>

</style>
