<template>
    <div style="display: flex;flex-direction: column;height: 300px">
      <div>
        <el-form :inline="true" size="mini" :model="search" :show-message="false" @submit.native.prevent>
          <el-form-item :label="h.label" v-for="(h,hindex) in header">
              <el-input v-model="search[h.param]" @keyup.enter.native="query"/>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" icon="el-icon-search" @click="query">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div  style="flex: 1; position: relative">
        <vTable :loading="loading" height="100%" :show-summary="false" style="position: absolute" :columns="columns" :data="data" @row-dbclick="rowdbclick" />
      </div>
    </div>
</template>

<script>
import vTable from '../components/v-table'
import { find_getbyfind } from '../api/common'
import { strToColumns } from '../util/common'
export default {
  name: '',
  components: { vTable },
  data () {
    return {
      search: {},
      columns: [],
      data: [],
      loading: false
    }
  },
  methods: {
    rowdbclick (row, column, event) {
      this.$emit('row-dbclick', row, column, event)
    },
    query () {
      this.load()
    },
    load () {
      this.loading = true
      find_getbyfind({ code: 'order_matsel', param: this.search }).then(r => {
        if (r.code == '200') {
          this.columns = strToColumns(r.data.fieldandname)
          this.data = r.data.data
        }
        this.loading = false
      }).then(err => { this.loading = false })
    }
  },
  mounted () {
    this.load()
  },
  props: {
    code: {
      type: String,
      default: ''
    },
    header: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>
  .el-form-item{
    margin-bottom: 15px;
  }
</style>
