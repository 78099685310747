<template>
    <div>

        <el-button @click="asClick">打开流程</el-button>


        <div   v-for="(item,index) in list">
            <div>id:{{item.id}}</div>
            <div>名称：{{item.name}}</div>
            <div>key:{{item.key}}</div>
            <div>版本：{{item.revision}}</div>

          <div>
            <el-button @click="open(index)">打开</el-button>
          </div>
        </div>


<!--        <el-dialog :visible.sync="designForm" fullscreen>-->
<!--        <flow-design></flow-design>-->
<!--        </el-dialog>-->

      <el-dialog :visible.sync="dialogVisible" fullscreen>
          <test ref="test" :id="id"></test>
      </el-dialog>

<!--      <ProcessDesign :dialogVisible="dialogVisible" :params="params" v-on:handleVisiable="handleVisiable(visible)"></ProcessDesign>-->

    </div>
</template>

<script>
import test from './test'
import {  process } from '../../api/common'

export default {
  name: '',
  components: {
    test
  },
  data () {
    return {
      dialogVisible: false,
      list: [],
      id: null,
      params: {
        row: ''
      }
    }
  },
  mounted(){
    process.getModelList().then(r=>{
      this.list = r.data
    })
  },
  methods: {
    asClick(){
      this.id = null
      this.dialogVisible=true
    },
    open(index){
      this.id = this.list[index].id
      this.dialogVisible=true
      if (this.$refs.test){
        this.$refs.test.getmodel(this.id)
      }
    },
    handleVisiable (visiable) {
      this.dialogVisible = visiable
    }
  }
}
</script>

<style scoped>

  /deep/ .el-dialog.is-fullscreen{
    display: flex;
    flex-direction: column;
  }

 /deep/ .el-dialog__body{
   flex:1;
 }



</style>
