<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="关键字">
              <el-input v-model="searchdata.key" @keyup.enter.native="search"  placeholder="请输入订单编号/客户名称/联系电话"></el-input>
            </el-form-item>
        <el-form-item>
            <div style="margin-bottom: 24px">
              <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
              <el-button type="primary"  icon="el-icon-plus" @click="addactivity">新增</el-button>
            </div>
        </el-form-item>
      </el-form>
    </div>

    <el-menu class="el-menu-demo" mode="horizontal" :default-active="searchdata.type" @select="statechange">
      <el-menu-item index="0">全部预订单</el-menu-item>
      <el-menu-item index="1">未完成预订单</el-menu-item>
      <el-menu-item index="2">待审核图纸</el-menu-item>
      <el-menu-item index="3">待审核报价</el-menu-item>
    </el-menu>

    <div class="tbbox">
      <el-table class="tb"  v-loading="loading" :data="listdata" :height="'1'" :border="true">
        <el-table-column  type="index" width="50" align="center" label="序号"/>
        <el-table-column header-align="center" label="订单状态" :width="150" align="center" prop="blog" show-overflow-tooltip>
          <div slot-scope="scope">
            <el-popover
              placement="left"
              width="160"
              trigger="click">
              <el-timeline style="padding: 0px; max-height: 200px; overflow: auto">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.log_time|dateformat">
                  {{activity.log_content}}
                </el-timeline-item>
              </el-timeline>
              <div slot="reference" style="color: #A52A2A; cursor: pointer" @click="glog(scope)">{{scope.row[scope.column.property]}}</div>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column header-align="center" label="订单编号" :width="120" prop="billnum" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="下单时间" :width="110" prop="makedate"  :formatter="el_table_datetime_format"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="客户名称" :width="110" prop="kehumingcheng" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="联系电话" :width="110" prop="lianxidianhua" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="型号" :width="100" prop="model" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="尺寸" :width="110" prop="size" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="要求完成设计时间" :width="110" prop="yaoqiushejishijian" show-overflow-tooltip :formatter="el_table_datetime_format"></el-table-column>
        <el-table-column header-align="center" label="预计完成设计时间" :width="110" prop="predict_done_date" show-overflow-tooltip :formatter="el_table_datetime_format"></el-table-column>
        <el-table-column header-align="center" label="设计文件" :width="110" align="center" prop="docs" show-overflow-tooltip>
          <div slot-scope="scope">
            <span style="color: #A52A2A; cursor: pointer" @click="showdesign(scope.row)" v-if="scope.row['shejishi'] && Number(scope.row['billstate']) >=10">已出图</span>
          </div>
        </el-table-column>
        <el-table-column header-align="center" label="报价单" prop="baojia_billnum" :width="120" show-overflow-tooltip >
        <template  slot-scope="scope">
          <span v-if="scope.row.billstate >=50" style="color: #A52A2A; cursor: pointer" @click="openbaojia(scope.row)">{{scope.row[scope.column.property]}}</span>
        </template>
        </el-table-column>
        <el-table-column header-align="center" label="说明" prop="shuoming" show-overflow-tooltip></el-table-column>


        <el-table-column header-align="center" fixed="right" :width="searchdata.type > 0 ? 150: 80" label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="viewdetail(scope.row)">订单详情</span>
            <div style="display: inline-block" v-if="searchdata.type=='2'">
              <el-divider direction="vertical"/>
              <span @click="outcheck(scope.row)">图纸审核</span>
            </div>
            <div style="display: inline-block" v-if="searchdata.type=='3'">
              <el-divider direction="vertical"/>
              <span @click="outcheck2(scope.row)">报价审核</span>
            </div>
            <div style="display: inline-block" v-if="searchdata.type=='1'">
            <el-divider direction="vertical"/>
            <span @click="cancel(scope.row)">取消订单</span>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>
    <new-view v-if="dialog" @close="dialogclose" :title="title">
      <edit ref="bill" :data="carddata" @post="save" @cancel="dialogclose"/>
    </new-view>

    <el-dialog title="设计文件" :visible.sync="dialog_fp" width="400px">
      <div>
        <div v-for="(file,findex) in files" class="file-item" @click="openfile(file, files, findex)">
          {{file.name}}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary"  @click="saveshejishi">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog class="billdialog" :destroy-on-close="true" title="报价单" :fullscreen="true"
               :visible.sync="dialog_bj"
               :close-on-press-escape="false"
               width="400px">
      <div slot="title" style="width: calc(100% - 30px)">
        报价单
        <div style="display: inline-block; float: right;right: 20px">
          <img v-if="view" src="../../assets/img/view.png" height="16" @click="showmoneyfield" width="16"/>
          <img v-else src="../../assets/img/noview.png" height="16" @click="showmoneyfield"  width="16"/>
        </div>
      </div>
      <bill :showmoney="view" ref="bjbill"/>
    </el-dialog>
    <el-dialog :close-on-click-modal	="false" append-to-body destroy-on-close :visible.sync="dialog_adv" title="权限">
        <get-adv-auth @result="handleadvauthresult"></get-adv-auth>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index" :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>
    <designCheck ref="designform"></designCheck>
  </div>
</template>

<script>
import {
  activityAdd,
  activityBodyAdd,
  activityBodyDelete,
  activityBodyEdit,
  activityBodyList,
  activityDelete,
  activityEdit,
  getuserallfeibiaodingzhi,
  addfeibiaodingzhi,
  cancelfeibiaodingzhi,
  getbuslog,
  getonefeibiaodingzhi,
  feibiaodingzhi
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, rowid, el_table_datetime_format } from '../../util/common'

import { uploadUrl, mobileurl } from '../../config'
import QRCode from 'qrcodejs2'
import edit from './edit'
import bill from '../quoteprice/bill'
import getAdvAuth from '../../dialogform/getAdvAuth'
import designCheck from './design-check'

export default {
  components: {
    NewView,
    edit,
    bill,
    getAdvAuth,
    designCheck
  },

  data () {
    return {
      pagetotal: 0,
      showViewer: false,
      perviewImage: { showViewer: false, index: 0, urls: [] },
      dialog_adv: false,
      view: false,
      dialog_bj: false,
      dialog_fp: false,
      files: [],
      activities: [],
      el_table_datetime_format: el_table_datetime_format,
      loading: false,
      searchdata: { key: '', type: '0', curpage: 1, size: 100 },
      carddata: {},
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    handleSizeChange (e) {
      this.searchdata.size = e
      this.load()
    },
    handleCurrentChange (e) {
      this.searchdata.curpage = e
      this.load()
    },
    handleadvauthresult (e) {
      this.view = e
      sessionStorage.setItem('showmoney', e)
    },
    showmoneyfield () {
      if (!this.view) {
        this.dialog_adv = true
      }
      if (this.view) {
        this.view = false
        sessionStorage.setItem('showmoney', false)
      }
    },
    openbaojia (row) {

      this.view = sessionStorage.getItem('showmoney') == 'true'
      this.dialog_bj = true
      this.$nextTick(() => {
        const bill = this.$refs.bjbill
        bill.disabled = true
        const load = this.$loading()
        feibiaodingzhi.getbaojiabill({ id: row.id }).then(r => {
          if (r.code == '200') {
            if (r.data.cdsmaster) {
              bill.cdsmaster = r.data.cdsmaster
              bill.cdsdeta00 = r.data.cdsdeta00
            }
          }
          load.close()
        }).catch(er => { load.close() })
      })
    },
    openfile (file, files, index) {
      this.perviewImage.urls = files.map(im => { return this.$options.filters.getImgSrc(file.url) })
      this.perviewImage.index = index
      this.showViewer = true
    },
    saveshejishi () {
      this.dialog_fp = false
    },
    showdesign (row) {
      this.$refs.designform.show(row.id, '1')
    },
    outcheck (row) {
      this.$refs.designform.show(row.id, '1')
    },
    outcheck2 (row) {
      this.$confirm('确认报价通过？', '提示', { type: 'warning' }).then(r => {
        feibiaodingzhi.outcheck2({ id: row.id }).then(r1 => {
          this.load()
        })
      })
    },
    statechange (e) {
      this.searchdata.type = e
      this.search()
    },
    glog (e) {
      this.activities = []
      getbuslog({ rel_id: e.row.id }).then(r => {
        this.activities = r.data
      })
    },
    search () {
      this.searchdata.curpage = 1
      this.load()
    },
    cancel (row) {
      this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
        cancelfeibiaodingzhi({ id: row.id }).then(res => {
          if (res.code == '205') { this.$message.warning(res.message) }
          if (res.code === '200') {
            this.load()
            this.$message.success('操作成功!')
          }
        })
      })
    },
    viewdetail (row) {
      const that = this
      getonefeibiaodingzhi({ id: row.id }).then(r => {
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          this.carddata = d
          this.title = '详情'
          this.dialog = true
          this.$nextTick(r => {
            that.$refs.bill.disabledall = true
          })
        }
      })
      // let d = Object.assign({}, row)
      // d.child = JSON.parse(d.child)
      // this.carddata= d
      // this.title = '详情'
      // this.dialog = true
      // this.$nextTick(r=>{
      //   that.$refs.bill.disabledall=true
      // })
    },
    addoption () {
      this.imglist = []
      this.optionData = { activity_id: this.formData.id }
      this.optiontitle = '新增'
      this.showModal = true
    },
    dialogclose () {
      this.formData = {}
      this.bodydata = []
      this.dialog = false
    },
    billstateconvert (row, column) {
      const val = row[column.property]
      if (val == '-1') {
        return '订单取消'
      }
    },
    load () {
      this.loading = true
      getuserallfeibiaodingzhi(this.searchdata).then(res => {
        this.listdata = res.data.records
        this.pagetotal = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    save (e) {
      const d = Object.assign({}, e)
      d.child = JSON.stringify(d.child)
      d.imgs = JSON.stringify(d.imgs)
      addfeibiaodingzhi(d).then(res => {
        if (res.code == '200') {
          this.dialog = false
          this.load()
          this.$message.success('操作成功!')
        }
      })
    },
    addactivity () {
      this.title = '新增'
      this.carddata = null
      this.bodydata = []
      this.formData = { id: rowid() }
      this.dialog = true
    },
    handlePictureCardPreview () {

    },
    handleAvatarSuccess (response, file, filelist) {
      this.optionData.img = response.data.path
    },
    handleRemove (file, filelist) {
      if (filelist.length === 0) {
        this.optionData.img = ''
      }
    },
    handleRemove2 (file, filelist) {

    },
    closemodal () {
      this.showModal = false
    },
    loadbody (id) {
      this.bodydata = []
      activityBodyList({ id: id, search: '' }).then(res => {
        if (res.code === '200') {
          this.bodydata = res.data
        }
      })
    },
    editoption (row) {
      this.optionData = Object.assign({}, row)
      if (this.optionData.att) {
        this.optionData.attlist = JSON.parse(this.optionData.att)
      }
      if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
      this.optiontitle = '编辑'
      this.showModal = true
    },
    uperror (err) {
      console.log(err)
    },
    submitoption () {
      this.$refs.option.validate(vali => {
        if (vali) {
          if (this.optionData.attlist) {
            const att = []
            this.optionData.attlist.map(cur => {
              att.push({ name: cur.name, path: cur.path })
            })
            this.optionData.att = JSON.stringify(att)
          }
          console.log(this.optionData)
          if (this.optiontitle === '新增') {
            activityBodyAdd(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          } else if (this.optiontitle === '编辑') {
            activityBodyEdit(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          }
        }
      })
    },
    deleteactivity (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.load()
          }
        })
      })
    },
    deleteoption (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityBodyDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.loadbody(row.activity_id)
          }
        })
      })
    },
    submitactivity () {
      this.$refs.mainform.validate(vali => {
        if (vali) {
          if (this.title === '新增') {
            activityAdd(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          } else if (this.title === '编辑') {
            activityEdit(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          }
        } else {
          console.log('fail')
        }
      })
    },
    crateQrcode (text) {
      this.qr = new QRCode('qrcode', {
        width: 128,
        height: 128, // 高度
        text: text // 二维码内容
      })
    },
    genqrcode (row) {
      console.log(mobileurl)
      this.qrcodedialog = true
      this.$nextTick(() => {
        this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
      })
    },
    qrcodedialogclose () {
      this.$refs.qrcode.innerHTML = ''
    },
    optiondialogclose () {
      this.optionData = {}
      this.imglist = []
      this.fileList = []
    },
    // 预览图片
    handlePreview () {

    },
    filelistchange (file, filelist) {
      console.log(filelist.slice(-3))
    },
    uploadSuccess (req, file, filelist) {
      if (req.code === '200') {
        file.url = req.data.path
      }
    },
    handleChange (file, filelist) {
      this.fileList = filelist.slice(-3)
    }
  },
  mounted () {
    this.view = Boolean(sessionStorage.getItem('showmoney'))
    this.load()
  }
}
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    display: flex;
    flex: 1;
    position: relative;
  }
  .tb{
    height: 100% !important;
    position: absolute;
  }

  .el-menu-demo{
    background-color: #F6F8FA;
    border: 1px solid #E6E6E6;
    border-bottom: 0px;
  }
  .el-menu-item{
    height: 40px;
    line-height: 40px;
  }

  .file-item{
    cursor: pointer;
    line-height: 30px;
  }
  .file-item:hover{
    background-color: #eeeeee;
  }

  .billdialog ::v-deep .el-dialog.is-fullscreen{
    display: flex !important;
    flex-direction: column !important;
  }

  .billdialog ::v-deep .el-dialog__body{
    flex: 1 !important;
  }

  @import "../table";
</style>
