<template>
  <el-dialog title="设计文件" :visible.sync="visible" fullscreen destroy-on-close style="height: 100%">
    <el-tabs tab-position="left" v-model="tabsindex" style="height: 100%;border-top: 1px solid #eeeeee" v-if="visible">
      <el-tab-pane label="预订单">
        <edit ref="bill" style="width: 800px" :data="carddata"/>
      </el-tab-pane>
      <el-tab-pane label="设计图">
        <div v-for="(item, index) in formdata" class="sj" style="max-width: 400px">
          <div class="title">第{{item.rowno}}次作图版本 {{item.makedate|dateformat()}}  {{item.last ==1 ? '（已审核，按此图生产）': ''}} </div>
          <div class="sheji-message">
            <div class="sheji-center">
              <div class="imgbox">
                <div v-for="(img, imgindex) in getimgs(item.imgs)" class="img-for">
                  <vue-hover-mask>
                    <el-image fit="cover" class="image" :src="$options.filters.getImgSrc(img.url)"/>
                    <template slot="action">
                      <i class="el-icon-zoom-in" @click="previewimage(getimgs(item.imgs), imgindex)"/>
                    </template>
                  </vue-hover-mask>
                </div>
              </div>
              <div style="display: flex">
                <!--                <el-upload-->
                <!--                  class="upload"-->
                <!--                  ref="fileRefs"-->
                <!--                  multiple-->
                <!--                  accept="image/png,image/jpg,image/jpeg"-->
                <!--                  :action="upfile.action"-->
                <!--                  :headers="upfile.headers"-->
                <!--                  :data="upfile.data"-->
                <!--                  :on-success="handleAvatarSuccess"-->
                <!--                  :show-file-list="false">-->
                <!--                  <el-button type="primary" :disabled="userinfo.usertype!= 0" @click="current = item">上传设计图</el-button>-->
                <!--                </el-upload>-->
                <!--                <el-button style="margin-left: 10px" :disabled="userinfo.usertype!= 0" type="primary"-->
                <!--                           @click="handlepost(item)">提交审核-->
                <!--                </el-button>-->
              </div>
            </div>
            <div class="khyj">
              <span>{{item.dc2}}</span>
              <div class="relimgbox" style="display: flex;">
                <div v-for="(img, imgindex) in getimgs(item.imgs2)" class="img-for">
                  <vue-hover-mask>
                    <el-image fit="cover" class="image" style="height: 66px;width: 66px"
                              :src="$options.filters.getImgSrc(img.url)"/>
                    <template slot="action">
                      <i class="el-icon-zoom-in" @click="previewimage(getimgs(item.imgs2), imgindex)"/>
                    </template>
                  </vue-hover-mask>
                </div>
              </div>
              <!--              <div>客户审核及意见：<span :style="{'color': item.maker2?'#3E84E9':'#ff0000'}">{{item.maker2? '通过':'未通过'}}</span>-->
              <!--              </div>-->
              <!--              <textarea :disabled="userinfo.usertype!= 1" v-model="item.dc2" class="yj"></textarea>-->
              <!--              <div>-->
              <!--                <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="handleoutcheck(item)">通过</el-button>-->
              <!--                <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="handleoutuncheck(item)">不通过-->
              <!--                </el-button>-->
            </div>
            <div v-if="index == formdata.length-1" style="align-self: flex-end;">
              <el-button type="primary" @click="openPassForm(item)">审 核</el-button>
              <el-button @click="openBackForm(item)">退 回</el-button>

              <el-dialog title="审核（请打印图纸签名后上传）" destroy-on-close :modal-append-to-body="false" :visible.sync="passForm">
                <el-upload
                  class="upload"
                  ref="fileRefs"
                  multiple
                  accept="image/png,image/jpg,image/jpeg"
                  :action="upfile.action"
                  :headers="upfile.headers"
                  :data="upfile.data"
                  :on-success="handleAvatarSuccessPassImg"
                  :show-file-list="false">
                  <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="current = item">上传确认图</el-button>
                </el-upload>

                <div class="relimgbox" style="display: flex;">
                  <div v-for="(img4, imgindex4) in passFormData.imgs3" class="img-for">
                    <vue-hover-mask>
                      <el-image fit="cover" class="image" style="height: 66px;width: 66px"  :src="$options.filters.getImgSrc(img4.url)"/>
                      <template slot="action">
                        <i class="el-icon-zoom-in" @click="previewimage(passFormData.imgs3, imgindex4)"/>
                      </template>
                    </vue-hover-mask>
                  </div>
                </div>

                <span slot="footer" class="dialog-footer">
                  <el-button @click="passForm = false">取 消</el-button>
                  <el-button type="primary" @click="handleoutcheck(passFormData)">确 定</el-button>
                 </span>
              </el-dialog>

              <el-dialog title="退回" destroy-on-close	 :modal-append-to-body="false" :visible.sync="backForm">

                <el-input type="textarea" v-model="backFormData.dc2" placeholder="请填写要修改的内容" style="margin-bottom: 10px"></el-input>

                <el-upload  class="upload"  ref="fileRefs" multiple accept="image/png,image/jpg,image/jpeg"
                  :action="upfile.action" :headers="upfile.headers" :data="upfile.data"  :on-success="handleAvatarSuccessRelImg"
                  :show-file-list="false">
                  <el-button type="primary">上传修改附图</el-button>
                </el-upload>

                <div class="relimgbox" style="display: flex;">
                  <div v-for="(img3, imgindex3) in backFormData.imgs2" class="img-for">
                    <vue-hover-mask>
                      <el-image fit="cover" class="image" style="height: 66px;width: 66px"  :src="$options.filters.getImgSrc(img3.url)"/>
                      <template slot="action">
                        <i class="el-icon-zoom-in" @click="previewimage(backFormData.imgs2, imgindex3)"/>
                      </template>
                    </vue-hover-mask>
                  </div>
                </div>

                <span slot="footer" class="dialog-footer">
                <el-button @click="backForm = false">取 消</el-button>
                <el-button type="primary" @click="handleoutuncheck(backFormData)">确 定</el-button>
              </span>
              </el-dialog>
            </div>
          </div>
          <!--            <div class="reimg" style="margin-left: 20px">-->
          <!--              <div>修改附图:</div>-->
          <!--              <el-upload-->
          <!--                class="upload"-->
          <!--                ref="fileRefs"-->
          <!--                multiple-->
          <!--                accept="image/png,image/jpg,image/jpeg"-->
          <!--                :action="upfile.action"-->
          <!--                :headers="upfile.headers"-->
          <!--                :data="upfile.data"-->
          <!--                :on-success="handleAvatarSuccessRelImg"-->
          <!--                :show-file-list="false">-->
          <!--                <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="current = item">上传附图</el-button>-->
          <!--              </el-upload>-->
          <!--            </div>-->
        </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-image-viewer v-if="showViewer"  :zIndex="9999" :initialIndex="perviewImage.index"
                     :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { designflow, feibiaodingzhi, getonefeibiaodingzhi } from '../../api/common'
import { uploadUrl } from '../../config'
import { getToken, rowid } from '../../util/common'
import edit from '../feibiaodingzhi/edit'
import vUpload from '../../components/v-upload'

export default {
  name: 'designform',
  components: {
    edit,
    vUpload
  },
  computed: {
    ...mapGetters(['userinfo'])

  },
  data () {
    return {
      passForm: false,
      passFormData: { sguid: '', dc2: '', imgs3: [] },
      backForm: false,
      backFormData: { sguid: '', dc2: '', imgs2: [] },
      carddata: null,
      tabsindex: '0',
      showViewer: false,
      perviewImage: {
        showViewer: false,
        index: 0,
        urls: []
      },
      current: null,
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      visible: false,
      sguid: '',
      formdata: []
    }
  },
  methods: {
    openBackForm (item) {
      this.backFormData.sguid = item.sguid
      this.backFormData.dc2 = item.dc2
      if (item.imgs2 == null || typeof (item.imgs2) === 'undefined' || item.imgs2 == '') {
        this.backFormData.imgs2 = []
      } else {
        this.backFormData.imgs2 = JSON.parse(item.imgs2)
      }
      this.backForm = true
    },
    openPassForm (item) {
      this.passFormData.sguid = item.sguid
      this.passFormData.dc2 = item.dc2
      if (item.imgs3 == null || typeof (item.imgs3) === 'undefined' || item.imgs3 == '') {
        this.passFormData.imgs3 = []
      } else {
        this.passFormData.imgs3 = JSON.parse(item.imgs3)
      }
      this.passForm = true
    },
    deleteImage2 (imgs, index, item) {
      this.$confirm('确认要删除吗？', '提示', { type: 'info' }).then(() => {
        imgs.splice(index, 1)
        const str = JSON.stringify(imgs)
        designflow.postimg2({
          sguid: item.sguid,
          smainguid: this.sguid,
          imgs2: str
        }).then(r => {
          if (r.code == '200') {
            this.$set(item, 'imgs2', str)
          } else {
            this.$message({
              message: r.message,
              type: 'warning'
            })
          }
        })
      })
    },
    deleteImage (imgs, index, item) {
      this.$confirm('确认要删除吗？', '提示', { type: 'info' }).then(() => {
        imgs.splice(index, 1)
        const str = JSON.stringify(imgs)
        designflow.postimg({
          sguid: item.sguid,
          smainguid: this.sguid,
          imgs: str
        }).then(r => {
          if (r.code == '200') {
            this.$set(item, 'imgs', str)
          } else {
            this.$message({
              message: r.message,
              type: 'warning'
            })
          }
        })
      })
    },
    previewimage (imgs, index) {
      console.log(imgs)
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    previewimage2 (imgs, index) {
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    handleincheck (item) {
      item.dc1 = ''
      designflow.incheck({
        sguid: item.sguid,
        dc1: item.dc1
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker1', 'ok')
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleinuncheck (item) {
      if (!item.dc1) {
        this.$message({
          message: '请输入不通过的原因或要修改的内容',
          type: 'warning'
        })
        return
      }
      designflow.inuncheck({
        sguid: item.sguid,
        dc1: item.dc1
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker1', '')
          this.$message({
            message: '驳回成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleoutcheck (item) {
      if (item.imgs3.length == 0) {
        this.$message({
          message: '请签名图纸上传后再审核',
          type: 'warning'
        })
        return
      }
      item.dc2 = ''
      designflow.outcheck({
        sguid: item.sguid,
        dc2: item.dc2,
        imgs3: JSON.stringify(item.imgs3)
      }).then(r => {
        if (r.code == '200') {
          this.passForm = false
          this.$set(item, 'maker2', 'ok')
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleoutuncheck (item) {
      if (!item.dc2) {
        this.$message({
          message: '请输入不通过的原因或要修改的内容',
          type: 'warning'
        })
        return
      }
      designflow.outuncheck({
        sguid: item.sguid,
        dc2: item.dc2,
        imgs2: JSON.stringify(item.imgs2)
      }).then(r => {
        if (r.code == '200') {
          this.backForm = false
          this.$set(item, 'maker2', '')
          this.$message({
            message: '驳回成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handlepost (item) {
      designflow.post({ sguid: item.sguid }).then(r => {
        if (r.code == '200') {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    getimgs (imgs) {
      return JSON.parse(imgs || '[]')
    },
    hanlderupload () {
      this.$refs.fileRefs.handleClick()
    },
    handleAvatarSuccessPassImg (response, file) {
      this.passFormData.imgs3.push({
        name: file.name,
        url: response.data.path
      })
    },
    handleAvatarSuccessRelImg (response, file) {
      this.backFormData.imgs2.push({
        name: file.name,
        url: response.data.path
      })

      // let str = JSON.stringify(imgs)
      // designflow.postimg2({
      //   sguid: this.current.sguid,
      //   smainguid: this.sguid,
      //   imgs2: str
      // }).then(r => {
      //   if (r.code == '200') {
      //     this.$set(this.current, 'imgs2', str)
      //   } else {
      //     this.$message({
      //       message: r.message,
      //       type: 'warning'
      //     })
      //   }
      // })
    },
    handleAvatarSuccess (response, file) {
      const imgs = JSON.parse(this.current.imgs || '[]')
      imgs.push({
        name: file.name,
        url: response.data.path
      })
      const str = JSON.stringify(imgs)
      designflow.postimg({
        sguid: this.current.sguid,
        smainguid: this.sguid,
        imgs: str
      }).then(r => {
        if (r.code == '200') {
          this.$set(this.current, 'imgs', str)
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    loaddata (sguid) {
      designflow.getbilldetail({ sguid: sguid }).then(r => {
        if (r.code == '200') {
          if (r.data.length > 0) {
            this.formdata = r.data
          } else {
            this.formdata = [{
              rowno: 1,
              sguid: rowid()
            }]
          }
        }
      })
    },
    show (id, pagindex) {
      const that = this
      getonefeibiaodingzhi({ id: id }).then(r => {
        console.log(r)
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          this.carddata = d
          this.visible = true
          this.sguid = id
          this.loaddata(id)
          this.$nextTick(r => {
            that.$refs.bill.disabledall = true
          })
          this.tabsindex = pagindex || '0'
        }
      })
    }
    // show (sguid) {
    //   this.visible = true
    //   this.sguid = sguid
    //   this.loaddata(sguid)
    // }
  },
  mounted () {

  }
}
</script>

<style scoped>

  .image {
    height: 66px;
    width: 66px;
  }

  .imgbox {
    display: flex;
    flex-direction: row;
  }

  .sheji-message {
    display: flex;
    padding: 5px 0;
    flex-direction: column;
  }

  .sj {
    border-bottom: 1px solid #eeeeee;
  }

  .sj:last-child {
    border-bottom: 0px;
  }

  .zgyj {
    margin: 0 50px;
    align-self: flex-end;
  }

  .khyj {
    align-self: flex-end;
  }

  .yj {
    height: 80px;
    width: 200px;
  }

  .sheji-center {

  }

  .title {
    margin: 7px 0;
    font-size: 15px;
    font-weight: 700;
  }

  ::v-deep .el-dialog.is-fullscreen {
    display: flex !important;
    flex-direction: column !important;
  }

  ::v-deep .el-dialog__body {
    flex: 1 !important;
  }

</style>
