<template>
  <el-upload
    ref="vupload"
    multiple
    :action= "action"
    :headers="headers"
    :data="data"
    :file-list="fileList"
    list-type="picture-card"
    :accept="accept"
  >
    <i slot="default" class="el-icon-plus"></i>
    <div slot="file" slot-scope="{file}" style="height: 100%">
      <img
        class="el-upload-list__item-thumbnail"
        :src="geturl(file.url)"
        alt=""
      >
      <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
<!--        <span-->
<!--          v-if="!disabled"-->
<!--          class="el-upload-list__item-delete"-->
<!--          @click="handleDownload(file)"-->
<!--        >-->
<!--          <i class="el-icon-download"></i>-->
<!--        </span>-->
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
    <!--             <div style="height: 100%;display: flex;justify-content: center;flex-direction: column">-->
    <!--               <i class="el-icon-plus"></i>-->
    <!--               <span>上传凭证</span>-->
    <!--             </div>-->
  </el-upload>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {
    uploadFiles () {
      return this.$refs.vupload.uploadFiles
    }
  },
  methods: {
    handlePreview(file) {
      this.onPreview && this.onPreview(file);
    },
    geturl (val) {
      if (val == null || val.length == 0) { return '' }
      if (val.substring(0, 10) == 'blob:http:') { return val } else { return this.host + val }
    },
    handlePictureCardPreview (file) {
      this.$emit("on-preview", file)
    },
    handleRemove (file) {
      const index = this.$refs.vupload.uploadFiles.findIndex((currValue, index) => {
        if (currValue.uid == file.uid) {
          return true
        }
      })
      if (index > -1) {
        this.$refs.vupload.uploadFiles.splice(index, 1)
      }
    }
  },
  props: {
    disabled:{
      type: Boolean,
      default: false
    },
    host: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: '#'
    },
    headers: {
      type: Object,
      default: {}
    },
    data: {
      type: Object,
      default: {}
    },
    fileList: {
      type: Array,
      default: () => { return [] }
    },
    accept: {
      type: String,
      default: ''
    },
    onPreview: {
      type: Function,
      default: function() {}
    },
  }

}
</script>

<style scoped>

</style>
