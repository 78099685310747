<template>
  <div class="container">
    <div class="top-bar">
      <el-button type="primary" @click="add_dict">新增</el-button>
    </div>
    <!--表格-->
    <el-table :data="dictdata" border>
      <el-table-column label="编码" prop="code"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="操作">
        <div class="commanddiv" slot-scope="scope">
          <span @click="edit_dict(scope.row)">编辑</span>
          <el-divider direction="vertical"/>
          <span @click="config_dict(scope.row)">配置</span>
          <el-divider direction="vertical"/>
          <span @click="delete_dict(scope.row)">删除</span>
        </div>
      </el-table-column>
    </el-table>
    <!--侧边明细-->
    <el-drawer  title="配置" :visible.sync="drawer_config" size="500px">
      <div class="top-bar">
          <el-button type="primary" @click="add_detail">新增</el-button>
      </div>
      <el-table border :data="detaildata">
        <el-table-column label="编码" prop="code"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="操作"  >
          <div class="commanddiv" slot-scope="scope">
            <span @click="edit_detail(scope.row)">编辑</span>
            <el-divider direction="vertical"/>
            <span @click="delete_detail(scope.row)">删除</span>
          </div>
        </el-table-column>
      </el-table>
    </el-drawer>
    <!--新增、编辑弹窗-->
    <el-dialog :close-on-click-modal="false"  :visible.sync="dialog"  :title="dict_dialog_title" width="40%">
      <el-form :model="dictformdata" ref="dictform" :rules="dict_rules" label-width="80px">
        <el-row>
        <el-col :span="22">
        <el-form-item label="编码" prop="code">
          <el-input v-model="dictformdata.code"/>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="dictformdata.name"/>
        </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="dialog=false">关闭</el-button>
            <el-button type="primary" @click="dict_confirm">确认</el-button>
          </el-form-item>
        </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!--明细新增、编辑弹窗-->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialog_detail" :title="detail_dialog_title" width="500px">
      <el-form :model="detailformdata" :rules="detail_rules" ref="detailform" label-width="80px">
          <el-col :span="22" v-for="(col,index) in detailfields" :key="index">
            <el-form-item :label= "col.name" :prop="col.field">
              <el-input v-model="detailformdata[col.field]"/>
            </el-form-item>
          </el-col>
          <div style="text-align: right">
            <el-button @click="dialog_detail=false">关闭</el-button>
            <el-button type="primary" @click="detail_confirm">确认</el-button>
          </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { itemListAdd, itemListDelete, itemListEdit, itemListPlist, itemListSlist } from '../../api/common'

export default {
  name: '',
  data () {
    return {
      currentRow: {},
      dialog_detail: false,
      detail_dialog_title: '',
      detailformdata: {},
      dictformdata: {},
      dict_dialog_title: '',
      dialog: false,
      drawer_config: false,
      dictdata: [],
      detaildata: [],
      detailfields: [
        { field: 'code', name: '编码' },
        { field: 'name', name: '名称' },
        { field: 'sort', name: '排序' },
        { field: 'remark', name: '备注' }
      ],
      dict_rules: {
        code: [
          { required: true, message: '请输入编码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      },
      detail_rules: {
        code: [
          { required: true, message: '请输入编码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.loaddict()
  },
  methods: {
    config_dict (row) {
      this.currentRow = row
      this.loaddictdetail()
      this.drawer_config = true
    },
    add_dict () {
      this.dictformdata = { pid: 0 }
      this.dict_dialog_title = '新增'
      this.dialog = true
    },
    edit_dict (row) {
      this.dictformdata = Object.assign({}, row)
      this.dict_dialog_title = '编辑'
      this.dialog = true
    },
    loaddict () {
      itemListPlist().then(res => {
        if (res.code === '200') {
          this.dictdata = res.data
        }
      })
    },
    loaddictdetail () {
      itemListSlist({ pid: this.currentRow.id }).then(res => {
        if (res.code === '200') {
          this.detaildata = res.data
        }
      })
    },
    dict_confirm () {
      this.$refs.dictform.validate((valid) => {
        if (valid) {
          if (this.dict_dialog_title === '新增') {
            itemListAdd(this.dictformdata).then((res) => {
              if (res.code === '200') {
                this.dialog = false
                this.$message.success(res.message)
                this.loaddict()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            itemListEdit(this.dictformdata).then((res) => {
              if (res.code === '200') {
                this.dialog = false
                this.$message.success(res.message)
                this.loaddict()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    detail_confirm () {
      this.$refs.detailform.validate((valid) => {
        if (valid) {
          if (this.detail_dialog_title === '新增') {
            itemListAdd(this.detailformdata).then((res) => {
              if (res.code === '200') {
                this.dialog_detail = false
                this.$message.success(res.message)
                this.loaddictdetail()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            itemListEdit(this.detailformdata).then((res) => {
              if (res.code === '200') {
                this.dialog_detail = false
                this.$message.success(res.message)
                this.loaddictdetail()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    delete_dict (row) {
      this.$confirm('确认删除吗？', '提示').then(() => {
        itemListDelete({ id: row.id }).then((res) => {
          if (res.code === '200') {
            this.dialog = false
            this.$message.success(res.message)
            this.loaddict()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    delete_detail (row) {
      this.$confirm('确认删除吗？', '提示').then(() => {
        itemListDelete({ id: row.id }).then((res) => {
          if (res.code === '200') {
            this.dialog_detail = false
            this.$message.success(res.message)
            this.loaddictdetail()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    add_detail () {
      if (this.currentRow.id !== undefined) {
        this.detailformdata = { pid: this.currentRow.id }
        this.detail_dialog_title = '新增'
        this.dialog_detail = true
      }
    },
    edit_detail (row) {
      if (this.currentRow.id !== undefined) {
        this.detailformdata = Object.assign({}, row)
        this.detail_dialog_title = '编辑'
        this.dialog_detail = true
      }
    }
  }
}
</script>

<style scoped lang="scss" type="text/scss">
  .container{
    background-color: white;
  }

  ::v-deep :focus{
    outline: 0px !important;
  }
  .commanddiv span{
    color: $color-primary;
  }
  .commanddiv span:hover{
    cursor: pointer;
  }
  .top-bar .el-button{
    margin: 5px 10px;
    width: 80px;
  }

  @import "../table.scss";

</style>
