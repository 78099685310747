<template>
    <div class="stocksearch">
      <div class="header">
        <el-form :model="searchdata" label-width="80px" size="small" @submit.native.prevent>
        <el-row :gutter="20">
          <el-col :span="6" >
              <el-form-item label="产品名称">
                <el-input v-model="searchdata.mname" @keyup.enter.native="search"  placeholder="请输入产品名称"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="3">
            <div style="margin-bottom: 24px">
            <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
            </div>
          </el-col>
        </el-row>
        </el-form>
      </div>
      <div class="tbbox">
        <el-table v-loading="loading" class="tb" :height="listdata.length > 0 ? 'auto' : '100%'" :data="listdata" :border="true">
          <el-table-column header-align="center" label="仓库名称" prop="whname" show-overflow-tooltip></el-table-column>
          <el-table-column header-align="center" label="货品名称" prop="mname" show-overflow-tooltip></el-table-column>
          <el-table-column header-align="center" label="材质" prop="fc2" show-overflow-tooltip></el-table-column>
          <el-table-column header-align="center" label="规格" prop="model" show-overflow-tooltip></el-table-column>
          <el-table-column header-align="center" label="数量" prop="endquan" show-overflow-tooltip></el-table-column>
          <el-table-column header-align="center" label="类型" prop="dc6" show-overflow-tooltip></el-table-column>
          <el-table-column header-align="center" label="做工信息" prop="dc1" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </div>
</template>

<script>
import { getallstock } from '../../api/common'
export default {
  name: 'stocksearch',
  data () {
    return {
      loading: false,
      searchdata: { mname: '' },
      listdata: []
    }
  },
  mounted () {
    this.loaddata()
  },
  methods: {
    loaddata () {
      this.loading = true
      getallstock(this.searchdata).then(res => {
        this.listdata = res.data
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    search () {
      this.loaddata()
    }
  }
}
</script>

<style scoped lang="scss" type="text/scss">

  .stocksearch{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    display: flex;
    flex: 1;
  }

  .tb{
    height: 90% !important;
    position: absolute;
  }

  @import "../table";
</style>
