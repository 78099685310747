<template>
<div>
  <div class="box">
    <fieldset class="fieldset" :disabled="iseditable">
      <el-form size="mini" label-width="200px" :model="formData" ref="formData">
        <el-form-item label="产品型号：" prop="mname" :rules="[{ required: true, message: '产品型号不能为空', trigger: 'blur'}]">
          <el-input style="max-width: 200px" placeholder="产品型号" v-model="formData.mname"/>
        </el-form-item>
        <el-form-item label="订单号：" prop="erp_billnum" :rules="[{ required: true, message: '订单号不能为空', trigger: 'blur'}]">
          <el-input style="max-width: 200px" placeholder="订单号" v-model="formData.erp_billnum"/>
        </el-form-item>
<!--        <el-form-item label="尺寸：" prop="model" :rules="[{ required: true, message: '尺寸不能为空', trigger: 'blur'}]">-->
<!--          <el-input style="max-width: 200px" placeholder="尺寸" v-model="formData.model"/>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="材质：">-->
<!--          <el-input style="max-width: 200px" placeholder="材质" v-model="formData.fc3"/>-->
<!--        </el-form-item>-->
        <el-form-item label="做工信息：">
          <el-input class="input" type="textarea"  placeholder="做工信息" v-model="formData.dc1"/>
        </el-form-item>
        <el-form-item label="颜色：">
          <el-input style="max-width: 200px" placeholder="颜色" v-model="formData.fc1"/>
        </el-form-item>
        <el-form-item label="数量：" prop="quan" :rules="[{ required: true, message: '数量不能为空', trigger: 'blur'}]">
          <el-input type="number" style="max-width: 100px" placeholder="数量" v-model="formData.quan"/>
        </el-form-item>
        <el-form-item label="问题描述：" prop="issue" :rules="[{ required: true, message: '问题描述不能为空', trigger: 'blur'}]">
          <el-input class="input" placeholder="问题描述" :rows="2" type="textarea" v-model="formData.issue"/>
        </el-form-item>
        <el-form-item label="要求说明：">
          <el-input class="input" placeholder="要求说明" :rows="2" type="textarea" v-model="formData.require"/>
        </el-form-item>
        <el-form-item label="需工厂如何协助处理说明：" prop="dispose" :rules="[{ required: true, message: '需工厂如何协助处理说明不能为空'}]">
          <el-input class="input" placeholder="需工厂如何协助处理说明" :rows="2" type="textarea" v-model="formData.dispose"/>
        </el-form-item>
        <el-form-item label="上传图片：">
          <v-upload :host="$options.filters.getImgSrc('')"
                    :action="upfile.action"
                    :headers="upfile.headers"
                    :data="upfile.data"
                    accept=".jpg,.jpeg,.png,.gif,.bmp"
                    :file-list="tmpimgs"
                    ref="upload"
                    :disabled="iseditable"
                    :on-preview="handlePreview"
          >
          </v-upload>
        </el-form-item>
      </el-form>
    </fieldset>

    <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index"
                     :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>


  </div>
    <br>
    <div class="box" v-if="step==1">
      <fieldset class="fieldset" :disabled="iseditable">
        <el-form label-width="200px" size="mini" :model="formData2">
<!--          <el-form-item label="经销商名称：">-->
<!--            <el-col :span="10">-->
<!--              <span>{{formData2.cusname}}</span>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
          <el-form-item label="责任判定：">
            <el-col :span="10">
              <span>{{formData2.def}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="售后类型：">
            <el-col :span="10">
              <span>{{formData2.type}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="售后人员处理方案建议：">
            <el-col :span="10">
              <span>{{formData2.project}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="最终结果：">
            <el-col :span="10">
              <span>{{formData2.result}}</span>
            </el-col>
          </el-form-item>
          <el-form-item label="改进后图片：">
              <div style="line-height:20px">
                <ul style="margin:0;display:inline;vertical-align:top;padding:0;list-style:none">
                  <li v-for="(pc, ipc) in arrImg1" style="height:88px;width:88px;overflow:hidden;background-color:#ffffff;border:1px solid; #c0ccda; border-radius:6px;box-sizing:border-box;margin:0 8px 8px 0;display:inline-block">
                    <img @click="handlePreview1(ipc)" :src="$options.filters.getImgSrc(pc.url)" style="width:100%;height:100%"></img>
                  </li>
                </ul>
              </div>
          </el-form-item>
        </el-form>
      </fieldset>
    </div>
    <br>

    

</div>

</template>

<script>
  import vUpload from '../../components/v-upload'
  import { uploadUrl } from '../../config'
  import { getToken, rowid } from '../../util/common'
  import { postsaleshead, process, afterprocessing } from '../../api/common'
  import { mapGetters } from 'vuex'

  export default {
    mounted () {
      if (this.processData && this.processData.formId) {
        postsaleshead.getbill({ sguid: this.processData.formId }).then(r => {
          if (r.code == '200') {
            this.formData = r.data
            this.tmpimgs = JSON.parse(r.data.imgs)
          }
        })
        afterprocessing.getInstance({sguid: this.processData.formId}).then(r=>{
          if (r.code=='200'){
            this.formData2 = r.data || {}
            this.arrImg1 = JSON.parse(this.formData2.img1)
          }
        })
        this.step = 1
        return
      }
      this.step = 0
      this.formData.sguid = rowid()
      this.formData.cuscode = this.userinfo.id
      this.formData.cusname = this.userinfo.realname
      if (this.defaultValue){
        this.$set(this.formData,'dc1',this.defaultValue.dc1)
        this.$set(this.formData,'fc1',this.defaultValue.fc1)
        this.$set(this.formData,'mname',this.defaultValue.mname)
        this.$set(this.formData,'erp_billnum',this.defaultValue.billnum)
        this.$set(this.formData,'quan',this.defaultValue.quanissue)
        this.$set(this.formData,'rc1',this.defaultValue.sguidsub)
      }
    },
    props: ['processData','defaultValue'],
    components: {
      vUpload
    },
    computed: {
      ...mapGetters(['userinfo']),
      iseditable () {
        if (this.processData) {
          if (this.processData.editableList && this.processData.editableList.length > 0) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      },
      // getImg1(){
      //   return this.arrImg1.map((item)=>{
      //     item.url = this.$options.filters.getImgSrc(item.url);
      //     return item
      //   })
      // },
    },
    data () {
      return {
        perviewImage:[],
        showViewer: false,
        upfile: {
          action: uploadUrl,
          headers: { Authorization: 'Bearer ' + getToken() },
          data: { bizPath: 'vote' }
        },
        formData: {
          sguid: null,
          mname: '',
          billnum: '',
          fc3: '',
          model: '',
          quan: 1,
          issue: '',
          require: '',
          dispose: '',
          imgs: [],
          cuscode: null,
          cusname: null,
          billdate: null
        },
        tmpimgs: [],
        formData2:{},
        arrImg1: [],
        step: 0
      }
    },
    methods: {
      handlePreview1(index){
        console.log(this.arrImg1)
        this.previewimage(this.arrImg1, index)
      },
      handleError () {

      },
      handleSuccess (response, file, fileList) {

      },
      submit () {
        let _this = this
        this.$refs.formData.validate((valid) => {
          if (valid) {
            const upload = this.$refs.upload
            const imgs = []
            upload.uploadFiles.forEach(value => {
              if (value.response) {
                imgs.push({ url: value.response.data.path })
              } else {
                imgs.push({ url: value.url })
              }
            })

            if (imgs.length == 0){
              _this.$message.warning('请上传图片')
              return
            }

            this.formData.imgs = JSON.stringify(imgs)
            //保存

            postsaleshead.savebill(this.formData).then(res => {
              if (res.code == '200') {
                //启动流程
                process.startAndComplet({
                  pro_def_id: this.processData.processDefId,
                  bus_id: this.formData.sguid
                }).then(r => {
                  if (r.code == '200') {
                    _this.$message.success('操作成功')
                    _this.$emit('close')
                  }
                })
              }
            })
          } else {

          }
        })
      },
      handleRemove (file, fileList) {
        console.log(file, fileList)
      },
      handlePreview (file) {
        const upload = this.$refs.upload
        const i = upload.uploadFiles.findIndex(item => item.uid === file.uid)
        const imgs = []
        upload.uploadFiles.forEach(value => {
          if (value.response) {
            imgs.push({ url: value.response.data.path })
          } else {
            imgs.push({ url: value.url })
          }
        })
        this.previewimage(imgs,i)
      },
      previewimage (imgs, index) {
        this.perviewImage.urls = imgs.map(im => {
          return this.$options.filters.getImgSrc(im.url)
        })
        this.perviewImage.index = index
        this.showViewer = true
      },

    }
  }
</script>

<style scoped>

  div {
    line-height: 20px;
  }

  .input {
    max-width: 600px;
  }

  .box {
    /*border: 1px solid #ccc;*/
    margin: 0px 20px;
    padding-top: 35px;
    padding-bottom: 50px;
    padding-right: 100px;
    background-color: white;
    border: 1px solid rgb(232, 232, 232);;
  }

  .post_button {
    border: none;
    height: 32px;
    line-height: 32px;
    color: #fff;
    background: #dd2727;
    font-size: 16px;
    min-width: 200px;
    border-radius: 4px;
    padding: 0 10px;
    margin-left: 200px;
  }

  /deep/ .el-upload--picture-card {
    height: 88px;
    width: 88px;
    line-height: 88px;
  }

  /deep/ .el-upload-list__item {
    height: 88px;
    width: 88px;
    transition: none !important;
  }

  .fieldset {
    border: 0px;
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  /deep/ input[type='number'] {
    -moz-appearance: textfield !important;
  }


</style>
