<template>
  <div class="role-box">
    <div class="nav-role">
      <!--    新建按钮-->
      <div class="button-content">
        <el-button type="primary" @click="newRoleBtn">新建角色</el-button>
      </div>
      <!--    角色列表-->
      <div class="role-nav-box">
         <div v-for="(item,i) in roleLists" :key="i" class="item-list"
              @click="roleListClick(item)"
              :class="{'item-list-hover' : item.role_id == roleActive.role_id}">
           <span>{{item.role_name}}</span>
         </div>
      </div>
    </div>
    <!--    tab-->
    <div class="content-box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="角色用户" name="user">
          <!--        关联按钮-->
          <div class="content-table-header">
            <div class="content-table-header-reminder"></div>
            <el-button type="primary">关联用户</el-button>
          </div>
          <!--        用户列表-->
          <div>
            <el-table :data="userData" border>
              <el-table-column v-for="(col,index) in userColumns" :key="index" :label="col.caption" :prop="col.field" show-overflow-tooltip>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <i class="el-icon-delete" style="color: #3e84e9" @click="handleDelete(scope.row)"/>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="角色权限" name="role">
          <div class="role-auth-box">
            <el-button type="primary" class="role-auth-save-btn">保存</el-button>
            <el-tabs v-model="authActive">
              <el-tab-pane label="模块功能" name="modelauth">

              </el-tab-pane>
              <el-tab-pane label="数据权限" name="dataauth" :lazy="true">

              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--  新增角色-->
    <el-dialog :title="roleTitle" :visible.sync="newRoleVisible" width="30%" :before-close="newRoleClose">
      <label class="label-title">角色名称</label>
      <el-input v-model="role.title" class="input-role"></el-input>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="newRoleSubmit">确 定</el-button>
          <el-button @click="newRoleClose">取 消</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { roleAdd, roleList, getUserByRoleId } from '../../api/common'
import { rowid } from '../../util/common'

export default {
  name: '',
  data () {
    return {
      page: {
        pagetotal: 0,
        currentpage: 1,
        size: 100
      },
      roleTitle: '',
      newRoleVisible: false,
      role: {}, // 操作角色的框 关联的信息
      activeName: 'user',
      authActive: 'modelauth',
      navLoading: false,
      roleLists: [],
      roleActive: {},
      userColumns: [
        {
          field: 'realname',
          caption: '姓名'
        }, {
          field: 'dept_name',
          caption: '部门'
        }, {
          field: 'post',
          caption: '职位'
        }, {
          field: 'role_name',
          caption: '角色'
        }
      ],
      userData: []
    }
  },
  methods: {
    roleChange (e) {
      console.log(e)
    },
    // 获取用户数据
    getuserdata (roleid) {
      getUserByRoleId({ param: { role_id: roleid }, currentpage: this.page.currentpage, size: this.page.size }).then((res) => {
        this.userData = res.data.records
        this.page.pagetotal = res.data.total
      })
    },
    // 新建角色
    newRoleBtn () {
      this.roleTitle = '新建角色'
      this.newRoleVisible = true
      this.role = {}
    },
    // 关闭dialog
    newRoleClose () {
      this.newRoleVisible = false
    },
    // 新建角色click
    newRoleSubmit () {
      if (!this.role.title) {
        this.$message.error('角色名称不能为空')
      } else {
        if (this.roleTitle === '新建角色') {
          roleAdd({
            role_id: rowid(),
            role_name: this.role.title
          }).then(res => {
            this.getRoleList()
            this.$message.success('添加成功')
            this.newRoleClose()
          })
        } else {
          // roleUpdate({
          //   roleName: this.role.title,
          //   roleType: this.role.pid,
          //   roleId: this.role.id
          // }).then(res => {
          //   this.getRoleList()
          //   this.$message.success('编辑成功')
          //   this.newRoleClose()
          // })
        }
      }
    },
    // 获取角色列表
    getRoleList () {
      this.navLoading = true
      roleList().then(res => {
        this.roleLists = res.data
        this.navLoading = false
      }).catch(() => {
        this.navLoading = false
      })
    },
    // 角色列表点击事件
    roleListClick (val) {
      this.roleActive = val
      this.getuserdata(val.role_id)
      // 点击角色 复制权限 用于编辑操作
      // this.getRoleRulesInfo(val)
      // this.getUserListWithRole(this.roleActive)
      // this.getUserRulesWithRole(this.roleActive)
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除是否继续?', '提示')
    }
  },
  mounted () {
    this.getRoleList()
  }
}
</script>

<style scoped lang="less" type="text/less">

  .role-box {
    height: 100%;
    overflow: hidden;
    position: relative;

    .nav-role {
      width: 200px;
      background: #fff;
      border: 1px solid #e6e6e6;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .button-content {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .role-nav-box .item-list {
    color: #333;
    font-size: 13px;
    padding-left: 30px;
    height: 40px;
    line-height: 40px;
    border-right: 2px solid transparent;
    cursor: pointer;
    position: relative;

    .icon-close {
      position: absolute;
      top: 0;
      right: 8px;
      z-index: 1;
      display: none;
    }
  }

  .role-nav-box .item-list:hover {
    background: #ebf3ff;
    border-right-color: #46cdcf;

    .icon-close {
      display: block;
    }
  }

  .item-list-hover {
    background: #ebf3ff;
    border-right: 2px solid #46cdcf;
  }

  .content-box {
    background: #fff;
    border: 1px solid #e6e6e6;
    margin-left: 215px;
    height: 100%;
    overflow: hidden;
    padding-top: 10px;
    position: relative;
  }

  /*tab*/
  .el-tabs /deep/ .el-tabs__header {
    padding: 0 17px;
    margin: 0 0 15px !important;
  }

  /*tab*/
  .el-tabs /deep/ .el-tabs__item {
    font-size: 13px !important;
    height: 40px !important;
    line-height: 40px !important;
  }

  .content-table-header {
    padding: 0 15px 5px;
    display: flex;

    .content-table-header-reminder {
      flex: 1;
      margin-right: 5px;
    }
  }

  .role-auth-box {
    position: relative;
  }

  .role-auth-save-btn {
    text-align: right;
    padding: 10px 30px;
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 3;
  }

  .role-nav-box .item-list {
    color: #333;
    font-size: 13px;
    padding-left: 30px;
    height: 40px;
    line-height: 40px;
    border-right: 2px solid transparent;
    cursor: pointer;
    position: relative;
    .icon-close {
      position: absolute;
      top: 0;
      right: 8px;
      z-index: 1;
      display: none;
    }
  }
  @import '../table.scss';

</style>
