<template>
  <div class="main">
    <el-form :inline="true">
      <el-form-item label="订单编号">
        <el-input v-model="a" placeholder="订单编号"/>
      </el-form-item>
      <el-form-item label="产品型号">
        <el-input v-model="b" placeholder="产品型号"/>
      </el-form-item>
      <el-form-item>
        <el-button @click="" icon="el-icon-search" type="primary" @click="handleQuery">查 询</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 15px">
<!--      <el-form-item>-->
<!--        <el-button @click="handleAddMsg" icon="el-icon-plus" type="primary">新 增</el-button>-->
<!--      </el-form-item>-->
    </div>
    <div class="tbbox">
      <el-table border :data="data" :row-style="{height: '38px'}" :header-cell-style="{background: '#F6F8FA'}" height="100%" :cell-style="changeCellStyle">
        <el-table-column header-align="center" align="center" :width="50" type="index" label="序号"></el-table-column>
        <el-table-column header-align="center" property="bpmn_step" label="状态" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="newbillstate" label="生产状态" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="billdate" :width="120" :formatter="el_table_datetime_format" label="单据日期"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="billnum" :width="120" label="售后申请编号"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="erp_billnum" :width="120" label="原订单编号"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="mname" label="产品型号" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="dc1" label="做工信息" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="fc1" label="颜色" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="quan" label="数量"></el-table-column>
        <el-table-column header-align="center" property="issue" label="问题描述" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="require" label="要求说明" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="dispose" label="处理说明" show-overflow-tooltip></el-table-column>

        <el-table-column  header-align="center" align="center" :width="80" label="操作" fixed="right">
          <template  slot-scope="scope">
            <span style="cursor: pointer;color: #3e84e9" @click="handleView(scope.row)">打开</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
        <el-dialog class="billdialog" :destroy-on-close="true" title="售后申请" :fullscreen="true"
                   :visible.sync="showEditForm"
                   :close-on-press-escape="false"
                   width="400px">
            <apply ref="formApply" @done="loaddata"/>
        </el-dialog>

        <baseFlowDialog :visible.sync="formVisible" @close="baseFormClose" :params="formParams"></baseFlowDialog>
  </div>
</template>

<script>
import bill from './bill'
import vTable from '../../components/v-table'
import apply from '../dispute/apply'
import { postsaleshead, process } from '../../api/common'
import { el_table_datetime_format, rowid } from '../../util/common'
import { mapGetters } from 'vuex'
import baseFlowDialog from '../../components/baseFlowDialog'

export default {
  name: '',
  data () {
    return {
      formVisible: false,
      formParams: {},
      a: '',
      b: '',
      showEditForm: false,
      data: [],
      el_table_datetime_format: el_table_datetime_format
    }
  },
  computed: {
    ...mapGetters(['userinfo'])
  },
  components: {
    bill,
    vTable,
    apply,baseFlowDialog
  },
  methods: {
    baseFormClose(){
      this.formVisible=false
      this.loaddata()
    },
    changeCellStyle (row, column, rowIndex, columnIndex) {
      //列的label的名称
      if (row.column.label === "状态") {
        return 'color:#A52A2A' //修改的样式
      } else {
        return ''
      }
    },
    handleView (row) {
      this.formParams = {id: row.sguid, idType: "formId"}
      this.formVisible = true
    },
    handleQuery () {
      this.loaddata()
    },
    handleAddMsg () {
      this.formParams = {id: this.afterOrderProWorkFlowKey, idType: "processDefKey"}
      this.formVisible = true
    },
    loaddata () {
      postsaleshead.getUserAfterSaleList().then(r => {
        this.data = r.data
      })
    }
  },
  mounted () {
    this.loaddata()
  }
}
</script>

<style scoped lang="scss" type="text/scss">
  .main{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .tbbox{
    flex: 1;
    position: relative;
  }
  .tb{
    position: absolute;
    height: 100% !important;
  }

  ::v-deep .el-dialog__body{
    position: absolute;
    left: 0;
    right: 0;
    top: 54px;
    bottom: 0;
  }

  @import "../table";

</style>
