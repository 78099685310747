<template>
  <el-dialog title="设计文件" :visible.sync="visible" fullscreen destroy-on-close style="height: 100%">
    <el-tabs tab-position="left" v-model="tabsindex" style="height: 100%;border-top: 1px solid #eeeeee" v-if="visible">
      <el-tab-pane label="预订单">
        <edit ref="bill" style="width: 800px" :data="carddata" />
      </el-tab-pane>
      <el-tab-pane label="设计图">
        <div v-for="(item, index) in formdata">
          <div class="title">第{{item.rowno}}次作图版本 {{item.makedate|dateformat()}}</div>
          <div class="sheji-message">
            <div class="sheji-center">
              <div class="imgbox">
                <div v-for="(img, imgindex) in getimgs(item.imgs)" class="img-for">
                  <vue-hover-mask>
                    <el-image fit="cover" class="image" :src="$options.filters.getImgSrc(img.url)"/>
                    <template slot="action">
                      <i class="el-icon-zoom-in" @click="previewimage(getimgs(item.imgs), imgindex)"/>
                      <i class="el-icon-delete" @click="deleteImage(getimgs(item.imgs), imgindex, item)"/>
                    </template>
                  </vue-hover-mask>
                </div>
              </div>
              <div style="display: flex">
                <el-upload
                  class="upload"
                  ref="fileRefs"
                  multiple
                  accept="image/png,image/jpg,image/jpeg"
                  :action="upfile.action"
                  :headers="upfile.headers"
                  :data="upfile.data"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false">
                  <el-button type="primary" :disabled="userinfo.usertype!= 0" @click="current = item">上传设计图</el-button>
                </el-upload>
                <el-button style="margin-left: 10px" :disabled="userinfo.usertype!= 0" type="primary"
                           @click="handlepost(item)">提交审核
                </el-button>
              </div>
            </div>
            <div class="zgyj">
              <div>主管审核及意见：<span :style="{'color': item.maker1?'#3E84E9':'#ff0000'}">{{item.maker1? '通过':'未通过'}}</span>
              </div>
              <textarea :disabled="userinfo.usertype!= 0" v-model="item.dc1" class="yj"></textarea>
              <div>
                <el-button type="primary" :disabled="userinfo.usertype!= 0" @click="handleincheck(item)">通过</el-button>
                <el-button type="primary" :disabled="userinfo.usertype!= 0" @click="handleinuncheck(item)">不通过
                </el-button>
              </div>
            </div>
            <div class="khyj">
              <div>客户审核及意见：<span :style="{'color': item.maker2?'#3E84E9':'#ff0000'}">{{item.maker2? '通过':'未通过'}}</span>
              </div>
              <textarea :disabled="userinfo.usertype!= 1" v-model="item.dc2" class="yj"></textarea>
              <div>
                <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="handleoutcheck(item)">通过</el-button>
                <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="handleoutuncheck(item)">不通过
                </el-button>
              </div>
            </div>
            <div class="reimg" style="margin-left: 20px">
              <div>修改附图:</div>
              <div class="relimgbox" style="display: flex;">
                <div v-for="(img, imgindex) in getimgs(item.imgs2)" class="img-for">
                  <vue-hover-mask>
                    <el-image fit="cover" class="image" style="height: 80px;width: 80px"
                              :src="$options.filters.getImgSrc(img.url)"/>
                    <template slot="action">
                      <i class="el-icon-zoom-in" @click="previewimage(getimgs(item.imgs2), imgindex)"/>
                      <i class="el-icon-delete" v-if="userinfo.usertype== 1"
                         @click="deleteImage2(getimgs(item.imgs2), imgindex, item)"/>
                    </template>
                  </vue-hover-mask>
                </div>
              </div>
              <el-upload
                class="upload"
                ref="fileRefs"
                multiple
                accept="image/png,image/jpg,image/jpeg"
                :action="upfile.action"
                :headers="upfile.headers"
                :data="upfile.data"
                :on-success="handleAvatarSuccessRelImg"
                :show-file-list="false">
                <el-button type="primary" :disabled="userinfo.usertype!= 1" @click="current = item">上传附图</el-button>
              </el-upload>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index"
                     :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { designflow, feibiaodingzhi, getonefeibiaodingzhi } from '../../api/common'
import { uploadUrl } from '../../config'
import { getToken, rowid } from '../../util/common'
import edit from '../feibiaodingzhi/edit'

export default {
  name: 'designform',
  components: {
    edit
  },
  computed: {
    ...mapGetters(['userinfo'])

  },
  data () {
    return {
      carddata: null,
      tabsindex: '0',
      showViewer: false,
      perviewImage: {
        showViewer: false,
        index: 0,
        urls: []
      },
      current: null,
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      visible: false,
      sguid: '',
      formdata: []
    }
  },
  methods: {

    deleteImage2 (imgs, index, item) {
      this.$confirm('确认要删除吗？', '提示', { type: 'info' }).then(() => {
        imgs.splice(index, 1)
        const str = JSON.stringify(imgs)
        designflow.postimg2({
          sguid: item.sguid,
          smainguid: this.sguid,
          imgs2: str
        }).then(r => {
          if (r.code == '200') {
            this.$set(item, 'imgs2', str)
          } else {
            this.$message({
              message: r.message,
              type: 'warning'
            })
          }
        })
      })
    },
    deleteImage (imgs, index, item) {
      this.$confirm('确认要删除吗？', '提示', { type: 'info' }).then(() => {
        imgs.splice(index, 1)
        const str = JSON.stringify(imgs)
        designflow.postimg({
          sguid: item.sguid,
          smainguid: this.sguid,
          imgs: str
        }).then(r => {
          if (r.code == '200') {
            this.$set(item, 'imgs', str)
          } else {
            this.$message({
              message: r.message,
              type: 'warning'
            })
          }
        })
      })
    },
    previewimage (imgs, index) {
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    previewimage2 (imgs, index) {
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    handleincheck (item) {
      item.dc1 = ''
      designflow.incheck({
        sguid: item.sguid,
        dc1: item.dc1
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker1', 'ok')
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleinuncheck (item) {
      if (!item.dc1) {
        this.$message({
          message: '请输入不通过的原因或要修改的内容',
          type: 'warning'
        })
        return
      }
      designflow.inuncheck({
        sguid: item.sguid,
        dc1: item.dc1
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker1', '')
          this.$message({
            message: '驳回成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleoutcheck (item) {
      item.dc2 = ''
      designflow.outcheck({
        sguid: item.sguid,
        dc2: item.dc2
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker2', 'ok')
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleoutuncheck (item) {
      if (!item.dc2) {
        this.$message({
          message: '请输入不通过的原因或要修改的内容',
          type: 'warning'
        })
        return
      }
      designflow.outuncheck({
        sguid: item.sguid,
        dc2: item.dc2
      }).then(r => {
        if (r.code == '200') {
          this.$set(item, 'maker2', '')
          this.$message({
            message: '驳回成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handlepost (item) {
      designflow.post({ sguid: item.sguid }).then(r => {
        if (r.code == '200') {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    getimgs (imgs) {
      return JSON.parse(imgs || '[]')
    },
    hanlderupload () {
      this.$refs.fileRefs.handleClick()
    },
    handleAvatarSuccessRelImg (response, file) {
      const imgs = JSON.parse(this.current.imgs2 || '[]')
      imgs.push({
        name: file.name,
        url: response.data.path
      })
      const str = JSON.stringify(imgs)
      designflow.postimg2({
        sguid: this.current.sguid,
        smainguid: this.sguid,
        imgs2: str
      }).then(r => {
        if (r.code == '200') {
          this.$set(this.current, 'imgs2', str)
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    handleAvatarSuccess (response, file) {
      const imgs = JSON.parse(this.current.imgs || '[]')
      imgs.push({
        name: file.name,
        url: response.data.path
      })
      const str = JSON.stringify(imgs)
      designflow.postimg({
        sguid: this.current.sguid,
        smainguid: this.sguid,
        imgs: str
      }).then(r => {
        if (r.code == '200') {
          this.$set(this.current, 'imgs', str)
        } else {
          this.$message({
            message: r.message,
            type: 'warning'
          })
        }
      })
    },
    loaddata (sguid) {
      designflow.getbilldetail({ sguid: sguid }).then(r => {
        if (r.code == '200') {
          if (r.data.length > 0) {
            this.formdata = r.data
          } else {
            this.formdata = [{
              rowno: 1,
              sguid: rowid()
            }]
          }
        }
      })
    },
    show (id, pagindex) {
      const that = this
      getonefeibiaodingzhi({ id: id }).then(r => {
        console.log(r)
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          this.carddata = d
          this.visible = true
          this.sguid = id
          this.loaddata(id)
          this.$nextTick(r => {
            that.$refs.bill.disabledall = true
          })
          this.tabsindex = pagindex || '0'
        }
      })
    }
    // show (sguid) {
    //   this.visible = true
    //   this.sguid = sguid
    //   this.loaddata(sguid)
    // }
  },
  mounted () {

  }
}
</script>

<style scoped>

  .image {
    height: 100px;
    width: 100px;
  }

  .imgbox {
    display: flex;
    flex-direction: row;
  }

  .sheji-message {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding: 5px 0;
  }

  .zgyj {
    margin: 0 50px;
    align-self: flex-end;
  }

  .khyj {
    align-self: flex-end;
  }

  .yj {
    height: 80px;
    width: 200px;
  }

  .sheji-center {

  }

  .title {
    margin: 7px 0;
    font-size: 15px;
    font-weight: 700;
  }

  ::v-deep .el-dialog.is-fullscreen{
    display: flex !important;
    flex-direction: column !important;
  }

   ::v-deep .el-dialog__body{
    flex: 1 !important;
  }

</style>
