<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" label-width="80px" :inline="true" size="small" @submit.native.prevent>
        <el-form-item label="关键字">
          <el-input v-model="searchdata.key" @keyup.enter.native="search"  placeholder="请输入订单编号/客户名称/联系电话"></el-input>
        </el-form-item>
        <el-form-item label="经销商">
          <el-input v-model="queryParas[1].val" @keyup.enter.native="search"  placeholder="经销商"></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-input v-model="queryParas[0].val" @keyup.enter.native="search"  placeholder="订单状态"></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="queryParas[2].val" @keyup.enter.native="search"  placeholder="型号"></el-input>
        </el-form-item>
        <el-form-item label="尺寸">
          <el-input v-model="queryParas[3].val" @keyup.enter.native="search"  placeholder="尺寸"></el-input>
        </el-form-item>

        <el-form-item>
          <div style="margin-bottom: 24px">
            <el-button type="primary"  icon="el-icon-search" :disabled="loading"  @click="search">查询</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <el-menu class="el-menu-demo" mode="horizontal" :default-active="searchdata.type" @select="statechange">
      <el-menu-item index="0">未领工单</el-menu-item>
      <el-menu-item index="1">报价中</el-menu-item>
      <el-menu-item index="2">待审核</el-menu-item>
      <el-menu-item index="3">全部</el-menu-item>
    </el-menu>

    <div class="tbbox">
      <el-table class="tb"  v-loading="loading" :data="listdata" :height="'1'" :border="true">
        <el-table-column  type="index" width="50" align="center" label="序号"/>
        <el-table-column header-align="center" :width="120" label="订单状态" align="center" prop="blog" show-overflow-tooltip>
          <div slot-scope="scope">
            <el-popover
              placement="left"
              width="160"
              trigger="click">
              <el-timeline style="padding: 0px; max-height: 200px; overflow: auto">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.log_time|dateformat">
                  {{activity.log_content}}
                </el-timeline-item>
              </el-timeline>
              <div slot="reference" style="color: #A52A2A; cursor: pointer" @click="glog(scope)">{{scope.row[scope.column.property]}}</div>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column header-align="center" :width="120" label="订单编号" prop="billnum" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="下单时间" prop="makedate" :formatter="el_table_datetime_format" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="经销商" prop="maker_name" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="终端客户名称" prop="kehumingcheng" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="联系电话" prop="lianxidianhua" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="型号" :width="100" prop="model" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="尺寸" :width="110" prop="size" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="设计师" prop="shejishi_name" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="设计文件" :width="110" align="center" prop="docs" show-overflow-tooltip>
          <div slot-scope="scope">
            <span style="color: #A52A2A; cursor: pointer" @click="showdesign(scope.row)" v-if="scope.row['shejishi'] && Number(scope.row['billstate']) >=10">已出图</span>
          </div>
        </el-table-column>
        <el-table-column header-align="center" label="报价单号" prop="baojia_billnum" show-overflow-tooltip>
          <template  slot-scope="scope">
            <span style="color: #A52A2A; cursor: pointer" @click="openbaojia(scope.row)">{{scope.row[scope.column.property]}}</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" fixed="right" :width=" searchdata.type == 3?85:150" label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="viewdetail(scope.row)">订单详情</span>
            <div style="display: inline-block" v-if="searchdata.type == 0">
              <el-divider direction="vertical"/>
              <span @click="opengetworkbox(scope.row)">领取工单</span>
            </div>
            <div style="display: inline-block" v-if="searchdata.type == 1">
              <el-divider direction="vertical"/>
              <span @click="addbj(scope.row)">报价</span>
            </div>
            <div style="display: inline-block" v-if="searchdata.type == 2">
              <el-divider direction="vertical"/>
              <span @click="checkbj(scope.row)">审核报价</span>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>
    <new-view v-if="dialog" @close="dialogclose" :title="title">
      <edit ref="bill" :data="carddata" @post="save" @cancel="dialogclose"/>
    </new-view>

    <el-dialog class="billdialog" :destroy-on-close="true" title="报价单" :fullscreen="true"
               :visible.sync="dialog_bj"
               :close-on-press-escape="false"
               width="400px">
      <bill :showmoney="true" ref="bjbill"/>
    </el-dialog>

    <el-dialog title="领取工单" :visible.sync="dialog_lq" width="400px">
      <el-form label-width="100px">
        <el-form-item label="预计完成时间">
          <el-date-picker  type="datetime" v-model="predict_quoted_date"/>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog_lq = false">取 消</el-button>
        <el-button type="primary" @click="getwork">确 定</el-button>
      </div>
    </el-dialog>
    <designform ref="designform"></designform>
  </div>

</template>

<script>
  import {
    activityAdd,
    activityBodyAdd,
    activityBodyDelete,
    activityBodyEdit,
    activityBodyList,
    activityDelete,
    activityEdit,
    getuserallfeibiaodingzhi,
    addfeibiaodingzhi,
    cancelfeibiaodingzhi,
    getlistfordispatch,
    getbuslog,
    feibiaodingzhi,
    find_getbyfind,
    upgfeibiaodingzhi,
    getonefeibiaodingzhi
  } from '@/api/common'
  import NewView from '@/components/NewView'
  import { getToken, rowid, el_table_datetime_format } from '../../util/common'
  import { mapGetters } from 'vuex'

  import { uploadUrl, mobileurl } from '../../config'
  import QRCode from 'qrcodejs2'
  import edit from '../feibiaodingzhi/edit'
  import bill from './bill'
  import designform from './designform'

  export default {
    computed: {
      ...mapGetters(['userinfo'])
    },

    components: {
      NewView,
      edit,
      bill,
      designform
    },

    data () {
      return {
        pagetotal: 0,
        queryParas: [
          { field: 'blog', type: '0', rule: 'like', val: null },
          { field: 'maker_name', type: '0', rule: 'like', val: null },
          { field: 'model', type: '0', rule: 'like', val: null },
          { field: 'size', type: '0', rule: 'like', val: null }
        ],
        dialog_lq: false,
        predict_quoted_date: null,
        curid: '',
        files: [],
        upfile: {
          action: uploadUrl,
          headers: { Authorization: 'Bearer ' + getToken() },
          data: { bizPath: 'vote' }
        },
        currentRow: { id: '', docs: '' },
        dict_shejishi: [],
        dialog_bj: false,
        activities: [],
        el_table_datetime_format: el_table_datetime_format,
        loading: false,
        searchdata: { key: '', type: '0', curpage: 1, size: 100 },
        carddata: {},
        upfile: {
          action: uploadUrl,
          headers: { Authorization: 'Bearer ' + getToken() },
          data: { bizPath: 'vote' }
        },
        imglist: [],
        fileList: [],
        bodydata: [],
        optiontitle: '',
        title: '',
        optionData: {}, // 选项数据
        formData: {}, // 活动数据
        showModal: false,
        dialog: false,
        listdata: []
      }
    },
    methods: {
      handleSizeChange (e) {
        this.searchdata.size = e
        this.load()
      },
      handleCurrentChange (e) {
        this.searchdata.curpage = e
        this.load()
      },
      showdesign (row) {
        this.$refs.designform.show(row.id, '1')
      },
      openbaojia (row) {
        if (this.userinfo.username == "刘其章"){
          this.$message.warning("您没有权限！")
          return
        }
        this.dialog_bj = true
        this.$nextTick(() => {
          const bill = this.$refs.bjbill
          bill.disabled = true
          const load = this.$loading()
          feibiaodingzhi.getbaojiabill({ id: row.id }).then(r => {
            if (r.code == '200') {
              if (r.data.cdsmaster) {
                if (row.billstate != '100') {
                  bill.disabled = false
                }
                bill.cdsmaster = r.data.cdsmaster
                bill.cdsdeta00 = r.data.cdsdeta00
              }
            }
            load.close()
          }).catch(er => { load.close() })
        })
      },
      getwork () {
        if (!this.predict_quoted_date) {
          this.$message.warning('请选择预计完成时间')
          return
        }
        feibiaodingzhi.getworkb({ id: this.curid, predict_quoted_date: this.predict_quoted_date }).then(r => {
          if (r.code == '200') {
            this.dialog_lq = false
            this.$message.success('操作成功')
            this.load()
          } else {
            this.$message.warning(r.message)
          }
        })
      },
      opengetworkbox (row) {
        this.curid = row.id
        this.predict_quoted_date = row.predict_quoted_date
        this.dialog_lq = true
      },
      checkbj (row) {
        this.dialog_bj = true
        this.$nextTick(() => {
          const bill = this.$refs.bjbill
          bill.$set(bill.bill, 'btnsave', true)
          bill.$set(bill.bill, 'btncheck', false)
          bill.$set(bill.bill, 'btnuncheck', false)
          const load = this.$loading()
          feibiaodingzhi.getbaojiabill({ id: row.id }).then(r => {
            if (r.code == '200') {
              if (r.data.cdsmaster) {
                bill.cdsmaster = r.data.cdsmaster
                bill.cdsdeta00 = r.data.cdsdeta00
              } else {
                bill.setValue((master, deta00) => {
                  bill.$set(master, 'cuscode', row.maker)
                  bill.$set(master, 'cusname', row.maker_name)
                  bill.$set(master, 'rc_yudingdan', row.id)
                  bill.$set(master, 'yudingdan_billnum', row.billnum)
                })
              }
            }
            load.close()
          }).catch(er => { load.close() })
        })
      },
      addbj (row) {
        this.dialog_bj = true
        this.$nextTick(() => {
          const bill = this.$refs.bjbill
          bill.$set(bill.bill, 'btnsave', false)
          bill.$set(bill.bill, 'btncheck', true)
          bill.$set(bill.bill, 'btnuncheck', true)
          const load = this.$loading()
          feibiaodingzhi.getbaojiabill({ id: row.id }).then(r => {
            if (r.code == '200') {
              if (r.data.cdsmaster) {
                bill.cdsmaster = r.data.cdsmaster
                bill.cdsdeta00 = r.data.cdsdeta00
              } else {
                bill.setValue((master, deta00) => {
                  bill.$set(master, 'cuscode', row.maker)
                  bill.$set(master, 'cusname', row.maker_name)
                  bill.$set(master, 'rc_yudingdan', row.id)
                  bill.$set(master, 'yudingdan_billnum', row.billnum)
                })
              }
            }
            load.close()
          }).catch(er => { load.close() })
        })
      },
      incheck (row) {
        this.$confirm('确认要审核吗？', '提示', { type: 'warning' }).then(r => {
          feibiaodingzhi.incheck({ id: row.id }).then(r1 => {
            this.load()
          })
        })
      },
      handleRemove (file, filelist) {
        const docs = []
        for (var i = 0; i < filelist.length; i++) {
          if (filelist[i].response) { docs.push({ name: filelist[i].name, url: filelist[i].response.data.path }) } else { docs.push({ name: filelist[i].name, url: filelist[i].url }) }
        }
        this.currentRow.docs = JSON.stringify(docs)
      },
      handlePreview (file) {
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = file.name
        if (file.response) { a.href = this.$options.filters.getImgSrc(file.response.data.path) } else { a.href = this.$options.filters.getImgSrc(file.url) }
        a.dispatchEvent(event)
      },
      handleAvatarSuccess (response, file, filelist) {
        const docs = []
        for (var i = 0; i < filelist.length; i++) {
          if (filelist[i].response) { docs.push({ name: filelist[i].name, url: filelist[i].response.data.path }) } else { docs.push({ name: filelist[i].name, url: filelist[i].url }) }
        }
        this.currentRow.docs = JSON.stringify(docs)
      },
      saveshejishi () {
        upgfeibiaodingzhi(this.currentRow).then(r => {
          this.dialog_fp = false
          if (r.code == '205') { this.$message.warning(r.message) }
          if (r.code == '200') {
            this.$message.success('操作成功')
            this.load()
          }
        })
      },
      statechange (e) {
        this.searchdata.type = e
        this.search()
      },
      glog (e) {
        this.activities = []
        getbuslog({ rel_id: e.row.id }).then(r => {
          this.activities = r.data
        })
      },
      search () {
        this.searchdata.curpage = 1
        this.load()
      },
      cancel (row) {
        this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
          cancelfeibiaodingzhi({ id: row.id }).then(res => {
            if (res.code == '205') { this.$message.warning(res.message) }
            if (res.code === '200') {
              this.load()
              this.$message.success('操作成功!')
            }
          })
        })
      },
      viewdetail (row) {
        const that = this
        getonefeibiaodingzhi({ id: row.id }).then(r => {
          if (r.code == '200') {
            const d = r.data
            d.child = JSON.parse(d.child)
            this.carddata = d
            this.title = '详情'
            this.dialog = true
            this.$nextTick(r => {
              that.$refs.bill.disabledall = true
            })
          }
        })
        // let d = Object.assign({}, row)
        // d.child = JSON.parse(d.child)
        // this.carddata= d
        // this.title = '详情'
        // this.dialog = true
        // this.$nextTick(r=>{
        //   that.$refs.bill.disabledall=true
        // })
      },
      addoption () {
        this.imglist = []
        this.optionData = { activity_id: this.formData.id }
        this.optiontitle = '新增'
        this.showModal = true
      },
      dialogclose () {
        this.formData = {}
        this.bodydata = []
        this.dialog = false
      },
      billstateconvert (row, column) {
        const val = row[column.property]
        if (val == '-1') {
          return '订单取消'
        }
      },
      load () {
        this.loading = true
        const param = Object.assign({}, this.searchdata,
          { queryParams: encodeURI(JSON.stringify(this.queryParas)) })
        feibiaodingzhi.getlistforquoteprice(param).then(res => {
          this.listdata = res.data.records
          this.pagetotal = res.data.total
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      save (e) {
        const d = Object.assign({}, e)
        d.child = JSON.stringify(d.child)
        d.imgs = JSON.stringify(d.imgs)
        addfeibiaodingzhi(d).then(res => {
          if (res.code == '200') {
            this.dialog = false
            this.load()
            this.$message.success('操作成功!')
          }
        })
      },
      addactivity () {
        this.title = '新增'
        this.carddata = null
        this.bodydata = []
        this.formData = { id: rowid() }
        this.dialog = true
      },
      handlePictureCardPreview () {

      },
      closemodal () {
        this.showModal = false
      },
      loadbody (id) {
        this.bodydata = []
        activityBodyList({ id: id, search: '' }).then(res => {
          if (res.code === '200') {
            this.bodydata = res.data
          }
        })
      },
      editoption (row) {
        this.optionData = Object.assign({}, row)
        if (this.optionData.att) {
          this.optionData.attlist = JSON.parse(this.optionData.att)
        }
        if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
        this.optiontitle = '编辑'
        this.showModal = true
      },
      uperror (err) {
        console.log(err)
      },
      submitoption () {
        this.$refs.option.validate(vali => {
          if (vali) {
            if (this.optionData.attlist) {
              const att = []
              this.optionData.attlist.map(cur => {
                att.push({ name: cur.name, path: cur.path })
              })
              this.optionData.att = JSON.stringify(att)
            }
            console.log(this.optionData)
            if (this.optiontitle === '新增') {
              activityBodyAdd(this.optionData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.showModal = false
                  this.loadbody(this.optionData.activity_id)
                }
              })
            } else if (this.optiontitle === '编辑') {
              activityBodyEdit(this.optionData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.showModal = false
                  this.loadbody(this.optionData.activity_id)
                }
              })
            }
          }
        })
      },
      deleteactivity (row) {
        this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
          activityDelete({ id: row.id }).then(res => {
            if (res.code === '200') {
              this.$message.success('操作成功！')
              this.load()
            }
          })
        })
      },
      deleteoption (row) {
        this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
          activityBodyDelete({ id: row.id }).then(res => {
            if (res.code === '200') {
              this.$message.success('操作成功！')
              this.loadbody(row.activity_id)
            }
          })
        })
      },
      submitactivity () {
        this.$refs.mainform.validate(vali => {
          if (vali) {
            if (this.title === '新增') {
              activityAdd(this.formData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.dialog = false
                  this.load()
                }
              })
            } else if (this.title === '编辑') {
              activityEdit(this.formData).then(res => {
                if (res.code === '200') {
                  this.$message.success('操作成功!')
                  this.dialog = false
                  this.load()
                }
              })
            }
          } else {
            console.log('fail')
          }
        })
      },
      crateQrcode (text) {
        this.qr = new QRCode('qrcode', {
          width: 128,
          height: 128, // 高度
          text: text // 二维码内容
        })
      },
      genqrcode (row) {
        console.log(mobileurl)
        this.qrcodedialog = true
        this.$nextTick(() => {
          this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
        })
      },
      qrcodedialogclose () {
        this.$refs.qrcode.innerHTML = ''
      },
      optiondialogclose () {
        this.optionData = {}
        this.imglist = []
        this.fileList = []
      },
      filelistchange (file, filelist) {
        console.log(filelist.slice(-3))
      },
      uploadSuccess (req, file, filelist) {
        if (req.code === '200') {
          file.url = req.data.path
        }
      },
      handleChange (file, filelist) {
        this.fileList = filelist.slice(-3)
      }
    },
    mounted () {
      this.load()
    },
    created () {
      find_getbyfind({ code: 'g_designer', param: { a: 'dd' } }).then(res => {
        if (res.code == '200') {
          this.dict_shejishi = res.data.data
        }
      })
    }
  }
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tbbox{
    display: flex;
    flex: 1;
    position: relative;
  }
  .tb{
    height: 100% !important;
    position: absolute;
  }

  .el-menu-demo{
    background-color: #F6F8FA;
    border: 1px solid #E6E6E6;
    border-bottom: 0px;
  }
  .el-menu-item{
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
  /*.billdialog ::v-deep .el-dialog.is-fullscreen{*/
  /*  display: flex !important;*/
  /*  flex-direction: column !important;*/
  /*}*/

  /*.billdialog ::v-deep .el-dialog__body{*/
  /*  flex: 1 !important;*/
  /*}*/
  .billdialog ::v-deep .el-dialog__body{
    position: absolute;
    top: 54px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  @import "../table";
</style>
