<template>
  <div>
    <div class="top-bar">
      <div class="title">
        <div class="icon-background">
          <i class="iconfont icondianpu-"></i>
        </div>
        <span>进度管理</span>
      </div>
      <div class="tbs">
        <el-tabs>
          <el-tab-pane label="任务板"></el-tab-pane>
          <el-tab-pane label="附件"></el-tab-pane>
          <el-tab-pane label="任务统计"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="task-body">
      <el-card v-for="(task, index) in tasks" :key="index" class="card">
        <div class="task-top">
          <span>{{task.name}}</span>
          <span style="margin-left: 10px; color: #999999"> 0 / {{task.children.length}} </span>
        </div>
        <div v-for="(item, itemIndex) in task.children" :key="itemIndex" class="task-item">
          <el-checkbox></el-checkbox>
          <span>{{item.name}}</span>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      tasks: [
        {
          name: '设立前',
          children: [
            { name: '定金' },
            { name: '填写专卖店信息表' },
            { name: '上传平面图' },
            { name: '上传位置图' },
            { name: '确认' }
          ]
        },
        {
          name: '设立中',
          children: [
            { name: '施工日志' },
            { name: '设计方案' },
            { name: '饰品采购' },
            { name: '培训' }
          ]
        },
        {
          name: '设立后',
          children: [
            { name: '验收' },
            { name: '上传照片' }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="less" type="text/less">

  .top-bar {
    background-color: white;
    padding-left: 30px;
    padding-top: 20px;
    border-radius: 5px;
    margin-bottom: 20px;

    .tbs {
      margin-left: 40px;
    }
  }

  .title {
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      font-weight: bold;
    }

    .icon-background {
      background-color: #4AB8B8;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
    }

    i {
      font-size: 20px;
      color: white;
      font-weight: 400;
      margin-right: 5px;
    }
  }

  /deep/ .el-tabs__header {
    margin: 0px;
  }

  .task-body{
    display: flex;

    .card {
      width: 280px;
      margin: 0px 7px;
      border-radius: 7px;
      box-shadow: 0px 0px 10px 5px #EBECEF;
    }
  }
  .task-item{
    margin-bottom: 10px;
    padding: 10px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 5px #EBECEF;
    .el-checkbox{
      margin: 5px;
    }
  }
  .task-top{
    margin-bottom: 10px;
    font-size: 15px;
  }
</style>
