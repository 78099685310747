<template>
    <div class="bill">
      <el-form  :model="cdsmaster" label-position="left" size="mini" :inline="true" class="demo-form-inline">
        <el-form-item label="客户">
          <el-input readonly v-model="cdsmaster.cusname" :disabled="disabled" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <el-input readonly type="date" :value="cdsmaster.billdate|dateformat('YYYY-MM-DD')"  :disabled="disabled"  placeholder="" ></el-input>
        </el-form-item>
        <el-form-item label="单据编号">
          <el-input readonly v-model="cdsmaster.billnum"  placeholder="" :disabled="disabled" ></el-input>
        </el-form-item>
      </el-form>
      <div class="toolbar">
        <el-button size="mini" icon="el-icon-plus" type="primary" @click="addrow" :disabled="disabled">增行</el-button>
        <el-button size="mini" icon="el-icon-s-fold" type="primary" @click="insertrow" :disabled="disabled">插入行</el-button>
        <el-button size="mini" icon="el-icon-delete" type="primary" @click="deleterow" :disabled="disabled">删除行</el-button>
        <el-button size="mini" icon="el-icon-tickets" type="primary" @click="openSelForm" :disabled="disabled">选择产品</el-button>
      </div>
      <div class="tbbox">
        <v-table ref="maintable" height="100%" @changed="handleFieldChange" :disabled="disabled" row-key="sguid" class="maintb" :data="cdsdeta00" :columns="columns"></v-table>
      </div>
      <div style="padding: 12px 0">
        <el-button type="primary" @click="savebill" :disabled="disabled||bill.btnsave">提 交</el-button>
        <el-button type="primary" @click="checkbill" :disabled="disabled||bill.btncheck">审 核</el-button>
        <el-button type="primary" @click="uncheckbill"  :disabled="disabled||bill.btnuncheck">驳 回</el-button>
        <el-button @click="handleClose">关 闭</el-button>
      </div>
      <el-dialog append-to-body class="seldialog" title="选择" :visible.sync="dialog_sel" width="800px">
        <popupForm :header="sel_header" @row-dbclick="rowdbclick" />
      </el-dialog>
    </div>
</template>

<script>
import vTable from '../../components/v-table'
import dropdownTable from '../../components/dropdown-table'
import popupForm from '../../components/popupForm'
import { find_getbyfind, getgoodprice, gycommain } from '../../api/common'
import { rowid, fillDefaultValue, dateformat } from '../../util/common'
import * as math from 'mathjs'

export default {
  name: '',
  props: {
    showmoney: {
      type: Boolean,
      default: false
    }
  },
  components: { vTable, popupForm, dropdownTable },
  data () {
    return {
      bill: {},
      disabled: false,
      isnew: true,
      rowDefault: { sguid: rowid() },
      sel_header: [{ param: 'mname', label: '查找' }],
      dialog_sel: false,
      cdsmaster: {},
      cdsdeta00: [],
      columns: [
        { fieldname: '类型', field: 'dc6', width: 85, allowedit: true, type: 19, id: 968, combo: '常规,非标,样品,售后,促销,常规改,装修品,五金卫浴正价,五金卫浴促销,地漏正价' },
        { fieldname: '材质', field: 'fc2', width: 85, allowedit: true, type: 0, id: 969, help_code: 'FItemA00095', alias_self: 'fc2;fname' },
        { fieldname: '货品编码', field: 'mnumberc', width: 110, allowedit: true, type: 0, id: 970, help_code: 'F_MaterialCP', alias_self: 'mnumber;sguid;mnumberc;mnumber;mname;mname;model;model;primaryunitname;primaryunitname;dn1;consultprice;price;consultprice;invsortname;invsortname;fc1;fc1' },
        { fieldname: '货品名称', field: 'mname', width: 140, allowedit: true, readonly: true },
        { fieldname: '颜色', field: 'fc1', width: 90, allowedit: true, type: 0, id: 972, help_code: 'f_color', alias_self: 'fc1;fname' },
        { fieldname: '台面边型', field: 'fc3', width: 80, allowedit: true, type: 0, id: 973, help_code: 'fc2find', alias_self: 'fc3;fname' },
        { fieldname: '常规规格', field: 'model', width: 110, allowedit: true, readonly: true },
        { fieldname: '非标尺寸', field: 'dc5', width: 110, allowedit: true },
        { fieldname: '数量', field: 'quanissue', width: 52, allowedit: true, type: 8, sum: true },
        { fieldname: '做工信息', field: 'dc1', width: 200, allowedit: true },
        { fieldname: '盆', field: 'dn6', width: 52, allowedit: true, type: 8, hidezero: true },
        { fieldname: '孔', field: 'dn7', width: 52, allowedit: true, type: 8, hidezero: true },
        { fieldname: '价格', field: 'dn3', width: 60, allowedit: true, type: 8 },
        { fieldname: '定做费', field: 'dn4', width: 60, allowedit: true, type: 8 },
        { fieldname: '附加费', field: 'dn2', width: 60, allowedit: true, type: 8 },
        { fieldname: '折扣', field: 'disc', width: 60, allowedit: true, type: 8 },
        { fieldname: '单价', field: 'price', width: 60, allowedit: true, type: 8, readonly: true },
        { fieldname: '实际金额', field: 'issuemoney', width: 70, allowedit: true, type: 8, sum: true, readonly: true },
        { fieldname: '交货日期', field: 'dd1', width: 115, allowedit: true, type: 7 },
        { fieldname: '备注', field: 'subremark', width: 120, allowedit: true },
        { fieldname: '过油', field: 'dc2', width: 80, allowedit: true, type: 19, id: 1054, combo: '过油,不过油' },
        { fieldname: '粘盆', field: 'dc3', width: 80, allowedit: true, type: 19, id: 1055, combo: '粘盆,不粘盆' },
        { fieldname: '类别', field: 'invsortname', width: 80, allowedit: true, readonly: true }
      ]
    }
  },
  mounted () {
    this.init_master_default_value(this.cdsmaster)
  },
  watch: {
    showmoney: {
      handler: function (newVal, oldVal) {
        const columns = this.columns.filter(item => ['issuemoney', 'price', 'disc', 'dn2', 'dn4', 'dn3'].indexOf(item.field) > -1)
        for (let i = 0; i < columns.length; i++) {
          this.$set(columns[i], 'visible', newVal || false)
        }
      },
      immediate: true
    }
  },
  methods: {
    checkbill () {
      gycommain.check(this.cdsmaster).then(r => {
        if (r.code == '200') {
          this.$message({ message: '审核成功', type: 'warning' })
        } else {
          this.$message.warning(r.message)
        }
      })
    },
    uncheckbill () {
      gycommain.uncheck(this.cdsmaster).then(r => {
        if (r.code == '200') {
          this.$message({ message: '驳回成功', type: 'warning' })
        } else {
          this.$message.warning(r.message)
        }
      })
    },
    handleFieldChange (param) {
      const { field, row } = param
      // 样品折扣 不带mlc样品享0.25折其他0.3
      if (field == null || field == 'dc6' || field == 'mnumberc') { row.disc = (row.dc6 == '样品' && (row.mnumberc || '').substring(0, 3).toLowerCase() != 'mlc') ? 0.25 : 0.3 }

      // 标准单价dn1
      if (row.mnumber && (['invsortname', 'mnumber', 'mnumberc', 'fc2', 'fc1', 'fc3'].indexOf(field) > -1 || field == null)) {
        getgoodprice({
          invsortname: row.invsortname || '',
          mnumber: row.mnumber || '',
          fc2: row.fc2 || '',
          fc1: row.fc1 || '',
          fc3: row.fc3 || ''
        }).then(r => {
          if (r.code == '200') {
            row.dn1 = r.data
            this.$refs.maintable.changed('dn1', row)
          }
        })
      }

      if (['mnumberc', 'fc3', 'dc5', 'fc1', 'fc2', 'dc6'].indexOf(field) > -1 || field == null) {
        // 非标长度大于常规长度100按比例增加单价
        let dc5len = 0; let modellen = 0
        const reg = /^\s*(\d*)\s*\\*/g
        if (row.dc5 && row.model) {
          dc5len = row.dc5.match(reg)[0]
          modellen = row.model.match(reg)[0]
        }
        row.dn3 = math.chain(math.bignumber(dc5len)).subtract(math.bignumber(modellen)).done() > 100 ? math.chain(math.bignumber(row.dn1 || 0)).multiply(math.bignumber(dc5len)).divide(math.bignumber(modellen)).done().round(0).toString() : row.dn1

        // 定做 小于等于200不加定做 主柜类： 泰国橡木主柜300挂柜100其他300， 美国红橡主柜350挂柜150其他400， 镜子类：泰国橡木100，美国红橡200
        let dz = 0
        if (row.dc5 && !(math.chain(math.bignumber(dc5len)).subtract(math.bignumber(modellen)).done() <= -200)) {
          if (row.invsortname == '主柜') {
            let lx = row.mnumberc.substring(0, 2).toLowerCase() == 'ml' ? '主柜' : ((['MG7019', 'MG7020', 'MG7016', 'MG7005', 'MG6018侧柜'].indexOf(row.mnumberc) > -1) ? '挂柜' : '其它')
            if (row.fc2 == '泰国橡木') {
              if (lx == '主柜')dz = 300
              else if (lx == '挂柜')dz = 100
              else dz = 300
            } else if (row.fc2 == '美国红橡') {
              if (lx == '主柜')dz = 350
              else if (lx == '挂柜')dz = 150
              else lx = 400
            }
          } else if (row.invsortname == '镜子') {
            if (row.fc2 == '泰国橡木')dz = 100
            else if (row.fc2 == '美国红橡') dz = 200
            else dz = 0
          }
        }
        row.dn4 = dz
      }
      // 单价 = 价格*折扣+定做费+附加费
      row.price = math.chain(math.bignumber(row.dn3 || 0)).multiply(math.bignumber(row.disc || 0)).add(math.bignumber(row.dn4 || 0)).add(math.bignumber(row.dn2 || 0)).done().ceil().toString()
      // 实际金额 = 单价 * 数量
      row.issuemoney = math.chain(math.bignumber(row.price || 0)).multiply(math.bignumber(row.quanissue || 0)).done().toString()
    },
    savebill () {
      gycommain.savebill({ gy_commain: this.cdsmaster, gy_comsub: this.cdsdeta00, gy_comsub_delete: this.$refs.maintable.removeRow }).then(r => {
        if (r.code == '200') {
          this.$refs.maintable.resetState()
          this.$message('保存成功')
        }
      })
    },
    handleClose () {
      this.$parent.handleClose()
    },
    deleterow () {
      this.$refs.maintable.deleterow()
    },
    rowdbclick (row, column, event) {
      find_getbyfind({ code: 'mnumbertobom', param: { mnumber: row.mnumber, fc1: row.fc1 } }).then(r => {
        if (r.code == '200') {
          const list = r.data.data
          for (let i = 0; i < list.length; i++) {
            const newRow = this.addrow()
            this.$refs.maintable.backFillObject(newRow, 'mnumber;mnumberc;mname;model;price;dn1;fc1;fc2;fc3;dn6;dn7;dc2;dc3;invsortname;mnumberc', list[i],
              'mnumber;mnumberc;mname;model;price;price;fc1;fc3;fc2;dn6;dn7;dc2;dc3;invsortname;mnumberc')
          }
        }
      })
    },
    openSelForm () {
      this.dialog_sel = true
    },
    addrow () {
      const newRow = this.$refs.maintable.addrow({
        sguid: rowid(),
        smainguid: this.cdsmaster.sguid,
        dc6: '常规',
        quanissue: 1,
        dn1: 0,
        dn3: 0,
        dn4: 0,
        dn2: 0,
        disc: 0.3,
        price: 0,
        issuemoney: 0
      })
      return newRow
    },
    insertrow () {
      const newRow = this.$refs.maintable.insertrow({
        sguid: rowid(),
        smainguid: this.cdsmaster.sguid,
        dc6: '常规',
        quanissue: 1,
        dn1: 0,
        dn3: 0,
        dn4: 0,
        dn2: 0,
        disc: 0.3,
        price: 0,
        issuemoney: 0
      })
      return newRow
    },
    setValue (backcall) {
      if (this.isnew) {
        backcall(this.cdsmaster, this.cdsdeta00)
      }
    },
    init_master_default_value (obj) {
      obj.sguid = rowid()
      obj.billdate = dateformat(new Date(), 'YYYY-MM-DD')
      obj.billcode = '1401'
    }
  }
}
</script>

<style scoped>
  .bill{
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .tbbox{
    flex: 1;
    position: relative;
  }
  .maintb{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .el-input ::v-deep .el-input__inner{
    line-height: normal !important;
  }
  .seldialog ::v-deep .el-dialog__body{
    padding: 10px 20px 20px;
  }

  .toolbar{
    padding: 10px 0;
    /*border-top: 1px solid #EBEEF5;*/
    /*border-left: 1px solid #EBEEF5;*/
    /*border-right: 1px solid #EBEEF5;*/
  }

</style>
