<template>
  <div class="main">
    <div v-loading.fullscreen.lock="loading2" element-loading-text="提交中"> </div>
    <el-form :inline="true" label-width="80px"  size="small">

      <el-form-item label="单据日期">
        <el-date-picker
          v-model="value"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="订单编号">
        <el-input v-model="queryParas[2].val" @keyup.enter.native="handleQuery" placeholder="订单编号"/>
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input v-model="queryParas[5].val" @keyup.enter.native="handleQuery" placeholder="客户名称"/>
      </el-form-item>
      <el-form-item label="产品型号">
        <el-input v-model="queryParas[3].val" @keyup.enter.native="handleQuery" placeholder="产品型号"/>
      </el-form-item>

      <el-form-item label="责任人">
        <el-input v-model="queryParas[4].val" @keyup.enter.native="handleQuery" placeholder="责任人"/>
      </el-form-item>

      <el-form-item>
        <el-button @click="" icon="el-icon-search" type="primary" :disabled="loading" @click="handleQuery">查 询</el-button>
      </el-form-item>
          <el-form-item>
            <el-button @click="" icon="el-icon-download" type="primary" :disabled="loading" @click="handleQueryExport('售后管理')">导 出</el-button>
          </el-form-item>
    </el-form>

    <div class="tbbox">
      <el-table border v-loading="loading" :data="data" :row-style="{height: '38px'}" :header-cell-style="{background: '#F6F8FA'}" height="100%" :cell-style="changeCellStyle">
        <el-table-column header-align="center" align="center" :width="50" type="index" label="序号"></el-table-column>
        <el-table-column header-align="center" property="bpmn_step" label="售后状态" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="newbillstate" label="生产状态" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="billdate" :width="150" :formatter="el_table_datetime_format" label="单据日期"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="cusname" :width="120" label="客户"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="billnum" :width="120" label="售后申请编号"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="erp_billnum" :width="120" label="原订单编号"  show-overflow-tooltip></el-table-column>

        <el-table-column header-align="center" property="sgdh" :width="120" label="手工单号"  show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="dzdh" :width="120" label="预订单号"  show-overflow-tooltip></el-table-column>

        <el-table-column header-align="center" property="mname" label="产品型号" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="dc1" label="做工信息" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="fc1" label="颜色" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="quan" label="数量"></el-table-column>
        <el-table-column header-align="center" property="issue" label="问题描述" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="require" label="要求说明" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="dispose" label="处理说明" show-overflow-tooltip></el-table-column>

        <el-table-column header-align="center" property="ap_def" label="责任判定" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dc1" label="责任人" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_type" label="售后类型" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_project" label="售后人员处理方案建议" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dn1" label="成本费" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dn2" label="客户需承担金额" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dn3" label="生产承担费用" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dn4" label="返厂运费" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dn5" label="审批费用" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_result" label="最终结果" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dc2" label="责任单位" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dc3" label="最终责任人" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dc4" label="品质部处理意见" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dc5" label="生产改善措施" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" property="ap_dc6" label="处理结果" show-overflow-tooltip></el-table-column>


        <el-table-column  header-align="center" align="center" :width="120" label="操作" fixed="right">
          <template  slot-scope="scope">
            <span style="cursor: pointer;color: #3e84e9" @click="handleView(scope.row)">打开</span>
            <el-divider direction="vertical"/>
            <span style="cursor: pointer;color: #3e84e9" @click="handleCancel(scope.row)">作废</span>
          </template>
          
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[50,100, 200, 300, 400]"
        :page-size= "searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>

    <el-dialog class="billdialog" :destroy-on-close="true" title="售后申请" :fullscreen="true"
               :visible.sync="showEditForm"
               :close-on-press-escape="false"
               width="400px">
      <apply ref="formApply" @done="loaddata"/>
    </el-dialog>

    <baseFlowDialog :visible.sync="formVisible" @close="baseFormClose" :params="formParams"></baseFlowDialog>
  </div>
</template>

<script>
  import bill from './bill'
  import vTable from '../../components/v-table'
  import apply from '../dispute/apply'
  import { postsaleshead, process } from '../../api/common'
  import { dateformat, el_table_datetime_format, rowid } from '../../util/common'
  import { mapGetters } from 'vuex'
  import baseFlowDialog from '../../components/baseFlowDialog'

  export default {
    name: '',
    data () {
      return {
        loading: false,
        loading2: false,
        pagetotal: 0,
        searchdata: {curpage: 1, size: 50 },
        formVisible: false,
        formParams: {},
        a: '',
        b: '',
        showEditForm: false,
        data: [],
        el_table_datetime_format: el_table_datetime_format,
        queryParas: [
          { field: 'billdate', type: '0', rule: 'ge', val: null },
          { field: 'billdate', type: '0', rule: 'le', val: null },
          { field: 'billnum', type: '0', rule: 'like', val: null },
          { field: 'mname', type: '0', rule: 'like', val: null },
          { field: 'ap_dc1', type: '0', rule: 'like', val: null },
          { field: 'cusname', type: '0', rule: 'like', val: null }
        ],
        value: []
      }
    },
    computed: {
      ...mapGetters(['userinfo'])
    },
    components: {
      bill,
      vTable,
      apply,baseFlowDialog
    },
    methods: {
      handleSizeChange (e) {
        this.searchdata.size = e
        this.loaddata()
      },
      handleCurrentChange (e) {
        this.searchdata.curpage = e
        this.loaddata()
      },
      baseFormClose(){
        this.formVisible=false
        this.loaddata()
      },
      changeCellStyle (row, column, rowIndex, columnIndex) {
        //列的label的名称
        if (row.column.label === "状态") {
          return 'color:#A52A2A' //修改的样式
        } else {
          return ''
        }
      },
      handleView (row) {
        this.formParams = {id: row.sguid, idType: "formId"}
        this.formVisible = true
      },
      handleCancel (row) {
        let that = this
        this.formParams = {id: row.sguid, idType: "formId"}
        this.$confirm('确定要作废吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass:'del-model',
          type: 'warning'
        }).then(() => {  
          this.loading2 = true
          postsaleshead.cancel({sguid: row.sguid}).then(r=>{
             if (r.code == '200')
             {
              this.loading2 = false
              this.$message({ message: '作废成功', type: 'warning' })
              this.loaddata();
             }else{
              this.$message({ message: '操作失败', type: 'warning' })
             }
             this.loading2 = false
          }).catch(()=>{
            this.loading2 = false
          })
          
        }).catch(() => {

        });
      },
      handleQuery () {
        this.searchdata.curpage =1
        this.loaddata()
      },
      loaddata () {
        this.loading = true
        if (this.value && this.value.length > 1) {
          this.queryParas[0].val = dateformat(this.value[0])
          this.queryParas[1].val = dateformat(this.value[1])
        } else {
          this.queryParas[0].val = null
          this.queryParas[1].val = null
        }

        let enQueryParas = encodeURI(JSON.stringify(this.queryParas))
        const param = Object.assign({}, this.searchdata, { queryParams: enQueryParas })

        postsaleshead.getlist(param).then(r => {
          this.data = r.data.list
          this.pagetotal = r.data.total
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      handleQueryExport(fileName){

        if (this.value && this.value.length > 1) {
          this.queryParas[0].val = dateformat(this.value[0])
          this.queryParas[1].val = dateformat(this.value[1])
        } else {
          this.queryParas[0].val = null
          this.queryParas[1].val = null
        }

        let enQueryParas = encodeURI(JSON.stringify(this.queryParas))
        const param = Object.assign({}, this.searchdata, { queryParams: enQueryParas })


        postsaleshead.export(param).then(data=>{
          if (!data   || data.size === 0 ) {
            this.$message.warning('文件下载失败')
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data]), fileName + '.xls')
          } else {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', '预订单管理.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) // 下载完成移除元素
            window.URL.revokeObjectURL(url) // 释放掉blob对象
          }
        })
      }
    },
    mounted () {
      this.loaddata()
    }
  }
</script>

<style scoped lang="scss" type="text/scss">
  .main{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .tbbox{
    flex: 1;
    position: relative;
  }
  .tb{
    position: absolute;
    height: 100% !important;
  }

  ::v-deep .el-dialog__body{
    position: absolute;
    left: 0;
    right: 0;
    top: 54px;
    bottom: 0;
  }

  @import "../table";

</style>
