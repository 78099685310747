import { render, staticRenderFns } from "./dict.vue?vue&type=template&id=05651a17&scoped=true"
import script from "./dict.vue?vue&type=script&lang=js"
export * from "./dict.vue?vue&type=script&lang=js"
import style0 from "./dict.vue?vue&type=style&index=0&id=05651a17&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05651a17",
  null
  
)

export default component.exports