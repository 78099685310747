<template>
  <div class="container">
    <div class="top-bar">
      <el-button type="primary" @click="addactivity">新增</el-button>
    </div>
    <el-table :data="listdata">
      <el-table-column label="标题" prop="name" show-overflow-tooltip></el-table-column>
      <el-table-column label="开始时间" prop="start_time" show-overflow-tooltip :formatter="formatDate"></el-table-column>
      <el-table-column label="结束时间" prop="end_time" show-overflow-tooltip :formatter="formatDate"></el-table-column>
      <el-table-column label="规则" prop="f_rule" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作">
        <div class="commanddiv" slot-scope="scope">
          <span @click="editactivity(scope.row)">编辑</span>
          <el-divider direction="vertical"/>
          <span @click="deleteactivity(scope.row)">删除</span>
          <el-divider direction="vertical"/>
          <span @click="genqrcode(scope.row)">生成二维码</span>
        </div>
      </el-table-column>
    </el-table>
    <el-dialog class="qrcodedialog" :visible.sync="qrcodedialog" @close="qrcodedialogclose" width="350px">

      <div style="display: flex; align-items: center; flex-direction: column; height: 270px">
        <div style="font-size: 16px; font-weight: bold; color: black">分享二维码</div>
        <div class="qrcode" style="height: 128px;width: 128px; margin: auto auto;" id="qrcode" ref="qrcode"/>
      </div>
    </el-dialog>
    <new-view v-if="dialog" @close="dialogclose" :title="title">
      <el-form label-width="80px" :model="formData" ref="mainform">
        <el-form-item label="标题"><el-input placeholder="标题" v-model="formData.name"></el-input></el-form-item>
        <el-form-item label="开始时间"><el-date-picker type="datetime" placeholder="开始日期" v-model="formData.start_time" style="width: 100%;"></el-date-picker></el-form-item>
        <el-form-item label="结束时间"><el-date-picker type="datetime" placeholder="结束日期" v-model="formData.end_time" style="width: 100%;"></el-date-picker></el-form-item>
        <el-form-item label="规则"><el-input placeholder="规则" v-model="formData.f_rule" type="textarea"></el-input></el-form-item>
      </el-form>
      <el-button type="primary" @click="addoption">添加选项</el-button>
      <el-table :data="bodydata">
        <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
        <el-table-column label="图片" prop="img" show-overflow-tooltip></el-table-column>
        <el-table-column label="详情" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="editoption(scope.row)">编辑</span>
            <el-divider direction="vertical"/>
            <span @click="deleteoption(scope.row)">删除</span>
          </div>
        </el-table-column>
      </el-table>
      <el-dialog append-to-body :visible.sync="showModal" :title="optiontitle" @close="optiondialogclose">
        <el-form  label-width="80px" :model="optionData" ref="option">
          <el-form-item label="名称"><el-input placeholder="名称" v-model="optionData.name"/></el-form-item>
          <el-form-item label="图片">
            <el-upload
            :action= "upfile.action"
            :limit = "1"
            :show-file-list="true"
            :headers="upfile.headers"
            :data="upfile.data"
            :on-error="uperror"
            :file-list="imglist"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          </el-form-item>
          <el-form-item label="详情"><el-input :autosize="{ minRows: 4}" type="textarea" placeholder="详情"  v-model="optionData.remark"/></el-form-item>
          <el-form-item label="附件">
            <upload :serverUrl="upfile.action" v-model="optionData.attlist" :prefix="upfile.action"/>
          </el-form-item>
        </el-form>
        <div class="buttondiv">
          <el-button @click="closemodal">关闭</el-button>
          <el-button type="primary" @click="submitoption">确认</el-button>
        </div>
      </el-dialog>
      <div class="buttondiv">
        <el-button @click="dialogclose">关闭</el-button>
        <el-button type="primary" @click="submitactivity">确认</el-button>
      </div>
    </new-view>
  </div>
</template>

<script>
import {
  activityList, activityAdd,
  activityBodyAdd,
  activityBodyDelete,
  activityBodyEdit,
  activityBodyList,
  activityDelete,
  activityEdit
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, rowid } from '../../util/common'

import { uploadUrl, mobileurl } from '../../config'
import QRCode from 'qrcodejs2'
import upload from '@/components/upload'

export default {
  components: {
    NewView,
    upload
  },
  data () {
    return {
      qrcodedialog: false,
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    addoption () {
      this.imglist = []
      this.optionData = { activity_id: this.formData.id }
      this.optiontitle = '新增'
      this.showModal = true
    },
    dialogclose () {
      this.formData = {}
      this.bodydata = []
      this.dialog = false
    },
    // 方法区
    formatDate (row, column) {
      // 获取单元格数据
      const data = row[column.property]
      if (data == null) {
        return null
      }
      const dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    },
    load () {
      activityList().then(res => {
        if (res.code === '200') {
          this.listdata = res.data
        }
      })
    },
    addactivity () {
      this.title = '新增'
      this.bodydata = []
      this.formData = { id: rowid() }
      this.dialog = true
    },
    handlePictureCardPreview () {

    },
    handleAvatarSuccess (response, file, filelist) {
      this.optionData.img = response.data.path
    },
    handleRemove (file, filelist) {
      if (filelist.length === 0) {
        this.optionData.img = ''
      }
    },
    handleRemove2 (file, filelist) {

    },
    closemodal () {
      this.showModal = false
    },
    loadbody (id) {
      this.bodydata = []
      activityBodyList({ id: id, search: '' }).then(res => {
        if (res.code === '200') {
          this.bodydata = res.data
        }
      })
    },
    editactivity (row) {
      this.loadbody(row.id)
      this.formData = Object.assign({}, row)
      this.title = '编辑'
      this.dialog = true
    },
    editoption (row) {
      this.optionData = Object.assign({}, row)
      if (this.optionData.att) {
        this.optionData.attlist = JSON.parse(this.optionData.att)
      }
      if (this.optionData.img) { this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }] }
      this.optiontitle = '编辑'
      this.showModal = true
    },
    uperror (err) {
      console.log(err)
    },
    submitoption () {
      this.$refs.option.validate(vali => {
        if (vali) {
          if (this.optionData.attlist) {
            const att = []
            this.optionData.attlist.map(cur => {
              att.push({ name: cur.name, path: cur.path })
            })
            this.optionData.att = JSON.stringify(att)
          }
          console.log(this.optionData)
          if (this.optiontitle === '新增') {
            activityBodyAdd(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          } else if (this.optiontitle === '编辑') {
            activityBodyEdit(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          }
        }
      })
    },
    deleteactivity (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.load()
          }
        })
      })
    },
    deleteoption (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityBodyDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.loadbody(row.activity_id)
          }
        })
      })
    },
    submitactivity () {
      this.$refs.mainform.validate(vali => {
        if (vali) {
          if (this.title === '新增') {
            activityAdd(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          } else if (this.title === '编辑') {
            activityEdit(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          }
        } else {
          console.log('fail')
        }
      })
    },
    crateQrcode (text) {
      this.qr = new QRCode('qrcode', {
        width: 128,
        height: 128, // 高度
        text: text // 二维码内容
      })
    },
    genqrcode (row) {
      console.log(mobileurl)
      this.qrcodedialog = true
      this.$nextTick(() => {
        this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
      })
    },
    qrcodedialogclose () {
      this.$refs.qrcode.innerHTML = ''
    },
    optiondialogclose () {
      this.optionData = {}
      this.imglist = []
      this.fileList = []
    },
    // 预览图片
    handlePreview () {

    },
    filelistchange (file, filelist) {
      console.log(filelist.slice(-3))
    },
    uploadSuccess (req, file, filelist) {
      if (req.code === '200') {
        file.url = req.data.path
      }
    },
    handleChange (file, filelist) {
      this.fileList = filelist.slice(-3)
    }
  },
  mounted () {
    this.load()
  }

}
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv{
    margin: 10px 0;
    text-align: right;
  }
  .commanddiv{
    color: $color-primary;
    span:hover{
      cursor: pointer;
    }
  }
  .qrcodedialog ::v-deep .el-dialog__body{
    padding: 0px;
  }

  @import "../table.scss";
</style>
