<template>
  <div class="container">
    <div class="header">
      <el-form :model="searchdata" :inline="true" size="small" @submit.native.prevent>
        <el-form-item label="关键字">
          <el-input v-model="searchdata.key" @keyup.enter.native="search" placeholder="请输入订单编号/客户名称/联系电话"></el-input>
        </el-form-item>
        <el-form-item label="经销商">
          <el-input v-model="queryParas[3].val" @keyup.enter.native="search" placeholder="经销商"></el-input>
        </el-form-item>
        <el-form-item label="设计师">
          <el-input v-model="queryParas[0].val" @keyup.enter.native="search" placeholder="设计师"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="value"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="完成时间">
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <!--          <el-input v-model="queryParas[6].val" @keyup.enter.native="search"  placeholder="订单状态"></el-input>-->
          <el-select clearable v-model="queryParas[6].val" placeholder="请选择订单状态">
            <el-option
              v-for="item in dict_state"
              :key="item.log_content"
              :label="item.log_content"
              :value="item.log_content">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="queryParas[7].val" @keyup.enter.native="search" placeholder="型号"></el-input>
        </el-form-item>
        <el-form-item label="尺寸">
          <el-input v-model="queryParas[8].val" @keyup.enter.native="search" placeholder="尺寸"></el-input>
        </el-form-item>
        <el-form-item label="上传生产图时间">
          <el-date-picker
            v-model="value3"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <div style="margin-bottom: 24px">
            <el-button type="primary" icon="el-icon-search" :disabled="loading" @click="search">查询</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <el-menu class="el-menu-demo" mode="horizontal" :default-active="searchdata.type" @select="statechange">
      <el-menu-item index="0">未领工单</el-menu-item>
      <el-menu-item index="1">设计中</el-menu-item>
      <el-menu-item index="2">待审核</el-menu-item>
      <el-menu-item index="3">待上传生产图</el-menu-item>
      <el-menu-item index="4">全部</el-menu-item>
    </el-menu>

    <div class="tbbox">
      <el-table class="tb" v-loading="loading" :data="listdata" :height="'1'" :border="true">
        <el-table-column type="index" width="50" align="center" label="序号"/>
        <el-table-column header-align="center" :width="120" label="订单状态" align="center" prop="blog"
                         show-overflow-tooltip>
          <div slot-scope="scope">
            <el-popover
              placement="left"
              width="160"
              trigger="click">
              <el-timeline style="padding: 0px; max-height: 200px; overflow: auto">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.log_time|dateformat">
                  {{activity.log_content}}
                </el-timeline-item>
              </el-timeline>
              <div slot="reference" style="color: #A52A2A; cursor: pointer" @click="glog(scope)">
                {{scope.row[scope.column.property]}}
              </div>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column header-align="center" :width="120" label="订单编号" prop="billnum"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="下单时间" prop="makedate"
                         :formatter="el_table_datetime_format" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="经销商" prop="maker_name"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="终端客户名称" prop="kehumingcheng"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="联系电话" prop="lianxidianhua"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="型号" :width="100" prop="model"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="尺寸" :width="110" prop="size"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="设计师" prop="shejishi_name"
                         show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" :width="110" label="要求完成设计时间" prop="yaoqiushejishijian"
                         show-overflow-tooltip :formatter="el_table_datetime_format"></el-table-column>
        <el-table-column header-align="center" :width="110" label="完成时间" prop="checkdate"
                         show-overflow-tooltip :formatter="el_table_datetime_format"></el-table-column>
        <el-table-column header-align="center" :width="120" label="上传生产图时间" prop="upload_pro_img_date"
                         show-overflow-tooltip :formatter="el_table_datetime_format"></el-table-column>
        <el-table-column header-align="center" label="说明" prop="shuoming" show-overflow-tooltip></el-table-column>

        <el-table-column header-align="center" fixed="right" :width="160" label="操作">
          <div class="commanddiv" slot-scope="scope">
            <span @click="viewdetail(scope.row)">订单详情</span>
            <div style="display: inline-block" v-if="searchdata.type == 0">
              <el-divider direction="vertical"/>
              <span @click="opengetworkbox(scope.row)">领取工单</span>
            </div>
            <div style="display: inline-block" v-if="searchdata.type > 0">
              <el-divider direction="vertical"/>
              <span @click="openfilebox(scope.row)">设计文件</span>
            </div>
            <div style="display: inline-block" v-if="searchdata.type == 3">
              <el-divider direction="vertical"/>
              <span @click="openProImgForm(scope.row)">上传生产图</span>
            </div>
            <!--            <div style="display: inline-block" v-if="searchdata.type == 2">-->
            <!--              <el-divider direction="vertical"/>-->
            <!--              <span @click="incheck(scope.row)">图纸审核</span>-->
            <!--            </div>-->
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchdata.curpage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="searchdata.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal">
      </el-pagination>
    </div>
    <new-view v-if="dialog" @close="dialogclose" :title="title">
      <edit ref="bill" :data="carddata" @post="save" @cancel="dialogclose"/>
    </new-view>

    <el-dialog title="设计文件" :visible.sync="dialog_fp" width="400px">
      <el-upload
        ref="uploada"
        class="upload-demo"
        multiple
        :action="upfile.action"
        :headers="upfile.headers"
        :data="upfile.data"
        :on-success="handleAvatarSuccess"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="files">
        <el-button size="small" type="primary">选择文件</el-button>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog_fp = false">取 消</el-button>
        <el-button type="primary" :disabled="searchdata.type != 1" @click="saveshejishi">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="领取工单" :visible.sync="dialog_lq" width="400px">
      <el-form label-width="100px">
        <el-form-item label="预计完成时间">
          <el-date-picker type="datetime" v-model="predict_done_date"/>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog_lq = false">取 消</el-button>
        <el-button type="primary" @click="getwork">确 定</el-button>
      </div>
    </el-dialog>
    <!--    上传生产图-->
    <el-dialog title="上传生产图" :visible.sync="showProForm">
      <div style="margin-bottom: 10px">
        <div>设计图</div>
        <div style="display: flex;">
          <div v-for="(img,index) in imgs3">
            <vue-hover-mask>
              <el-image :src="$options.filters.getImgSrc(img.url)" style="width: 100px;height: 100px"/>
              <template slot="action">
                <i class="el-icon-zoom-in" @click="previewimage(imgs3, index)"/>
              </template>
            </vue-hover-mask>
          </div>
        </div>
      </div>
      <el-upload
        class="upload"
        ref="fileRefs"
        multiple
        accept="image/png,image/jpg,image/jpeg"
        :action="upfile.action"
        :headers="upfile.headers"
        :data="upfile.data"
        :on-success="handleAvatarSuccessPassImg"
        :show-file-list="false">
        <el-button type="primary" >上传生产图</el-button>
      </el-upload>

      <div class="relimgbox" style="display: flex;">
        <div v-for="(img, imgindex) in proFormData.pro_imgs" class="img-for">
          <vue-hover-mask>
            <el-image fit="cover" class="image" style="height: 66px;width: 66px"  :src="$options.filters.getImgSrc(img.url)"/>
            <template slot="action">
              <i class="el-icon-zoom-in" @click="previewimage(proFormData.pro_imgs, imgindex)"/>
            </template>
          </vue-hover-mask>
        </div>
      </div>

       <span slot="footer" class="dialog-footer">
         <el-button @click="jump10(proFormData)">驳回重新上传签名图</el-button>
         <el-button @click="showProForm = false">取 消</el-button>
         <el-button type="primary" @click="handlePass(proFormData)">确 定</el-button>
       </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :zIndex="9999" :initialIndex="perviewImage.index"
                     :on-close="()=>{showViewer=false}" :url-list="perviewImage.urls"></el-image-viewer>

    <designform ref="designform"></designform>

  </div>

</template>

<script>
import {
  activityAdd,
  activityBodyAdd,
  activityBodyDelete,
  activityBodyEdit,
  activityBodyList,
  activityDelete,
  activityEdit,
  getuserallfeibiaodingzhi,
  addfeibiaodingzhi,
  cancelfeibiaodingzhi,
  getlistfordispatch,
  getbuslog,
  feibiaodingzhi,
  find_getbyfind,
  upgfeibiaodingzhi,
  getonefeibiaodingzhi,
  designflow
} from '@/api/common'
import NewView from '@/components/NewView'
import { getToken, rowid, el_table_datetime_format, dateformat } from '../../util/common'

import { uploadUrl, mobileurl } from '../../config'
import QRCode from 'qrcodejs2'
import edit from '../feibiaodingzhi/edit'
import designform from './designform'

export default {
  components: {
    NewView,
    edit,
    designform
  },
  data () {
    return {
      imgs3: [],
      proFormData: {},
      showProForm: false,
      pagetotal: 0,
      dict_state: [],
      value: [],
      value2: [],
      value3: [],
      queryParas: [
        {
          field: 'shejishi_name',
          type: '0',
          rule: 'like',
          val: null
        },
        {
          field: 'makedate',
          type: '0',
          rule: 'ge',
          val: null
        },
        {
          field: 'makedate',
          type: '0',
          rule: 'le',
          val: null
        },
        {
          field: 'maker_name',
          type: '0',
          rule: 'like',
          val: null
        },
        {
          field: 'checkdate',
          type: '0',
          rule: 'ge',
          val: null
        },
        {
          field: 'checkdate',
          type: '0',
          rule: 'le',
          val: null
        },
        {
          field: 'blog',
          type: '0',
          rule: 'like',
          val: null
        },
        {
          field: 'model',
          type: '0',
          rule: 'like',
          val: null
        },
        {
          field: 'size',
          type: '0',
          rule: 'like',
          val: null
        },
        {
          field: 'upload_pro_img_date',
          type: '0',
          rule: 'ge',
          val: null
        },
        {
          field: 'upload_pro_img_date',
          type: '0',
          rule: 'le',
          val: null
        }
      ],
      showdesignform: true,
      showViewer: false,
      perviewImage: {
        showViewer: false,
        index: 0,
        urls: []
      },
      curid: '',
      predict_done_date: null,
      dialog_lq: false,
      files: [],
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      currentRow: {
        id: '',
        docs: ''
      },
      dict_shejishi: [],
      dialog_fp: false,
      activities: [],
      el_table_datetime_format: el_table_datetime_format,
      loading: false,
      searchdata: {
        key: '',
        type: '0',
        curpage: 1,
        size: 100
      },
      carddata: {},
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'vote' }
      },
      imglist: [],
      fileList: [],
      bodydata: [],
      optiontitle: '',
      title: '',
      optionData: {}, // 选项数据
      formData: {}, // 活动数据
      showModal: false,
      dialog: false,
      listdata: []
    }
  },
  methods: {
    jump10 (data) {
      this.$confirm('确认要驳回吗？', '提示', { type: 'info' }).then(() => {
        feibiaodingzhi.jump({ id: data.id, step: '10' }).then(r => {
          if (r.code == '200') {
            this.$message.success('驳回成功')
            this.showProForm = false
            this.load()
          } else {
            this.$message.error(r.message)
          }
        })
      })
    },
    handlePass (data) {
      const d = Object.assign({}, data)
      d.pro_imgs = JSON.stringify(d.pro_imgs)
      feibiaodingzhi.updateProImgs(d).then(r => {
        if (r.code == '200') {
          this.$message.success('上传成功')
          this.showProForm = false
          this.load()
        }
      })
    },
    previewimage (imgs, index) {
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im.url)
      })
      this.perviewImage.index = index
      this.showViewer = true
    },
    handleAvatarSuccessPassImg (response, file) {
      this.proFormData.pro_imgs.push({
        name: file.name,
        url: response.data.path
      })
    },
    openProImgForm (d) {
      this.$set(this.proFormData, 'id', d.id)
      // 获取图片
      getonefeibiaodingzhi({ id: d.id }).then(r => {
        if (r.code == '200') {
          if (r.data.pro_imgs == null || typeof (r.data.pro_imgs) === 'undefined') { this.$set(this.proFormData, 'pro_imgs', []) } else { this.$set(this.proFormData, 'pro_imgs', JSON.parse(r.data.pro_imgs)) }
        }
      })
      designflow.getPassIns({ id: d.id }).then(r => {
        if (r.code == '200') {
          if (typeof (r.data) === 'undefined' || r.data.imgs3 == null || typeof (r.data.imgs3) === 'undefined') {
            if (typeof (r.data) === 'undefined' || r.data.imgs == null || typeof (r.data.imgs) === 'undefined') { this.imgs3 = [] } else { this.imgs3 = JSON.parse(r.data.imgs) }
          } else { this.imgs3 = JSON.parse(r.data.imgs3) }
        }
      })
      this.showProForm = true
    },
    handleSizeChange (e) {
      this.searchdata.size = e
      this.load()
    },
    handleCurrentChange (e) {
      this.searchdata.curpage = e
      this.load()
    },
    incheck (row) {
      this.$confirm('确认要审核吗？', '提示', { type: 'warning' }).then(r => {
        feibiaodingzhi.incheck({ id: row.id }).then(r1 => {
          this.load()
        })
      })
    },
    handleRemove (file, filelist) {
      const docs = []
      for (var i = 0; i < filelist.length; i++) {
        if (filelist[i].response) {
          docs.push({
            name: filelist[i].name,
            url: filelist[i].response.data.path
          })
        } else {
          docs.push({
            name: filelist[i].name,
            url: filelist[i].url
          })
        }
      }
      this.currentRow.docs = JSON.stringify(docs)
    },
    handlePreview (file) {
      const files = this.$refs.uploada.uploadFiles
      const imgs = []
      for (var i = 0; i < files.length; i++) {
        if (files[i].response) {
          imgs.push(files[i].response.data.path)
        } else {
          imgs.push(files[i].url)
        }
      }
      const indexstr = file.response ? file.response.data.path : file.url
      this.perviewImage.index = imgs.indexOf(indexstr)
      this.perviewImage.urls = imgs.map(im => {
        return this.$options.filters.getImgSrc(im)
      })
      this.showViewer = true
      //
      //
      // var a = document.createElement('a');
      // var event = new MouseEvent('click');
      // a.download = file.name;
      // a.target = "view_window"
      // if (file.response)
      //   a.href = this.$options.filters.getImgSrc(file.response.data.path);
      // else
      //   a.href = this.$options.filters.getImgSrc(file.url);
      // a.dispatchEvent(event);
    },
    handleAvatarSuccess (response, file, filelist) {
      const docs = []
      for (var i = 0; i < filelist.length; i++) {
        if (filelist[i].response) {
          docs.push({
            name: filelist[i].name,
            url: filelist[i].response.data.path
          })
        } else {
          docs.push({
            name: filelist[i].name,
            url: filelist[i].url
          })
        }
      }
      this.currentRow.docs = JSON.stringify(docs)
    },
    getwork () {
      if (!this.predict_done_date) {
        this.$message.warning('请选择预计完成时间')
        return
      }
      feibiaodingzhi.getworka({
        id: this.curid,
        predict_done_date: this.predict_done_date
      }).then(r => {
        if (r.code == '200') {
          this.dialog_lq = false
          this.$message.success('操作成功')
          this.load()
        } else {
          this.$message.warning(r.message)
        }
      })
    },
    opengetworkbox (row) {
      this.curid = row.id
      this.predict_done_date = row.predict_done_date
      this.dialog_lq = true
    },
    openfilebox (row) {
      this.currentRow.id = row.id
      this.currentRow.docs = row.docs
      this.files = JSON.parse(row.docs || '[]')
      this.$refs.designform.show(row.id, '1')
      return

      this.dialog_fp = true
    },
    saveshejishi () {
      upgfeibiaodingzhi(this.currentRow).then(r => {
        this.dialog_fp = false
        if (r.code == '205') {
          this.$message.warning(r.message)
        }
        if (r.code == '200') {
          this.$message.success('操作成功')
          this.load()
        }
      })
    },
    statechange (e) {
      this.searchdata.type = e
      this.search()
    },
    glog (e) {
      this.activities = []
      getbuslog({ rel_id: e.row.id }).then(r => {
        this.activities = r.data
      })
    },
    search () {
      this.searchdata.curpage = 1
      this.load()
    },
    cancel (row) {
      this.$confirm('确认要取消订单吗?', '提示', { type: 'warning' }).then(() => {
        cancelfeibiaodingzhi({ id: row.id }).then(res => {
          if (res.code == '205') {
            this.$message.warning(res.message)
          }
          if (res.code === '200') {
            this.load()
            this.$message.success('操作成功!')
          }
        })
      })
    },
    viewdetail (row) {
      const that = this
      getonefeibiaodingzhi({ id: row.id }).then(r => {
        if (r.code == '200') {
          const d = r.data
          d.child = JSON.parse(d.child)
          this.carddata = d
          this.title = '详情'
          this.dialog = true
          this.$nextTick(r => {
            that.$refs.bill.disabledall = true
          })
        }
      })
      // let d = Object.assign({}, row)
      // d.child = JSON.parse(d.child)
      // this.carddata = d
      // this.title = '详情'
      // this.dialog = true
      // this.$nextTick(r => {
      //   that.$refs.bill.disabledall = true
      // })
    },
    addoption () {
      this.imglist = []
      this.optionData = { activity_id: this.formData.id }
      this.optiontitle = '新增'
      this.showModal = true
    },
    dialogclose () {
      this.formData = {}
      this.bodydata = []
      this.dialog = false
    },
    billstateconvert (row, column) {
      const val = row[column.property]
      if (val == '-1') {
        return '订单取消'
      }
    },
    load () {
      this.loading = true
      if (this.value && this.value.length > 1) {
        this.queryParas[1].val = dateformat(this.value[0])
        this.queryParas[2].val = dateformat(this.value[1])
      } else {
        this.queryParas[1].val = null
        this.queryParas[2].val = null
      }
      if (this.value2 && this.value2.length > 1) {
        this.queryParas[4].val = dateformat(this.value2[0])
        this.queryParas[5].val = dateformat(this.value2[1])
      } else {
        this.queryParas[4].val = null
        this.queryParas[5].val = null
      }
      if (this.value3 && this.value3.length > 1) {
        this.queryParas[9].val = dateformat(this.value3[0])
        this.queryParas[10].val = dateformat(this.value3[1])
      } else {
        this.queryParas[9].val = null
        this.queryParas[10].val = null
      }
      const param = Object.assign({}, this.searchdata,
        { queryParams: encodeURI(JSON.stringify(this.queryParas)) })
      feibiaodingzhi.getuserlistfordesign(param).then(res => {
        this.listdata = res.data.records
        this.pagetotal = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    save (e) {
      const d = Object.assign({}, e)
      d.child = JSON.stringify(d.child)
      d.imgs = JSON.stringify(d.imgs)
      addfeibiaodingzhi(d).then(res => {
        if (res.code == '200') {
          this.dialog = false
          this.load()
          this.$message.success('操作成功!')
        }
      })
    },
    addactivity () {
      this.title = '新增'
      this.carddata = null
      this.bodydata = []
      this.formData = { id: rowid() }
      this.dialog = true
    },
    handlePictureCardPreview () {

    },
    closemodal () {
      this.showModal = false
    },
    loadbody (id) {
      this.bodydata = []
      activityBodyList({
        id: id,
        search: ''
      }).then(res => {
        if (res.code === '200') {
          this.bodydata = res.data
        }
      })
    },
    editoption (row) {
      this.optionData = Object.assign({}, row)
      if (this.optionData.att) {
        this.optionData.attlist = JSON.parse(this.optionData.att)
      }
      if (this.optionData.img) {
        this.imglist = [{ url: this.$options.filters.getImgSrc(this.optionData.img) }]
      }
      this.optiontitle = '编辑'
      this.showModal = true
    },
    uperror (err) {
      console.log(err)
    },
    submitoption () {
      this.$refs.option.validate(vali => {
        if (vali) {
          if (this.optionData.attlist) {
            const att = []
            this.optionData.attlist.map(cur => {
              att.push({
                name: cur.name,
                path: cur.path
              })
            })
            this.optionData.att = JSON.stringify(att)
          }
          console.log(this.optionData)
          if (this.optiontitle === '新增') {
            activityBodyAdd(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          } else if (this.optiontitle === '编辑') {
            activityBodyEdit(this.optionData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.showModal = false
                this.loadbody(this.optionData.activity_id)
              }
            })
          }
        }
      })
    },
    deleteactivity (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.load()
          }
        })
      })
    },
    deleteoption (row) {
      this.$confirm('确认删除吗?', '提示', { type: 'warning' }).then(() => {
        activityBodyDelete({ id: row.id }).then(res => {
          if (res.code === '200') {
            this.$message.success('操作成功！')
            this.loadbody(row.activity_id)
          }
        })
      })
    },
    submitactivity () {
      this.$refs.mainform.validate(vali => {
        if (vali) {
          if (this.title === '新增') {
            activityAdd(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          } else if (this.title === '编辑') {
            activityEdit(this.formData).then(res => {
              if (res.code === '200') {
                this.$message.success('操作成功!')
                this.dialog = false
                this.load()
              }
            })
          }
        } else {
          console.log('fail')
        }
      })
    },
    crateQrcode (text) {
      this.qr = new QRCode('qrcode', {
        width: 128,
        height: 128, // 高度
        text: text // 二维码内容
      })
    },
    genqrcode (row) {
      console.log(mobileurl)
      this.qrcodedialog = true
      this.$nextTick(() => {
        this.crateQrcode(mobileurl + 'pages/vote/index/' + '?activityid=' + row.id)
      })
    },
    qrcodedialogclose () {
      this.$refs.qrcode.innerHTML = ''
    },
    optiondialogclose () {
      this.optionData = {}
      this.imglist = []
      this.fileList = []
    },
    filelistchange (file, filelist) {
      console.log(filelist.slice(-3))
    },
    uploadSuccess (req, file, filelist) {
      if (req.code === '200') {
        file.url = req.data.path
      }
    },
    handleChange (file, filelist) {
      this.fileList = filelist.slice(-3)
    }
  },
  mounted () {
    this.load()
  },
  created () {
    find_getbyfind({
      code: 'find_state',
      param: { a: 'dd' }
    }).then(res => {
      if (res.code == '200') {
        this.dict_state = res.data.data
      }
    })
    find_getbyfind({
      code: 'g_designer',
      param: { a: 'dd' }
    }).then(res => {
      if (res.code == '200') {
        this.dict_shejishi = res.data.data
      }
    })
  }
}
</script>

<style scoped lang="scss" type="text/scss">
  .buttondiv {
    margin: 10px 0;
    text-align: right;
  }

  .commanddiv {
    color: $color-primary;

    span:hover {
      cursor: pointer;
    }
  }

  .qrcodedialog ::v-deep .el-dialog__body {
    padding: 0px;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .tbbox {
    display: flex;
    flex: 1;
    position: relative;
  }

  .tb {
    height: 100% !important;
    position: absolute;
  }

  .el-menu-demo {
    background-color: #F6F8FA;
    border: 1px solid #E6E6E6;
    border-bottom: 0px;
  }

  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-upload-list__item {
    transition: none !important;
  }

  @import "../table";
</style>
