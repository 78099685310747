'use strict'

var getBusinessObject = require('bpmn-js/lib/util/ModelUtil').getBusinessObject

// input entities
var textInputField = require('./TextInputEntryFactory')
var checkboxField = require('./CheckboxEntryFactory')
var selectBoxField = require('./SelectEntryFactory')
var comboBoxField = require('./ComboEntryFactory')
var textBoxField = require('./TextBoxEntryFactory')
var validationAwareTextInputField = require('./ValidationAwareTextInput')
var tableField = require('./TableEntryFactory')
var labelEntry = require('./LabelFactory')
var link = require('./LinkEntryFactory')

var cmdHelper = require('../helper/CmdHelper')

// helpers ////////////////////////////////////////

function ensureNotNull (prop) {
  if (!prop) {
    throw new Error(prop + ' must be set.')
  }

  return prop
}

/**
 * sets the default parameters which are needed to create an entry
 *
 * @param options
 * @returns {{id: *, description: (*|string), get: (*|Function), set: (*|Function),
 *            validate: (*|Function), html: string}}
 */
var setDefaultParameters = function (options) {
  // default method to fetch the current value of the input field
  var defaultGet = function (element) {
    var bo = getBusinessObject(element)
    var res = {}
    var prop = ensureNotNull(options.modelProperty)
    res[prop] = bo.get(prop)

    return res
  }

  // default method to set a new value to the input field
  var defaultSet = function (element, values) {
    var res = {}
    var prop = ensureNotNull(options.modelProperty)
    if (values[prop] !== '') {
      res[prop] = values[prop]
    } else {
      res[prop] = undefined
    }

    return cmdHelper.updateProperties(element, res)
  }

  // default validation method
  var defaultValidate = function () {
    return {}
  }

  return {
    id: options.id,
    description: (options.description || ''),
    get: (options.get || defaultGet),
    set: (options.set || defaultSet),
    validate: (options.validate || defaultValidate),
    html: ''
  }
}

function EntryFactory () {

}

/**
 * Generates an text input entry object for a property panel.
 * options are:
 * - id: id of the entry - String
 *
 * - description: description of the property - String
 *
 * - label: label for the input field - String
 *
 * - set: setter method - Function
 *
 * - get: getter method - Function
 *
 * - validate: validation mehtod - Function
 *
 * - modelProperty: name of the model property - String
 *
 * - buttonAction: Object which contains the following properties: - Object
 * ---- name: name of the [data-action] callback - String
 * ---- method: callback function for [data-action] - Function
 *
 * - buttonShow: Object which contains the following properties: - Object
 * ---- name: name of the [data-show] callback - String
 * ---- method: callback function for [data-show] - Function
 *
 * @param options
 * @returns the propertyPanel entry resource object
 */
EntryFactory.textField = function (options) {
  return textInputField(options, setDefaultParameters(options))
}

EntryFactory.validationAwareTextField = function (options) {
  return validationAwareTextInputField(options, setDefaultParameters(options))
}

/**
 * Generates a checkbox input entry object for a property panel.
 * options are:
 * - id: id of the entry - String
 *
 * - description: description of the property - String
 *
 * - label: label for the input field - String
 *
 * - set: setter method - Function
 *
 * - get: getter method - Function
 *
 * - validate: validation method - Function
 *
 * - modelProperty: name of the model property - String
 *
 * @param options
 * @returns the propertyPanel entry resource object
 */
EntryFactory.checkbox = function (options) {
  return checkboxField(options, setDefaultParameters(options))
}

EntryFactory.textBox = function (options) {
  return textBoxField(options, setDefaultParameters(options))
}

EntryFactory.selectBox = function (options) {
  return selectBoxField(options, setDefaultParameters(options))
}

EntryFactory.comboBox = function (options) {
  return comboBoxField(options)
}

EntryFactory.table = function (options) {
  return tableField(options)
}

EntryFactory.label = function (options) {
  return labelEntry(options)
}

EntryFactory.link = function (options) {
  return link(options)
}

module.exports = EntryFactory
